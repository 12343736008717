<template>
  <div>
    <div class="top_1_2">
      <div class="fx_ce">
        <img src="/images/1_1_logo.png" srcset="/images/1_1_logo.png 1x, /images/1_1_logo@2x.png 2x" alt="BCon"><h1>{{ title }}</h1>
      </div>
      <router-link v-if="(project.progress_status == 2 || project.progress_status == 2.1 || project.progress_status == 3) && (newStep == 2 || newStep == 2.1) && $route.name !== 'ApplyPreview'" :to="{ name: 'ApplyPreview' }" class="top_btn top_btn_4_1">申込内容確認</router-link>
      <p id="over" v-show="user && user.name">
        <a href="javascript:;" :class="hover ? 'link_1_under' : 'link_1'" @mouseover="mouseOver" ><img src="/images/2_3_top.png" alt="ログイン" srcset="/images/2_3_top.png 1x, /images/2_3_top@2x.png 2x" class="login_parson">{{ user && user.name }}</a>
        <span id="off" :class="{'hover_text': true, 'no' : !hover}"><a href="javascript:;" @click="logout" class="link_2">ログアウト</a></span>
      </p>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import { USER_CONSULTANT, USER_PROJECT, USER_SALES } from './../contstant'
  export default {
    data() {
      return {
        hover: false,
        logoutTo: 'Login'
      }
    },
    props: {
      title: String,
      default: null
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project']),
      ...mapState('project', ['newStep'])
    },
    methods: {
      logout() {
        if (this.user.type === USER_CONSULTANT) {
          this.logoutTo = 'LoginConsultant'
        } else if (this.user.type === USER_SALES) {
          this.logoutTo = 'LoginSales'
        }
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      mouseOver() {
        this.hover = !this.hover
      }
    }
  }
</script>