export default {
  state: {
    temp_user: {}
  },
  getters: {
    temp_user: state => state.temp_user,
  },
  mutations: {
    LIST_PROJECTS(state, projects) {
      state.projects = projects
    },
    TEMP_CLIENT(state, user) {
      state.temp_user = user
    }
  },
  actions: {
    TEMP_CLIENT({commit}, user) {
      console.log(user)
      commit('TEMP_CLIENT', user);
    }
  }
}
