<template>
	<div>
		<div id="body_1_2" v-if="errors.length === 0">
			<div class="top_box_4_2">
				<div class="content_box_4_2">
					<p>以下のファイルをアップロードしてよろしいですか？</p>
					<p class="content_img_box_4_2">
						<img src="images/up_exel.png" alt="アップロードファイル" srcset="images/up_exel.png 1x, images/up_exel@2x.png 2x">
					</p>
					<p class="content_box_4_2_p" v-html="file_name"></p>
				</div>
			</div>
	
			<div class="footer_4_2" v-if="project.project_type >=1 && project.project_type <=6">
				<p class="btn_1">
					<input class="form_box_sub form_box_sub_4_2" @click="upload()" type="submit" value="ファイルを送信する">
				</p>
				<p class="btn_2">
					<input type="file" ref="file" @change="reUpload()" id="02" name="03"><label for="02" id="input-label">ファイルを変更する</label>
				</p>
			</div>

			<div class="footer_4_2" v-if="project.project_type >=11 && project.project_type <=16">
				<p class="btn_1">
					<input class="form_box_sub form_box_sub_4_2" @click="project.progress_status == 2.1 ? uploadPTP() : upload()" type="submit" value="ファイルを送信する">
				</p>
				<p class="btn_2">
					<input type="file" ref="file" @change="project.progress_status == 2.1 ? reUploadPTP() : reUpload()" id="02" name="03"><label for="02" id="input-label">ファイルを変更する</label>
				</p>
			</div>

			<div class="footer_4_2" v-if="project.project_type >=21 && project.project_type <=24">
				<p class="btn_1">
					<input class="form_box_sub form_box_sub_4_2" @click="uploadPTP()" type="submit" value="ファイルを送信する">
				</p>
				<p class="btn_2">
					<input type="file" ref="file" @change="reUploadPTP()" id="02" name="03"><label for="02" id="input-label">ファイルを変更する</label>
				</p>
			</div>
		</div>
		<div v-else>
			<div class="top_box_4_2">
				<div class="content_box_4_2 content_box_4_3">
					<p>ファイルに以下のエラーが見られました。<br>
						ご確認の上、再アップロードをお願いします。</p>
					<div class="attention_box" v-for="error in errors">
						<p class="attention">{{ error }}</p>
					</div>
				</div>
			</div>

			<div class="footer_4_2">
				<p class="btn btn_2_5 btn_3_3 btn_4_1 btn_4_3">
					<input type="file" ref="file2" @change="project.progress_status == 2.1 ? reUploadPTP() : reUpload()" id="03" name="03">
					<label for="03">ファイルを再アップロード</label></p>
				<p class="footer_box_4_1_u footer_box_4_3_u">アップロード期日 2020年○月○日 00:00分</p>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapState} from 'vuex'

	export default {
    data() {
      return {
				// link_upload_attr: '/api/admin/p-attribute-info/',
				link_upload: '/api/admin/pai-import',
				link_upload_ptp: '/api/admin/ptp-import',
    		file_name: '',
				errors: []
      }
    },
    watch:{
			file_uploaded() {
				if (this.file_uploaded && this.file_uploaded.name) {
					this.file_name = this.file_uploaded.name;
				}
			},
    },
    computed: {
    	...mapState('consultantUser', ['project']),
    	...mapState('uploadFile', ['file_uploaded']),
		},
		methods: {
		upload() {
			this.$wait.start('loading')
			this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload}).then((rs) => {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
				if (rs.errors && rs.errors.length > 0) {
					this.errors = rs.errors
				} else {
					if (this.project.progress_status == 3.0) {
						this.$router.push({ name: 'apply-completed' });
					} else {
						this.$store.dispatch('project/NEW_STEP', 2.1);
					}
				}
				this.$wait.end('loading')
			});
		},
		reUpload() {
			this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file2.files[0]);
			this.$wait.start('loading')
			this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload}).then((rs) => {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
				if (rs.errors && rs.errors.length > 0) {
					this.errors = rs.errors
				} else {
					this.$store.dispatch('project/NEW_STEP', 2.1);
				}
				this.$wait.end('loading')
			});
		},
		uploadPTP() {
			this.$wait.start('loading')
			this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_ptp}).then((rs) => {
				console.log('upload_ptp', rs);
				this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
				if (rs.errors && rs.errors.length > 0) {
					this.errors = rs.errors
				} else {
					if (this.$route.name !== 'appy-completed')
					this.$router.push({ name: 'apply-completed' });
				}
				this.$wait.end('loading')
			});
		},
		reUploadPTP() {
			this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file2.files[0]);
			this.$wait.start('loading')
			this.$store.dispatch('uploadFile/UPLOAD', {url: this.link_upload_ptp}).then((rs) => {
				this.$store.dispatch('uploadFile/FILE_UPLOADED', null);
				if (rs.errors && rs.errors.length > 0) {
					this.errors = rs.errors
				} else {
					if (this.$route.name !== 'appy-completed')
					this.$router.push({ name: 'apply-completed' });
				}
				this.$wait.end('loading')
			});
		}
	}
  }
</script>

<style scoped lang="scss">
	.cur_pointer{
		cursor: pointer;
	}
</style>