<template>
  <div>
    <div class="top_box_4_1">
      <div class="top_box_child_4_1">
        <p>アップロードが必要な資料は以下になります。</p>
        <div v-if="project.project_type >=1 && project.project_type <=6">
          <div class="fx_se">
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari1.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">対象者リスト</p>
            </div>
          </div>
        </div>
        <div v-if="project.project_type >=11 && project.project_type <=16">
          <div class="fx_se" v-if="project.progress_status == '2.1'">
            <div>
              <div class="top_img_box_child_4_4 fin_up">
                <img src="images/atari1.jpg" alt="区分コード画像" width="352" height="155" class="top_4_4_o">
              </div>
              <p class="top_box_child_4_1_p top_4_4_o">①「区分コード」設定</p>
            </div>
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">②対象者リスト</p>
            </div>
          </div>
          <div class="fx_se" v-else>
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari1.jpg" alt="区分コード画像" width="352" height="155">
              </div>
              <p class="top_box_child_4_1_p">&#9312;属性一覧の設定</p>
            </div>
            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">&#9313;対象者リスト</p>
            </div>
          </div>
        </div>
        <div v-if="project.project_type >=21 && project.project_type <=24">
          <div class="fx_se">

            <div>
              <div class="top_img_box_child_4_1">
                <img src="images/atari2.jpg" alt="対象者リスト画像" width="358" height="199">
              </div>
              <p class="top_box_child_4_1_p">対象者リスト</p>
            </div>
          </div>
        </div>
      </div>
      <p class="top_box_4_1_p">最初に属性一覧の設定を入力→アップロードしてください</p>
      <div class="top_img_box_4_1 fx_se">
        <div>
          <p><img src="images/up_1.png" alt="フォーマットをダウンロード" srcset="images/up_1.png 1x, images/up_1@2x.png 2x"></p>
          <p>フォーマットをダウンロード</p>
        </div>
        <div>
          <p class="arrow"><img src="images/up_2.png" alt="デスクトップで入力" srcset="images/up_2.png 1x, images/up_2@2x.png 2x">
          </p>
          <p>デスクトップで入力</p>
        </div>
        <div>
          <p class="arrow"><img src="images/up_3.png" alt="ファイルをアップロード" srcset="images/up_3.png 1x, images/up_3@2x.png 2x">
          </p>
          <p>ファイルをアップロード</p>
        </div>
      </div>
    </div>

    <div class="footer_box_4_1" v-if="project.project_type >=1 && project.project_type <=6">
      <div class="fx_se">
        <div>
          <p class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"><a @click="download()">「属性一覧の設定」フォーマットを<br>ダウンロード</a></p>
          <p class="footer_box_4_1_d"><a href=""><img src="images/file_d_2.png" alt="入力の仕方を見る" srcset="images/file_d_2.png 1x, images/file_d_2@2x.png 2x">
            入力の仕方を見る</a></p>
        </div>
        <div>
          <p class="btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer">
            <input type="file" ref="file" v-on:change="upload()" id="02" name="02" ><label for="02" class="cur_pointer" id="input-label">「属性一覧の設定」ファイルを<br>アップロード</label>
          </p>
          <p class="footer_box_4_1_u">アップロード期日 2020年○月○日 00:00分</p>
          <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
        </div>
      </div>
    </div>

    <div class="footer_box_4_1" v-if="project.project_type >=21 && project.project_type <=24">
      <div class="fx_se">
        <div>
          <p class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"><a @click="downloadPTP()">「属性一覧の設定」フォーマットを<br>ダウンロード</a></p>
          <p class="footer_box_4_1_d"><a href=""><img src="images/file_d_2.png" alt="入力の仕方を見る" srcset="images/file_d_2.png 1x, images/file_d_2@2x.png 2x">
            入力の仕方を見る</a></p>
        </div>
        <div>
          <p class="btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer">
            <input type="file" ref="file" v-on:change="uploadPTP()" id="02" name="02" ><label for="02" class="cur_pointer" id="input-label">「属性一覧の設定」ファイルを<br>アップロード</label>
          </p>
          <p class="footer_box_4_1_u">アップロード期日 2020年○月○日 00:00分</p>
          <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
        </div>
      </div>
    </div>

    <div class="footer_box_4_1" v-if="project.project_type >=11 && project.project_type <=16">
      <div class="fx_se">
        <div>
          <p class="btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer">
            <!--<a @click="project.progress_status == 2.1 ? downloadPTP() : download()">「属性一覧の設定」フォーマットを<br>ダウンロード</a>-->
            <a download href="/api/admin/pai-download">「属性一覧の設定」フォーマットを<br>ダウンロード</a>
          </p>
          <p class="footer_box_4_1_d"><a href=""><img src="images/file_d_2.png" alt="入力の仕方を見る" srcset="images/file_d_2.png 1x, images/file_d_2@2x.png 2x">
            入力の仕方を見る</a></p>
        </div>
        <div>
          <p class="btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer">
          		<input type="file" ref="file" v-on:change="project.progress_status == 2.1 ? uploadPTP() : upload()" id="02" name="02" ><label for="02" class="cur_pointer" id="input-label">「属性一覧の設定」ファイルを<br>アップロード</label>
          </p>
          <p class="footer_box_4_1_u">アップロード期日 2020年○月○日 00:00分</p>
          <p class="footer_box_4_1_u_2">この画面を終了して、後でアップロードする場合<br>は、再度ログインしてください。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapState} from 'vuex'

  export default {
    data() {
      return {
    	  link_download_pai: '/api/admin/pai-download',
    	  link_download_ptp: '/api/admin/ptp-export',
      }
    },
    computed: {
    	...mapState('consultantUser', ['project']),
    	...mapState('project', ['newStep']),
    },
    methods: {
      download(){
        this.$store.dispatch('uploadFile/DOWNLOAD', {url: this.link_download_pai, filename: '組織診断_対象者リスト.xlsx'});
      },
      upload(){
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
        this.$store.dispatch('project/NEW_STEP', 3);
      },
      downloadPTP(){
        this.$store.dispatch('uploadFile/DOWNLOAD', {url: this.link_download_ptp, filename: '個人診断_対象者リスト.xlsx'});
      },
      uploadPTP(){
        this.$store.dispatch('uploadFile/FILE_UPLOADED', this.$refs.file.files[0]);
        this.$store.dispatch('project/NEW_STEP', 3);
      }
    }
  }
</script>

<style scoped lang="scss">
	.cur_pointer{
		cursor: pointer;
	}
</style>