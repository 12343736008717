import axios from "axios";

export default {
  namespaced: true,
  state: {
    projects: {},
    projectInfo: {},
    newStep: 0,
    file_uploaded: {}
  },
  getters: {},
  mutations: {
    LIST_PROJECTS(state, projects) {
      state.projects = projects
    },
    PROJECT(state, projectInfo) {
      state.projectInfo = projectInfo
    },
    NEW_STEP(state, step) {
      state.newStep = step
    },
    FILE_UPLOADED(state, file_uploaded) {
        state.file_uploaded = file_uploaded
      }
  },
  actions: {
    NEW_STEP({commit}, status) {
      commit('NEW_STEP', status)
    },
    async CREATE_PROJECT({commit, dispatch}, data) {
      let project = data.project
      let project_user = data.project_user
      try {
        let {data} = await axios.post('/api/sales-users/projects/create', project)
        if (data.id) {
          project_user.project_id = data.id
          project_user.sort = 1
          dispatch('CREATE_PROJECT_USER', project_user).then(() => {
            return
          }).catch(err => {

          })
        }
      } catch (err) {
        return err
      }
    },

    async UPDATE_PROJECT({commit, dispatch}, item) {
      console.log(item)
      // let project = item.project;
      // let user = item.user ? item.user : {};
      try {
        let {data} = await axios.put('/api/projects', item);

        commit('consultantUser/SET_PROJECT', data.project, {root: true})
        commit('consultantUser/SET_USER', data.user, {root: true})
        return data;
      } catch (err) {
        return err
      }
    },

    async GET_LIST_PROJECTS({commit, dispatch}, page = 1) {
      try {
        let {data} = await axios.get('/api/admin/projects?page=' + page)
        commit('LIST_PROJECTS', data)
        return data

      } catch (err) {
        return err
      }
    },

    async GET_PROJECT_DETAIL({commit, dispatch}, id) {
      try {
        let {data} = await axios.get('/api/admin/projects/' + id)
        commit('PROJECT', data)
        return data
      } catch (err) {
        return err
      }
    },

    async SEARCH_ADDRESS({commit, dispatch, rootState}, zip_code) {
      try {
        let {data} = await axios.get('/api/client/address?postal_code=' + zip_code)
        let project = rootState.consultantUser.project;
        project.address1 = data.prefecture
        project.address2 = data.city_address
        commit('consultantUser/SET_PROJECT', project, {root: true})
        return data
      } catch (err) {
        return err
      }
    },

    async SEARCH_ADDRESS_PAPER({commit, dispatch, rootState}, zip_code) {
      try {
        let {data} = await axios.get('/api/client/address?postal_code=' + zip_code)
        let project = rootState.consultantUser.project;
        project.paper1_address1 = data.prefecture
        project.paper1_address2 = data.city_address
        commit('consultantUser/SET_PROJECT', project, {root: true})
        return data
      } catch (err) {
        return err
      }
    },


    async SEARCH_ADDRESS_PAPER2({commit, dispatch, rootState}, zip_code) {
      try {
        let {data} = await axios.get('/api/client/address?postal_code=' + zip_code)
        let project = rootState.consultantUser.project;
        project.paper2_address1 = data.prefecture
        project.paper2_address2 = data.city_address
        commit('consultantUser/SET_PROJECT', project, {root: true})
        return data
      } catch (err) {
        return err
      }
    },

    async DELETE_PROJECT({commit, dispatch}, id) {
      try {
        let {data} = await axios.delete('/api/admin/projects/' + id)
        return data
      } catch (err) {
        return err
      }
    },

    async UPDATE_PROGRESS_STATUS({commit, dispatch}, item) {
      try {
        let {data} = await axios.put('/api/admin/projects/' + item.id, {'progress_status': item.progress_status});
        commit('consultantUser/SET_PROJECT_PROGRESS_STATUS', item.progress_status, {root: true})
        return data;
      } catch (err) {
        return err
      }
    },

    async SEND_PASSWORD({commit, dispatch}, item) {
      try {
        let {data} = await axios.post('/api/admin/projects/send-password/' + item.id);
        return data;
      } catch (err) {
        return err;
      }
    },

    async UPDATE_USER_PROJECT({commit, dispatch}, user) {
      try {
        let {data} = await axios.put('/api/users/update', user);
        commit('consultantUser/SET_USER', data, {root: true})
        return data;
      } catch (err) {
        return err
      }
    }
    
  }
}
