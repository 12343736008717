<template>
  <div class="">
    <header-component :title="'組織診断サーベイ'"></header-component>
    <div class="top_contact fx_cece" v-show="project && project.id">
      <div class="top_con1">
        <img src="images/top_qu.png" srcset="images/top_qu.png 1x, images/top_qu@2x.png 2x" alt="お問合せ先"><br>お問合せ先
      </div>
      <div class="top_con2">
        <p>不明点や確認事項がありましたら担当営業もしくはこちらまでお問い合わせください。</p>
        <div class="fx_ce">
          <p class="top_con2_p border_left"><img src="images/top_mail.png"
                                                 srcset="images/top_mail.png 1x, images/top_mail@2x.png 2x" alt="メールでのお問合せ">メールでのお問い合わせ<br><span
            class="span_color"><a href="mailto:quest@info.bcon.jp">quest@info.bcon.jp</a></span></p>
          <p class="top_con2_p"><img src="images/top_tel.png" srcset="images/top_tel.png 1x, images/top_tel@2x.png 2x"
                                     alt="お電話でのお問合せ">電話でのお問い合わせ<br><span>03-3287-3416(平日 10:00～17:00)</span></p>
        </div>
      </div>
    </div>
    <div v-show="project.progress_status !== undefined">
      <div id="step_bar_box">
        <ol class="step_bar">
          <li :class="{ current: (project.progress_status == 0 || project.progress_status == 1) && newStep == 0 }">STEP1 診断申込 登録</li>
          <li :class="{ current: (project.progress_status == 1 || project.progress_status == 2) && newStep == 1 }">STEP2 事前情報 登録</li>
          <li :class="{ current: ((project.progress_status == 2 || project.progress_status == 3) && (newStep == 2 || newStep == 3)) || (project.progress_status == 2.1 && (newStep == 2.1 || newStep == 3))  }">STEP3 対象者リストアップロード</li>
        </ol>
        <p v-show="(project.progress_status == 0 || project.progress_status == 1 || project.progress_status == 2) && !hideBtn" class="save_btn"><a href="javascript:;" @click="saveWithoutValidate">一時保存</a></p>
      </div>

      <!--{{ project.progress_status }}-->
      <div>
        <step-first @hidden="hidden" :preview="preview" v-show="project.progress_status == 0 || (project.progress_status == 1 && newStep == 0)"></step-first>
        <step-second @hidden="hidden" :preview="preview" v-show="(project.progress_status == 1 || project.progress_status == 2) && newStep == 1"></step-second>
        <step-third v-show="((project.progress_status == 2 || project.progress_status == 3) && newStep == 2) || (project.progress_status == 2.1 && newStep == 2.1) "></step-third>
        <file-confirmation v-show="(project.progress_status == 2 || project.progress_status == 2.1  || project.progress_status == 3) && newStep == 3"></file-confirmation>
      </div>
    </div>
    <!--<project-guide></project-guide>-->
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import ProjectGuideComponent from './ProjectGuideComponent.vue';
  import StepFirstComponent from './StepFirstComponent.vue';
  import StepSecondComponent from './StepSecondComponent.vue';
  import StepThirdComponent from './StepThirdComponent.vue';
  import FileConfirmationComponent from './FileConfirmationComponent.vue';

  export default {
    data() {
      return {
        preview: false,
        hideBtn: false,
      }
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project']),
      ...mapState('project', ['newStep'])
    },
    watch: {
      'project.progress_status': function () {
        if (parseFloat(this.project.progress_status) >= 3) {
          this.$router.push({
            name: 'apply-completed'
          })
        }
      }
    },
    components: {
      'step-first': StepFirstComponent,
      'step-second': StepSecondComponent,
      'step-third': StepThirdComponent,
      'project-guide': ProjectGuideComponent,
      'file-confirmation': FileConfirmationComponent
    },
    methods: {
      saveWithoutValidate() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project, 'user': this.user}).then(() => {
          // this.preview = true
          // this.hideBtn = true
          this.$wait.end('loading')
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      hidden(status) {
        this.hideBtn = status
        this.preview = false
      }
    },
    created() {

    },
    mounted() {

    }
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: 20px;
  }

  .main-contain {
    margin-top: 30px;

    .btn-primary {
      color: #fff;
    }
  }

  .required {
    background-color: red;
    color: #fff;
    border-radius: 10px;
    font-size: 11px;
    padding: 3px 7px;

  }

  .p-70 {
    width: 70%;
  }

  .p-20 {
    width: 20%;
  }

  .mail-content {
    margin-top: 30px;
  }

  .step-by-step .bc-step {
    font-weight: bold;
    color: #8c8c8c
  }

  .step-by-step .bc-step .bc-step-sign {
    background: #8c8c8c;
    padding: 3px 10px 4px 10px;
    border-radius: 50px;
    margin: 0px 3px 0px 0px;
    color: #ffffff;
  }

  .step-by-step .bc-step.active .bc-step-sign {
    background: rgb(86, 86, 236);
  }

  .step-by-step .bc-step.active {
    color: rgb(86, 86, 236);
  }

  .bc-page-title {
    font-size: 20px;
    font-weight: bold;
  }
</style>