var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "top_1_2" },
      [
        _c("div", { staticClass: "fx_ce" }, [
          _c("img", {
            attrs: {
              src: "/images/1_1_logo.png",
              srcset:
                "/images/1_1_logo.png" +
                " 1x, " +
                "/images/1_1_logo@2x.png" +
                " 2x",
              alt: "BCon"
            }
          }),
          _c("h1", [_vm._v(_vm._s(_vm.title))])
        ]),
        _vm._v(" "),
        (_vm.project.progress_status == 2 ||
          _vm.project.progress_status == 2.1 ||
          _vm.project.progress_status == 3) &&
        (_vm.newStep == 2 || _vm.newStep == 2.1) &&
        _vm.$route.name !== "ApplyPreview"
          ? _c(
              "router-link",
              {
                staticClass: "top_btn top_btn_4_1",
                attrs: { to: { name: "ApplyPreview" } }
              },
              [_vm._v("申込内容確認")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "p",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.user && _vm.user.name,
                expression: "user && user.name"
              }
            ],
            attrs: { id: "over" }
          },
          [
            _c(
              "a",
              {
                class: _vm.hover ? "link_1_under" : "link_1",
                attrs: { href: "javascript:;" },
                on: { mouseover: _vm.mouseOver }
              },
              [
                _c("img", {
                  staticClass: "login_parson",
                  attrs: {
                    src: "/images/2_3_top.png",
                    alt: "ログイン",
                    srcset:
                      "/images/2_3_top.png" +
                      " 1x, " +
                      "/images/2_3_top@2x.png" +
                      " 2x"
                  }
                }),
                _vm._v(_vm._s(_vm.user && _vm.user.name))
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                class: { hover_text: true, no: !_vm.hover },
                attrs: { id: "off" }
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "link_2",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.logout }
                  },
                  [_vm._v("ログアウト")]
                )
              ]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }