var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.projectInfo && _vm.projectInfo.id,
          expression: "projectInfo && projectInfo.id"
        }
      ],
      staticClass: "container"
    },
    [
      _c("header-component"),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("form", { attrs: { action: "", method: "post" } }, [
        _c("div", { staticClass: "form_box form_box_1_3" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("組織名(会社名)")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.client_name))])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("ご担当者様 所属・役職")]),
            _vm._v(" "),
            _c("dd", [
              _vm.projectInfo && _vm.projectInfo.user && _vm.projectInfo.user[0]
                ? _c("div", [
                    _vm._v(
                      _vm._s(_vm.projectInfo.user[0].division) +
                        " " +
                        _vm._s(_vm.projectInfo.user[0].position)
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("ご担当者様 氏名")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo &&
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[0]
                    ? _vm.projectInfo.user[0].name
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("ご担当者様 メールアドレス")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo &&
                    _vm.projectInfo.user &&
                    _vm.projectInfo.user[0]
                    ? _vm.projectInfo.user[0].email
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("ご担当者様2人目 氏名")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.projectInfo &&
                      _vm.projectInfo.user &&
                      _vm.projectInfo.user[1]
                      ? _vm.projectInfo.user[1].name
                      : ""
                  ) +
                  "\n\t\t\t"
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("ご担当者様2人目 メールアドレス")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.projectInfo &&
                      _vm.projectInfo.user &&
                      _vm.projectInfo.user[1]
                      ? _vm.projectInfo.user[1].email
                      : ""
                  ) +
                  "\n\t\t\t"
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("電話番号")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.projectInfo.client_tel) + "\n\t\t\t"
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", [_vm._v("住所")]),
            _vm._v(" "),
            _c("dd", [
              _c("p", [_vm._v("〒" + _vm._s(_vm.projectInfo.zip_code))]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(_vm.projectInfo.address1) +
                    _vm._s(_vm.projectInfo.address2)
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form_box form_box_1_3" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("dl", [
            _c("dt", { attrs: { width: "25%" } }, [_vm._v("実施方法")]),
            _vm._v(" "),
            _c("dd", { attrs: { width: "75%" } }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    this.medium_types[_vm.projectInfo.medium_type]
                      ? this.medium_types[_vm.projectInfo.medium_type]
                      : ""
                  ) +
                  "\n\t\t\t\t\t\t\t" +
                  _vm._s(
                    (parseInt(_vm.projectInfo.web_people) || 0) +
                      (parseInt(_vm.projectInfo.web_people) || 0)
                  ) +
                  "名\n\t\t\t\t\t"
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", { staticClass: "top" }, [_vm._v("回答スケジュール")]),
            _vm._v(" "),
            _c("dd", { staticClass: "fx" }, [
              _c("div", { staticClass: "form_box_2_4" }, [
                _vm._m(4),
                _vm._v(" "),
                _vm.projectInfo.medium_type == 1
                  ? _c("div", { staticClass: "form_box_360" }, [
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("Web診断開始日時")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("datetimeFormat")(
                                _vm.projectInfo.web_start
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row margin_b_2_4 margin_b_2_3 fx_ce" },
                        [
                          _c("p", { staticClass: "col-140" }, [
                            _vm._v("Web診断終了日時")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "col margin_l_2_4" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("datetimeFormat")(
                                  _vm.projectInfo.web_end
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectInfo.medium_type == 2
                  ? _c("div", { staticClass: "form_box_360" }, [
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙到着希望日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_preferred_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce" }, [
                        _c("p", [_vm._v("質問紙提出期限(任意)")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_limit_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙BCon到着日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_bcon_receipt_date
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.projectInfo.medium_type == 3
                  ? _c("div", { staticClass: "form_box_360" }, [
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("Web診断開始日時")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("datetimeFormat")(
                                _vm.projectInfo.web_start
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row margin_b_2_4 margin_b_2_3 fx_ce" },
                        [
                          _c("p", { staticClass: "col-140" }, [
                            _vm._v("Web診断終了日時")
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "col margin_l_2_4" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("datetimeFormat")(
                                  _vm.projectInfo.web_end
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙到着希望日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_preferred_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce" }, [
                        _c("p", [_vm._v("質問紙提出期限(任意)")]),
                        _vm._v(" "),
                        _c("p", { staticClass: "margin_l_2_4 stick_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_limit_date
                              )
                            )
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "margin_b_2_4 fx_ce row" }, [
                        _c("p", { staticClass: "col col-140" }, [
                          _vm._v("質問紙BCon到着日")
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "col margin_l_2_4" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("dateFormat")(
                                _vm.projectInfo.paper_bcon_receipt_date
                              )
                            )
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("報告書納品希望日")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(_vm._f("dateFormat")(_vm.project.report_preferred_date))
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", [_vm._v("該当する業種・規模")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                "業種　　　：　" +
                  _vm._s(
                    this.industry_types[_vm.projectInfo.industry_type]
                      ? this.industry_types[_vm.projectInfo.industry_type]
                      : ""
                  )
              ),
              _c("br"),
              _vm._v(
                "\n\t\t\t\t\t\t\t規模　　　：　" +
                  _vm._s(
                    this.scale_types[_vm.projectInfo.scale_type]
                      ? this.scale_types[_vm.projectInfo.scale_type]
                      : ""
                  )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("備考欄")]),
            _vm._v(" "),
            _c("dd", [
              _vm.projectInfo &&
              _vm.projectInfo.memo &&
              _vm.projectInfo.memo.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.projectInfo.memo.split("\n"), function(line) {
                      return _c("div", [_vm._v(_vm._s(line) + "\n\t\t\t\t")])
                    }),
                    0
                  )
                : _c("div", [_vm._v("なし")])
            ])
          ]),
          _vm._v(" "),
          _vm._m(6)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form_box form_box_1_3 form_box_5_2" }, [
          _vm._m(7),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("組織名")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.org_name))])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("診断名")]),
            _vm._v(" "),
            _c("dd", [_vm._v(_vm._s(_vm.projectInfo.diagnosis_name))])
          ]),
          _vm._v(" "),
          _vm._m(8),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", [_vm._v("ID・パスワード設定")]),
            _vm._v(" "),
            _c("dd", { staticClass: "l_height" }, [
              _vm._v(
                "\n\t\t\t\tID：　　　　" +
                  _vm._s(
                    _vm.setting_types[_vm.projectInfo.id_setting_type]
                      ? _vm.setting_types[_vm.projectInfo.id_setting_type]
                      : ""
                  )
              ),
              _c("br"),
              _vm._v(
                "\n\t\t\t\tパスワード：" +
                  _vm._s(
                    _vm.setting_types[_vm.projectInfo.pass_setting_type]
                      ? _vm.setting_types[_vm.projectInfo.pass_setting_type]
                      : ""
                  ) +
                  "\n\t\t\t"
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", { staticClass: "form_box_1_3_dl" }, [
            _c("dt", { staticClass: "height_up_3_2" }, [
              _vm._v("貴社お問合せ先")
            ]),
            _vm._v(" "),
            _c("dd", { staticClass: "l_height" }, [
              _vm._v(
                "\n\t\t\t\t部署・役職 " +
                  _vm._s(_vm.projectInfo.inquiry_division)
              ),
              _c("br"),
              _vm._v(" \n\t\t\t\t氏名 " + _vm._s(_vm.projectInfo.inquiry_name)),
              _c("br"),
              _vm._v(" \n\t\t\t\t電話 " + _vm._s(_vm.projectInfo.inquiry_tel)),
              _c("br"),
              _vm._v(
                " \n\t\t\t\tメール " + _vm._s(_vm.projectInfo.inquiry_email)
              ),
              _c("br")
            ])
          ]),
          _vm._v(" "),
          _vm._m(9),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("業種別比較")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.projectInfo.comparison_industry_type ? "有り" : "無し"
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c("dl", [
            _c("dt", [_vm._v("回答者への案内メール")]),
            _vm._v(" "),
            _c("dd", [
              _vm._v(
                _vm._s(
                  _vm.guidance_email_types[_vm.projectInfo.guidance_email_type]
                    ? _vm.guidance_email_types[
                        _vm.projectInfo.guidance_email_type
                      ]
                    : ""
                )
              )
            ])
          ]),
          _vm._v(" "),
          _vm._m(10),
          _vm._v(" "),
          _vm._m(11),
          _vm._v(" "),
          _c("dl", [
            _c("dt", { staticClass: "hight_up_5_2" }, [
              _vm._v("区分コードファイル(Excel)")
            ]),
            _vm._v(" "),
            _c("dd", [
              _c("p", { staticClass: "form_box_sub_5_2_p" }, [
                _c("span", [_vm._v("未")]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.projectInfo.exportLink,
                      target: "_blank"
                    }
                  },
                  [
                    _c("input", {
                      staticClass: "form_box_sub_2 form_box_sub_5_2",
                      attrs: {
                        type: "button",
                        value: "ファイルを入力→アップロード"
                      }
                    })
                  ]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _vm._m(12)
        ]),
        _vm._v(" "),
        _vm._m(13)
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "deletePopup",
          attrs: {
            name: "deletePopup",
            id: "deletePopup",
            "modal-class": ["modal-action"],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", [_vm._v("クライアント名を削除しますか？")]),
          _vm._v(" "),
          _c("div", { staticClass: "message" }, [_c("div")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("deletePopup")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-yellow",
                  on: {
                    click: function($event) {
                      return _vm.deleteProject()
                    }
                  }
                },
                [_vm._v("削除")]
              )
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "sendPasswordPopup",
          attrs: {
            name: "sendPasswordPopup",
            id: "sendPasswordPopup",
            "modal-class": ["modal-action"],
            centered: true,
            "no-close-on-backdrop": true,
            "header-close-label": "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("h5", [_vm._v("Do you reset and send password to user？")]),
          _vm._v(" "),
          _c("div", { staticClass: "message" }, [_c("div")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer top-30" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-submit",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("sendPasswordPopup")
                    }
                  }
                },
                [_vm._v("キャンセル")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn-yellow",
                  on: {
                    click: function($event) {
                      return _vm.sendPassword()
                    }
                  }
                },
                [_vm._v("削除")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_contact fx_cece" }, [
      _c("div", { staticClass: "top_con1" }, [
        _c("img", {
          attrs: {
            src: "/images/top_qu.png",
            srcset:
              "/images/top_qu.png" + " 1x, " + "/images/top_qu@2x.png" + " 2x",
            alt: "お問合せ先"
          }
        }),
        _c("br"),
        _vm._v("お問合せ先\n\t")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "top_con2" }, [
        _c("p", [
          _vm._v(
            "不明点や確認事項がありましたら直接診断チームまでお問合せください。"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "fx_ce" }, [
          _c("p", { staticClass: "top_con2_p border_left" }, [
            _c("img", {
              attrs: {
                src: "/images/top_mail.png",
                srcset:
                  "/images/top_mail.png" +
                  " 1x, " +
                  "/images/top_mail@2x.png" +
                  " 2x",
                alt: "メールでのお問合せ"
              }
            }),
            _vm._v("メールでのお問合せ"),
            _c("br"),
            _vm._v(" "),
            _c("span", { staticClass: "span_color" }, [
              _c("a", { attrs: { href: "mailto:quest@info.bcon.jp" } }, [
                _vm._v("quest@info.bcon.jp")
              ])
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "top_con2_p" }, [
            _c("img", {
              attrs: {
                src: "/images/top_tel.png",
                srcset:
                  "/images/top_tel.png" +
                  " 1x, " +
                  "/images/top_tel@2x.png" +
                  " 2x",
                alt: "お電話でのお問合せ"
              }
            }),
            _vm._v("お電話でのお問合せ"),
            _c("br"),
            _vm._v(" "),
            _c("span", [_vm._v("03-3287-3416(平日 10:00～17:00)")])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c("p", [_vm._v("以下の内容で企業診断を受け付けております。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("お客様情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("申込内容")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "form_box_2_4_p form_box_2_4_2_p",
        attrs: { id: "margin_left_20" }
      },
      [_c("span", [_vm._v("診断用書類(必須)締切日　2020年x月x日")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("個人情報保管期間")]),
      _vm._v(" "),
      _c("dd")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("事前情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("属性情報の設定")]),
      _vm._v(" "),
      _c("dd")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("質問紙提出先")]),
      _vm._v(" "),
      _c("dd", [_vm._v("●●部署　▲▲さん")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("質問文の主語変更")]),
      _vm._v(" "),
      _c("dd")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", { staticClass: "fx" }, [
      _c("dt", [_vm._v("追加質問文")]),
      _vm._v(" "),
      _c("dd", { staticClass: "padding_tb" }, [
        _vm._v("\n\t\t\t\t追加質問が5問以内/追加質問の尺度が左記の7点法を利用"),
        _c("br"),
        _vm._v(" \n\t\t\t\t【質問文】"),
        _c("br"),
        _vm._v("\n\t\t\t\t質問文が入ります。ダミーテキスト"),
        _c("br"),
        _vm._v(" \n\t\t\t\t【質問文】"),
        _c("br"),
        _vm._v(" \n\t\t\t\t質問文が入ります。ダミーテキスト\n\t\t\t")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", { staticClass: "hight_up_5_2" }, [
        _vm._v("対象者リスト(Excel)")
      ]),
      _vm._v(" "),
      _c("dd", [
        _c("p", { staticClass: "form_box_sub_5_2_p" }, [
          _c("span", [_vm._v("未")]),
          _c("input", {
            staticClass: "form_box_sub_2 form_box_sub_5_2",
            attrs: { type: "submit", value: "ファイルを入力→アップロード" }
          })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "footer_box footer_box_1_3" }, [
      _c("p"),
      _vm._v(" "),
      _c("p", { staticClass: "btn" }, [
        _c("input", {
          staticClass: "form_box_sub form_box_sub_2",
          attrs: { type: "submit", value: "登録内容を変更する" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }