import VueRouter from 'vue-router';
import HomeComponent from './../components/HomeComponent.vue';
import LoginComponent from './../components/project-user/LoginComponent.vue';
import LoginConsultantComponent from '../components/consultant-user/LoginComponent.vue';
import LoginSalesComponent from '../components/sales-user/LoginComponent.vue';
import ProjectListComponent from '../components/consultant-user/project/ListComponent.vue';
import ProjectDetailComponent from '../components/consultant-user/project/DetailComponent.vue';
import ClientRegisterComponent from '../components/sales-user/client/RegisterComponent';
import ClientListComponent from '../components/sales-user/client/ListComponent';
import ApplicationFormComponent from '../components/project-user/apply/ApplicationFormComponent';
import ApplicationFormCompletedComponent from '../components/project-user/apply/ApplicationFormCompletedComponent';
import ApplyPreviewComponent from '../components/project-user/apply/ApplyPreviewComponent';
import NotFound from './../components/404.vue';
import store from '../store'
import {USER_CONSULTANT, USER_PROJECT, USER_SALES} from '../contstant';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['consultantUser/isAuthenticated']) {
    next()
    return
  }
  if (to.path.indexOf('admin') > -1) {
    next('/admin')
  } else if (to.path.indexOf('sales') > -1) {
    next('/sales')
  } else {
    next('/')
  }
}

function checkUser(to, from, next) {
  if (store.getters['consultantUser/user'] && store.getters['consultantUser/user'].type === USER_CONSULTANT) {
    if ((to.path.indexOf('admin')) < 0) {
      next('/admin')
    }
  } else if (store.getters['consultantUser/user'] && store.getters['consultantUser/user'].type === USER_SALES) {
    if ((to.path.indexOf('sales')) < 0) {
      next('/sales')
    }
  } else if (store.getters['consultantUser/user'] && store.getters['consultantUser/user'].type === USER_PROJECT) {
    if (to.path.indexOf('admin') > -1 || (to.path.indexOf('sales')) > -1) {
      next('/')
    }
  }
}

const ifAuthenticated = (to, from, next) => {
  store.watch(
    (state) => {
      if (!!state.consultantUser.token) {
        if (_.isEmpty(store.getters['consultantUser/user'])) {
          store.dispatch('consultantUser/AUTH_TOKEN').then(() => {
            checkUser(to, from, next)
          }).catch((e) => {
            next('/')
          })
        } else {
          checkUser(to, from, next)
        }
        next()
        return
      }
      if (to.path.indexOf('admin') > -1) {
        next('/admin/login')
      } else if (to.path.indexOf('sales') > -1) {
        next('/sales/login')
      } else {
        next('/login')
      }
    }
  )
}
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginComponent,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: '組織診断サーベイ 診断申込 登録フォーム'
    }
  },
  {
    path: '/admin/login',
    name: 'LoginConsultant',
    component: LoginConsultantComponent,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: '営業部門 ログイン画面'
    }
  },
  {
    path: '/sales/login',
    name: 'LoginSales',
    component: LoginSalesComponent,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: '営業部門 ログイン画面'
    }
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: HomeComponent,
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: '/admin',
    name: 'ProjectList',
    component: ProjectListComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: ''
    }
  },
  {
    path: '/',
    name: 'apply',
    component: ApplicationFormComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '組織診断サーベイ 診断申込 登録フォーム'
    }
  },
  
  {
    path: '/apply-completed',
    name: 'apply-completed',
    component: ApplicationFormCompletedComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '組織診断サーベイ 診断用資料(必須) アップロード'
    }
  },
  {
    path: '/preview',
    name: 'ApplyPreview',
    component: ApplyPreviewComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '組織診断サーベイ 申込内容確認'
    }
  },
  {
    path: '/admin/:id',
    name: 'ProjectDetail',
    component: ProjectDetailComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '営業部門用 案件 登録フォーム'
    }
  },
  // {
  //   path: '/sales/clients',
  //   name: 'ClientList',
  //   component: ClientListComponent,
  //   beforeEnter: ifAuthenticated
  // },
  {
    path: '/sales',
    name: 'ClientCreate',
    component: ClientRegisterComponent,
    beforeEnter: ifAuthenticated,
    meta: {
      title: '営業部門用 案件 登録フォーム'
    }
  },
  {path: '*', component: NotFound},
]


const router = new VueRouter({
  mode: 'history',
  routes: routes
});

export default router
