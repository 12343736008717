<template>
  <div class="container">
    <header-component></header-component>
    <div style="margin-bottom: 30px">
      <button class="btn btn-info">CSV</button>
    </div>
    <table class="table table-bordered">
      <thead class="thead-light">
      <tr>
        <th scope="col" style="background: royalblue">No</th>
        <th scope="col" style="background: rgb(43,54,147)">クライアント名</th>
        <th scope="col" style="background: rgb(43,54,147)">診断名</th>
        <th scope="col" style="background: rgb(43,54,147)">形式(人数)</th>
        <th scope="col" style="background: rgb(43,54,147)">ステータス</th>
        <th scope="col" style="background: rgb(253,125,123)">催促メール(開始日)</th>
        <th scope="col" style="background: rgb(48,103,201)">診断開始日</th>
        <th scope="col" style="background: rgb(48,103,201)">診断終了日時</th>
        <th scope="col" style="background: rgb(48,103,201)">質問紙到着希望日</th>
        <th scope="col" style="background: rgb(48,103,201)">質問紙BCon到着日</th>
        <th scope="col" style="background: rgb(48,103,201)">納品希望日</th>
        <th scope="col" style="background: rgb(48,103,201)">リスト締切日</th>
        <th scope="col" style="background: rgb(253,125,123)">催促メール(リスト)</th>
        <th scope="col" style="background: rgb(253,148,38)">リスト</th>
        <th scope="col" 　style="background: rgb(43,54,147)">キャンセル</th>
        <th tyle="background: rgb(43,54,147)">完了</th>
        <!--<th tyle="background: rgb(43,54,147)">完了()</th>-->
      </tr>
      </thead>
      <tbody>
      <tr v-for="(p, i) in projects.data" v-show="projects.data && projects.data.length > 0">
        <th>{{ p.id }}</th>
        <td> <router-link :to="{ name: 'ProjectDetail', params: { id: p.id }  }">{{ p.client_name }}</router-link></td>
        <td>{{ projectType(p.project_type, p.is_multisitpe_diagnosis) }}</td>
        <td>{{ p.people }}</td>
        <td><button v-if="projectStatus(p.progress_status)" :class="'status btn bg-' + p.progress_status">{{ projectStatus(p.progress_status) }}</button></td>
        <td></td>
        <td>{{ p.start | datetimeFormat }}</td>
        <td>{{ p.end | datetimeFormat }}</td>
        <td></td>
        <td>{{ p.paper_preferred_date | datetimeFormat }}</td>
        <td>{{ p.paper_bcon_receipt_date | datetimeFormat }}</td>
        <td>{{ p.report_preferred_date | dateFormat }}</td>
        <td></td>
        <td></td>
        <td>
          <a href="javascript:;" class=" btn btn-danger delete" @click="openDelete(p.id)">削除</a>
        </td>
        <td>{{ p.progress_status == 5 ? p.consultant_user && p.consultant_user.name ? '(' + p.consultant_user.name + ')' : '' : '' }}</td>
        <!--<td>{{ p.consultant_user && p.consultant_user.name ? p.consultant_user.name : '' }}</td>-->
      </tr>
      </tbody>
    </table>
    <pagination-component :current-page="projects.current_page"
                          :per-page="projects.per_page"
                          :total="projects.total"
                          @changePage="changePage"
                          v-if="projects.total > 1"
    ></pagination-component>
    <b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action']" :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
      <h5>クライアント名を削除しますか？</h5>
      <div class="message"><div></div></div>
      <div class="modal-footer top-30">
        <b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
        <a class="btn btn-yellow" @click="deleteProject()">削除</a>
      </div>
    </b-modal>
  </div>

</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {PROJECT_STATUS, PROJECT_TYPE} from './../../../contstant'

  export default {
    data() {
      return {
        dId: null
      }
    },
    computed: {
      ...mapState('project', ['projects'])
    },
    methods: {
      ...mapMutations('project', ['LIST_PROJECTS']),
      ...mapActions('project', ['GET_LIST_PROJECTS']),
      projectType(project_type, is_multisite_diagnosis) {
        return PROJECT_TYPE[is_multisite_diagnosis] && PROJECT_TYPE[is_multisite_diagnosis][project_type] ? PROJECT_TYPE[is_multisite_diagnosis][project_type] : ''
      },
      projectStatus(status) {
        return PROJECT_STATUS[status] ? PROJECT_STATUS[status] : ''
      },
      changePage(page) {
        this.$router.push({name: 'ProjectList', query: {page: page}})
        this.GET_LIST_PROJECTS(page)
      },
      openDelete(id) {
        this.$refs['deletePopup'].show()
        this.dId = id
      },
      deleteProject() {
        this.$store.dispatch('project/DELETE_PROJECT', this.dId).then(() => {
          this.GET_LIST_PROJECTS(1);
          this.$refs.deletePopup.hide()
        })
        // this.$refs['deletePopup'].hide()
      }
    },
    created() {
      let page = this.$route.query.page ? this.$route.query.page : 1
      this.GET_LIST_PROJECTS(page)
    },
    mounted() {
      this.projectType()
    }

  }
</script>
<style scoped lang="scss">
  .container {
    padding-top: 30px;
  }

  .thead-light th {
    color: #fff;
    vertical-align: middle;
  }

  .delete {
    background: rgb(240, 38, 27);
    color: #fff;
    text-align: center;
    font-size: 11px;
  }

  .bg-1 {
    background-color: rgb(231, 184, 218);
  }

  .bg-3 {
    background-color: rgb(193, 218, 174);
  }

  .bg-4 {
    background-color: rgb(177, 181, 215);
  }

  .bg-5 {
    background-color: rgb(219, 218, 175);
  }
</style>
