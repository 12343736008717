<template>
  <div class="container" v-show="project && project.id">
    <header-component :title="'組織診断サーベイ'"></header-component>

    <div class="top_contact fx_cece">
      <div class="top_con1">
        <img src="/images/top_qu.png" srcset="/images/top_qu.png 1x, /images/top_qu@2x.png 2x" alt="お問合せ先"><br>お問合せ先
      </div>
      <div class="top_con2">
        <p>不明点や確認事項がありましたら直接診断チームまでお問合せください。</p>
        <div class="fx_ce">
          <p class="top_con2_p border_left">
            <img src="/images/top_mail.png" srcset="/images/top_mail.png 1x, /images/top_mail@2x.png 2x" alt="メールでのお問合せ">メールでのお問合せ<br>
            <span class="span_color"><a href="mailto:quest@info.bcon.jp">quest@info.bcon.jp</a></span>
          </p>
          <p class="top_con2_p">
            <img src="/images/top_tel.png" srcset="/images/top_tel.png 1x, /images/top_tel@2x.png 2x" alt="お電話でのお問合せ">お電話でのお問合せ<br>
            <span>03-3287-3416(平日 10:00～17:00)</span>
          </p>
        </div>
      </div>
    </div>

    <div class="attention">
      <p>以下の内容で企業診断を受け付けております。</p>
    </div>

    <form action="" method="post">
      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>お客様情報</p>
        </div>
        <dl>
          <dt>組織名(会社名)</dt>
          <dd>{{ project.client_name }}</dd>
        </dl>
        <dl>
          <dt>ご担当者様 所属・役職</dt>
          <dd><div v-if="project && project.user && project.user[0]">{{ project.user[0].division }} {{ project.user[0].position }}</div></dd>
        </dl>
        <dl>
          <dt>ご担当者様 氏名</dt>
          <dd>{{ project && project.user && project.user[0] ? project.user[0].name : '' }}</dd>
        </dl>
        <dl>
          <dt>ご担当者様 メールアドレス</dt>
          <dd>{{ project && project.user && project.user[0] ? project.user[0].email : '' }}</dd>
        </dl>
        <dl>
          <dt>ご担当者様2人目 氏名</dt>
          <dd>
            {{ project && project.user && project.user[1] ? project.user[1].name : '' }}
          </dd>
        </dl>
        <dl>
          <dt>ご担当者様2人目 メールアドレス</dt>
          <dd>
            {{ project && project.user && project.user[1] ? project.user[1].email : '' }}
          </dd>
        </dl>
        <dl>
          <dt>電話番号</dt>
          <dd>
            {{ project.client_tel }}
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>住所</dt>
          <dd>
            <p>〒{{ project.zip_code }}</p>
            <p>{{ project.address1 }}{{ project.address2 }}</p>
          </dd>
        </dl>
      </div>

      <div class="form_box form_box_1_3">
        <div class="form_box_title">
          <p>申込内容</p>
        </div>
        <dl>
          <dt width="25%">実施方法</dt>
          <dd width="75%">
            {{ this.medium_types[project.medium_type] ? this.medium_types[project.medium_type] : '' }}
            {{ (parseInt(project.web_people) || 0) + (parseInt(project.web_people) || 0) }}名
          </dd>
        </dl>
        <dl>
          <dt class="top">回答スケジュール</dt>
          <dd class="fx">
            <div class="form_box_2_4">
              <p id="margin_left_20" class="form_box_2_4_p form_box_2_4_2_p">
                <span>診断用書類(必須)締切日　2020年x月x日</span>
              </p>
              <div v-if="project.medium_type == 1" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">Web診断開始日時</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | datetimeFormat }}</p>
                </div>
                <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                  <p class="col-140">Web診断終了日時</p>
                  <p class="col margin_l_2_4">{{ project.web_end | datetimeFormat }}</p>
                </div>
              </div>
              <div v-if="project.medium_type == 2" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙到着希望日</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce">
                  <p>質問紙提出期限(任意)</p>
                  <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙BCon到着日</p>
                  <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                </div>
              </div>
              <div v-if="project.medium_type == 3" class="form_box_360">
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">Web診断開始日時</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | datetimeFormat }}</p>
                </div>
                <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                  <p class="col-140">Web診断終了日時</p>
                  <p class="col margin_l_2_4">{{ project.web_end | datetimeFormat }}</p>
                </div>
                <div>
                  <hr>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙到着希望日</p>
                  <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce">
                  <p>質問紙提出期限(任意)</p>
                  <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                </div>
                <div class="margin_b_2_4 fx_ce row">
                  <p class="col col-140">質問紙BCon到着日</p>
                  <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <dl>
          <dt>報告書納品希望日</dt>
          <dd>{{ project.report_preferred_date | dateFormat }}</dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>該当する業種・規模</dt>
          <dd>業種　　　：　{{ this.industry_types[project.industry_type] ? this.industry_types[project.industry_type] : '' }}<br>
            規模　　　：　{{ this.scale_types[project.scale_type] ? this.scale_types[project.scale_type] : '' }}</dd>
        </dl>
        <dl>
          <dt>備考欄</dt>
          <dd><div v-if="project && project.memo && project.memo.length > 0">
            <div v-for="line in project.memo.split('\n')">{{ line }}
            </div>
          </div>
            <div v-else>なし</div></dd>
        </dl>
        <dl>
          <dt>個人情報保管期間</dt>
          <dd></dd>
        </dl>
      </div>

      <div class="form_box form_box_1_3 form_box_5_2">
        <div class="form_box_title">
          <p>事前情報</p>
        </div>
        <dl>
          <dt>組織名</dt>
          <dd>{{ project.org_name }}</dd>
        </dl>
        <dl>
          <dt>診断名</dt>
          <dd>{{ project.diagnosis_name }}</dd>
        </dl>
        <dl>
          <dt>属性情報の設定</dt>
          <dd>{{ attribute_setting_types[project.attribute_setting_type] ? attribute_setting_types[project.attribute_setting_type] : '' }}</dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt>ID・パスワード設定</dt>
          <dd class="l_height">
            ID：　　　　{{ setting_types[project.id_setting_type] ? setting_types[project.id_setting_type] : '' }}<br>
            パスワード：{{ setting_types[project.pass_setting_type] ? setting_types[project.pass_setting_type] : '' }}
          </dd>
        </dl>
        <dl class="form_box_1_3_dl">
          <dt class="height_up_3_2">貴社お問合せ先</dt>
          <dd class="l_height">
            部署・役職 {{ project.inquiry_division }}<br>
            氏名 {{ project.inquiry_name }}<br>
            電話 {{ project.inquiry_tel }}<br>
            メール {{ project.inquiry_email }}<br>
          </dd>
        </dl>
        <dl>
          <dt>質問紙提出先</dt>
          <dd>●●部署　▲▲さん</dd>
        </dl>
        <dl>
          <dt>業種別比較</dt>
          <dd>{{ project.comparison_industry_type ? '有り' : '無し' }}</dd>
        </dl>
        <dl>
          <dt>回答者への案内メール</dt>
          <dd>{{ guidance_email_types[project.guidance_email_type] ? guidance_email_types[project.guidance_email_type] : ''}}</dd>
        </dl>
        <dl>
          <dt>質問文の主語変更</dt>
          <dd></dd>
        </dl>
        <dl class="fx">
          <dt>追加質問文</dt>
          <dd class="padding_tb">
            追加質問が5問以内/追加質問の尺度が左記の7点法を利用<br>
            【質問文】<br>
            質問文が入ります。ダミーテキスト<br>
            【質問文】<br>
            質問文が入ります。ダミーテキスト
          </dd>
        </dl>
        <dl>
          <dt class="hight_up_5_2">区分コードファイル(Excel)</dt>
          <dd>
            <p class="form_box_sub_5_2_p">
              <!--<span>未</span>-->
              <!--<a :href="project.exportLink" target="_blank">-->
                <!--<input class="form_box_sub_2 form_box_sub_5_2" type="button" value="ファイルを入力→アップロード">-->
              </a>
            </p>
          </dd>
        </dl>
        <dl>
          <dt class="hight_up_5_2">対象者リスト(Excel)</dt>
          <dd>
            <p class="form_box_sub_5_2_p">
              <!--<span>未</span><input class="form_box_sub_2 form_box_sub_5_2"-->
                                   <!--type="submit" value="ファイルを入力→アップロード">-->
            </p>
          </dd>
        </dl>
      </div>

      <div class="footer_box footer_box_1_3">
        <p></p>
        <p class="btn">
          <input class="form_box_sub form_box_sub_2" type="button"
                 value="戻る" @click="backForm()">
        </p>
      </div>
    </form>
  </div>
</template>
<script>
  import {mapActions, mapMutations, mapState} from 'vuex'
  import {
    INDUSTRY_TYPE,
    MEDIUM_TYPE,
    ATTRIBUTE_SETTING_TYPE,
    SCALE_TYPE,
    SELECT_KUBUN_TYPE,
    SETTING_TYPE,
    GUIDENCE_EMAIL_TYPE
  } from './../../../contstant'

  export default {
    data() {
      return {
        select_kubun_types: SELECT_KUBUN_TYPE,
        scale_types: SCALE_TYPE,
        industry_types: INDUSTRY_TYPE,
        medium_types: MEDIUM_TYPE,
        setting_types: SETTING_TYPE,
        guidance_email_types: GUIDENCE_EMAIL_TYPE,
        attribute_setting_types: ATTRIBUTE_SETTING_TYPE
      }
    },
    watch: {
      'project.progress_status': function() {
        if ((this.project.progress_status == 1 || this.project.progress_status == 0) && parseFloat(this.newStep) < 2) {
          this.$router.push({
            name: 'apply'
          })
        }
      }
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project']),
      ...mapState('project', ['newStep'])
    },
    methods: {
      backForm() {
        this.$router.push({
          name: 'apply'
        })
      }
    },
    created() {
    }
  }
</script>
<style scoped lang="scss">
</style>
