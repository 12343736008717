var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("header-component", { attrs: { title: "組織診断サーベイ" } }),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "body_1_2" } }, [
      _c("div", { staticClass: "content_box_4_5" }, [
        _c("p", [
          _vm._v("\n\t\t\t\tご登録ありがとうございます。"),
          _c("br"),
          _vm._v(
            " 〇〇診断についてのお申込み・事前情報・診断用資料を受理致しました。"
          ),
          _c("br"),
          _vm._v(
            "\n\t\t\t\t後ほど、ご登録いただいたメールアドレス宛に【BCon】の件名で受付完了メールが届きます。"
          ),
          _c("br"),
          _vm._v(" ご登録内容に相違ないかご確認ください。"),
          _c("br"),
          _vm._v(
            "\n\t\t\t\t尚、スケジュールに関しては、ご要望によって変動の可能性もありますことをご留意ください。"
          ),
          _c("br"),
          _vm._v(
            "\n\t\t\t\t弊社にてお申込み内容を確認させて頂いた上で、3営業日以内に弊社担当者からご連絡をさせて頂きます。"
          ),
          _c("br"),
          _vm._v(
            "\n\t\t\t\t今後、対象者に関する情報変更やご相談ごとなどは、担当者に直接メールにてご連絡下さい。"
          ),
          _c("br"),
          _vm._v(
            "\n\t\t\t\t皆さまにとりまして、株式会社ビジネスコンサルタントのソリューションが"
          ),
          _c("br"),
          _vm._v(" 組織開発・人材開発の一助となれば幸いです。"),
          _c("br"),
          _vm._v(" "),
          _c("br"),
          _vm._v("\n\t\t\t\t(株)ビジネスコンサルタント"),
          _c("br"),
          _vm._v(" 診断サービスグループ 担当"),
          _c("br"),
          _vm._v(" "),
          _c("a", { attrs: { href: "mailto:quest@info.bcon.jp" } }, [
            _vm._v("quest@info.bcon.jp")
          ]),
          _c("br"),
          _vm._v("\n\t\t\t\tTEL：03-3287-3416 平日 10:00～17:00\n\t\t\t")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "back_box_4_5" }, [
        _c("img", {
          attrs: {
            src: "/images/4_5_back.png",
            alt: "「組織診断サーベイ診断」を受け付けました",
            srcset:
              "/images/4_5_back.png" +
              " 1x, " +
              "/images/4_5_back@2x.png" +
              " 2x"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }