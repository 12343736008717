<template>
	<div class="container" v-show="projectInfo && projectInfo.id">
		<header-component></header-component>
		<!--<router-link :to="{ name: 'ProjectList' }">リスト一覧</router-link>
		<div>
			案件No.{{ projectInfo.id }}
			<button :class="'status btn bg-' + projectInfo.progress_status">{{ progressStatus() }}</button>
			<div class="float-right">
				<input id="check" type="checkbox" @change="updateComplete" :checked="projectInfo.progress_status == 5"><label for="check">完了にする</label>
				<button class="btn btn-danger" @click="$refs.deletePopup.show()">削除</button>
			</div>
		</div>
		<div class="p-status">
			<span>ステークス</span>
			<button class="status btn bg-1">案件登録済み</button>
			<button class="status btn bg-2">申込済</button>
			<button class="status btn bg-3">リスト登録済</button>
			<button class="status btn bg-4">診断中</button>
			<button class="status btn bg-5">完了</button>
		</div> -->
		
		<div class="top_contact fx_cece">
		<div class="top_con1">
			<img src="/images/top_qu.png" srcset="/images/top_qu.png 1x, /images/top_qu@2x.png 2x" alt="お問合せ先"><br>お問合せ先
		</div>
		<div class="top_con2">
			<p>不明点や確認事項がありましたら直接診断チームまでお問合せください。</p>
			<div class="fx_ce">
				<p class="top_con2_p border_left">
					<img src="/images/top_mail.png" srcset="/images/top_mail.png 1x, /images/top_mail@2x.png 2x" alt="メールでのお問合せ">メールでのお問合せ<br>
					<span class="span_color"><a href="mailto:quest@info.bcon.jp">quest@info.bcon.jp</a></span>
				</p>
				<p class="top_con2_p">
					<img src="/images/top_tel.png" srcset="/images/top_tel.png 1x, /images/top_tel@2x.png 2x" alt="お電話でのお問合せ">お電話でのお問合せ<br>
					<span>03-3287-3416(平日 10:00～17:00)</span>
				</p>
			</div>
		</div>
	</div>

	<div class="attention">
		<p>以下の内容で企業診断を受け付けております。</p>
	</div>
	
	<form action="" method="post">
		<div class="form_box form_box_1_3">
			<div class="form_box_title">
				<p>お客様情報</p>
			</div>
			<dl>
				<dt>組織名(会社名)</dt>
				<dd>{{ projectInfo.client_name }}</dd>
			</dl>
			<dl>
				<dt>ご担当者様 所属・役職</dt>
				<dd><div v-if="projectInfo && projectInfo.user && projectInfo.user[0]">{{ projectInfo.user[0].division }} {{ projectInfo.user[0].position }}</div></dd>
			</dl>
			<dl>
				<dt>ご担当者様 氏名</dt>
				<dd>{{ projectInfo && projectInfo.user && projectInfo.user[0] ? projectInfo.user[0].name : '' }}</dd>
			</dl>
			<dl>
				<dt>ご担当者様 メールアドレス</dt>
				<dd>{{ projectInfo && projectInfo.user && projectInfo.user[0] ? projectInfo.user[0].email : '' }}</dd>
			</dl>
			<dl>
				<dt>ご担当者様2人目 氏名</dt>
				<dd>
					{{ projectInfo && projectInfo.user && projectInfo.user[1] ? projectInfo.user[1].name : '' }}
				</dd>
			</dl>
			<dl>
				<dt>ご担当者様2人目 メールアドレス</dt>
				<dd>
					{{ projectInfo && projectInfo.user && projectInfo.user[1] ? projectInfo.user[1].email : '' }}
				</dd>
			</dl>
			<dl>
				<dt>電話番号</dt>
				<dd>
					{{ projectInfo.client_tel }}
				</dd>
			</dl>
			<dl class="form_box_1_3_dl">
				<dt>住所</dt>
				<dd>
					<p>〒{{ projectInfo.zip_code }}</p>
					<p>{{ projectInfo.address1 }}{{ projectInfo.address2 }}</p>
				</dd>
			</dl>
		</div>

		<div class="form_box form_box_1_3">
			<div class="form_box_title">
				<p>申込内容</p>
			</div>
			<dl>
				<dt width="25%">実施方法</dt>
				<dd width="75%">
					{{ this.medium_types[projectInfo.medium_type] ? this.medium_types[projectInfo.medium_type] : '' }}
								{{ (parseInt(projectInfo.web_people) || 0) + (parseInt(projectInfo.web_people) || 0) }}名
						</dd>
			</dl>
			<dl>
				<dt class="top">回答スケジュール</dt>
				<dd class="fx">
					<div class="form_box_2_4">
						<p id="margin_left_20" class="form_box_2_4_p form_box_2_4_2_p">
							<span>診断用書類(必須)締切日　2020年x月x日</span>
						</p>
						<div v-if="projectInfo.medium_type == 1" class="form_box_360">
							<div class="margin_b_2_4 fx_ce row">
								<p class="col col-140">Web診断開始日時</p>
								<p class="col margin_l_2_4 stick_2_4">{{ projectInfo.web_start | datetimeFormat }}</p>
							</div>
							<div class="row margin_b_2_4 margin_b_2_3 fx_ce">
								<p class="col-140">Web診断終了日時</p>
								<p class="col margin_l_2_4">{{ projectInfo.web_end | datetimeFormat }}</p>
							</div>
						</div>
						<div v-if="projectInfo.medium_type == 2" class="form_box_360">
							<div class="margin_b_2_4 fx_ce row">
								<p class="col col-140">質問紙到着希望日</p>
								<p class="col margin_l_2_4 stick_2_4">{{ projectInfo.paper_preferred_date | dateFormat }}</p>
							</div>
							<div class="margin_b_2_4 fx_ce">
								<p>質問紙提出期限(任意)</p>
								<p class="margin_l_2_4 stick_2_4">{{ projectInfo.paper_limit_date | dateFormat }}</p>
							</div>
							<div class="margin_b_2_4 fx_ce row">
								<p class="col col-140">質問紙BCon到着日</p>
								<p class="col margin_l_2_4">{{ projectInfo.paper_bcon_receipt_date | dateFormat }}</p>
							</div>
						</div>
						<div v-if="projectInfo.medium_type == 3" class="form_box_360">
							<div class="margin_b_2_4 fx_ce row">
								<p class="col col-140">Web診断開始日時</p>
								<p class="col margin_l_2_4 stick_2_4">{{ projectInfo.web_start | datetimeFormat }}</p>
							</div>
							<div class="row margin_b_2_4 margin_b_2_3 fx_ce">
								<p class="col-140">Web診断終了日時</p>
								<p class="col margin_l_2_4">{{ projectInfo.web_end | datetimeFormat }}</p>
							</div>
							<div>
								<hr>
							</div>
							<div class="margin_b_2_4 fx_ce row">
								<p class="col col-140">質問紙到着希望日</p>
								<p class="col margin_l_2_4 stick_2_4">{{ projectInfo.paper_preferred_date | dateFormat }}</p>
							</div>
							<div class="margin_b_2_4 fx_ce">
								<p>質問紙提出期限(任意)</p>
								<p class="margin_l_2_4 stick_2_4">{{ projectInfo.paper_limit_date | dateFormat }}</p>
							</div>
							<div class="margin_b_2_4 fx_ce row">
								<p class="col col-140">質問紙BCon到着日</p>
								<p class="col margin_l_2_4">{{ projectInfo.paper_bcon_receipt_date | dateFormat }}</p>
							</div>
						</div>
					</div>
				</dd>
			</dl>
			<dl>
				<dt>報告書納品希望日</dt>
				<dd>{{ project.report_preferred_date | dateFormat }}</dd>
			</dl>
			<dl class="form_box_1_3_dl">
				<dt>該当する業種・規模</dt>
						<dd>業種　　　：　{{ this.industry_types[projectInfo.industry_type] ? this.industry_types[projectInfo.industry_type] : '' }}<br>
								規模　　　：　{{ this.scale_types[projectInfo.scale_type] ? this.scale_types[projectInfo.scale_type] : '' }}</dd>
			</dl>
			<dl>
				<dt>備考欄</dt>
				<dd><div v-if="projectInfo && projectInfo.memo && projectInfo.memo.length > 0">
					<div v-for="line in projectInfo.memo.split('\n')">{{ line }}
					</div>
				</div>
					<div v-else>なし</div></dd>
			</dl>
			<dl>
				<dt>個人情報保管期間</dt>
				<dd></dd>
			</dl>
		</div>

		<div class="form_box form_box_1_3 form_box_5_2">
			<div class="form_box_title">
				<p>事前情報</p>
			</div>
			<dl>
				<dt>組織名</dt>
				<dd>{{ projectInfo.org_name }}</dd>
			</dl>
			<dl>
				<dt>診断名</dt>
				<dd>{{ projectInfo.diagnosis_name }}</dd>
			</dl>
			<dl>
				<dt>属性情報の設定</dt>
				<dd></dd>
			</dl>
			<dl class="form_box_1_3_dl">
				<dt>ID・パスワード設定</dt>
				<dd class="l_height">
					ID：　　　　{{ setting_types[projectInfo.id_setting_type] ? setting_types[projectInfo.id_setting_type] : '' }}<br>
					パスワード：{{ setting_types[projectInfo.pass_setting_type] ? setting_types[projectInfo.pass_setting_type] : '' }}
				</dd>
			</dl>
			<dl class="form_box_1_3_dl">
				<dt class="height_up_3_2">貴社お問合せ先</dt>
				<dd class="l_height">
					部署・役職 {{ projectInfo.inquiry_division }}<br> 
					氏名 {{ projectInfo.inquiry_name }}<br> 
					電話 {{ projectInfo.inquiry_tel }}<br> 
					メール {{ projectInfo.inquiry_email }}<br>
				</dd>
			</dl>
			<dl>
				<dt>質問紙提出先</dt>
				<dd>●●部署　▲▲さん</dd>
			</dl>
			<dl>
				<dt>業種別比較</dt>
				<dd>{{ projectInfo.comparison_industry_type ? '有り' : '無し' }}</dd>
			</dl>
			<dl>
				<dt>回答者への案内メール</dt>
				<dd>{{ guidance_email_types[projectInfo.guidance_email_type] ? guidance_email_types[projectInfo.guidance_email_type] : ''}}</dd>
			</dl>
			<dl>
				<dt>質問文の主語変更</dt>
				<dd></dd>
			</dl>
			<dl class="fx">
				<dt>追加質問文</dt>
				<dd class="padding_tb">
					追加質問が5問以内/追加質問の尺度が左記の7点法を利用<br> 
					【質問文】<br>
					質問文が入ります。ダミーテキスト<br> 
					【質問文】<br> 
					質問文が入ります。ダミーテキスト
				</dd>
			</dl>
				<dl>
					<dt class="hight_up_5_2">区分コードファイル(Excel)</dt>
					<dd>
						<p class="form_box_sub_5_2_p">
							<span>未</span>
							<a :href="projectInfo.exportLink" target="_blank">
								<input class="form_box_sub_2 form_box_sub_5_2" type="button" value="ファイルを入力→アップロード">
							</a>
						</p>
					</dd>
				</dl>
				<dl>
					<dt class="hight_up_5_2">対象者リスト(Excel)</dt>
					<dd>
						<p class="form_box_sub_5_2_p">
							<span>未</span><input class="form_box_sub_2 form_box_sub_5_2"
								type="submit" value="ファイルを入力→アップロード">
						</p>
					</dd>
				</dl>
		</div>

		<div class="footer_box footer_box_1_3">
			<p></p>
			<p class="btn">
				<input class="form_box_sub form_box_sub_2" type="submit"
					value="登録内容を変更する">
			</p>
		</div>
	</form>
	
		<!-- html old
			
		<div class="form_box form_box_1_3">
			<div class="form_box_title">
				<p>基本情報</p>
			</div>
			<dl>
				<dt>顧客名(会社名)</dt>
				<dd>{{ projectInfo.client_name }}</dd>
			</dl>
			<dl>
				<dt>診断名</dt>
				<dd>{{ projectInfo.diagnosis_name }}</dd>
			</dl>
			<dl>
				<dt>BCon担当営業名</dt>
				<dd>{{ projectInfo.staff_name }}</dd>
			</dl>
			<dl>
				<dt>配信先メールアドレス①</dt>
				<dd>{{ projectInfo.staff_email1 }}</dd>
			</dl>
			<dl>
				<dt>配信先メールアドレス②</dt>
				<dd>{{ projectInfo.staff_email2 }}</dd>
			</dl>
			<dl>
				<dt>備考</dt>
				<dd>{{ projectInfo.memo }}</dd>
			</dl>
		</div>

		<div class="form_box form_box_1_3">
			<div class="form_box_title">
				<p>お客さま情報</p>
			</div>
			<dl>
				<dt>ご担当者様　所属</dt>
				<dd>{{ projectInfo.division }}</dd>
			</dl>
			<dl>
				<dt>ご担当者様　役職</dt>
				<dd>{{ projectInfo.position }}</dd>
			</dl>
			<dl>
				<dt>ご担当者様　氏名</dt>
				<dd>{{ projectInfo && projectInfo.user ? projectInfo.user.name : '' }}</dd>
			</dl>
			<dl>
				<dt>ご担当者様　メールアドレス</dt>
				<dd>{{ projectInfo && projectInfo.user ? projectInfo.user.email : '' }} <a class="float-right btn btn-primary" @click="$refs.sendPasswordPopup.show()">アカウント再発行</a>
				</dd>
			</dl>
		</div>

		<div class="form_box form_box_1_3">
			<div class="form_box_title">
				<p>申込内容</p>
			</div>
			<dl>
				<dt width="25%">実施方法</dt>
				<dd width="75%">
					{{ this.medium_types[projectInfo.medium_type] ? this.medium_types[projectInfo.medium_type] : '' }}
					{{ (parseInt(projectInfo.web_people) || 0) + (parseInt(projectInfo.web_people) || 0) }}名
				</dd>
			</dl>
			<dl>
				<dt>診断スケジュール</dt>
				<dd>
					<div v-if="projectInfo.medium_type == 1">
						<p>診断開始日 {{ projectInfo.web_start }}</p>
						<p>診断終了日{{ projectInfo.web_end }}</p>
						<p>レポート納品希望日 {{ projectInfo.report_preferred_date }}</p>
					</div>
					<div v-if="projectInfo.medium_type == 2">
						<p>質問紙到着希望日 {{ projectInfo.paper_preferred_date }}</p>
						<p>質問紙提出期限{{ projectInfo.paper_preferred_date }}</p>
						<p>診断終了日時 {{ projectInfo.end }}</p>
						<p>質問紙BCon到着日（任意） {{ projectInfo.paper_bcon_receipt_date }}</p>
						<p>報告書納品希望日 {{ projectInfo.report_preferred_date }}</p>
					</div>
					<div v-if="projectInfo.medium_type == 3">
						<p>web診断開始日時 {{ projectInfo.web_start }}</p>
						<p>web診断終了日時{{ projectInfo.web_end }}</p>
						<p>質問紙到着希望日 {{ projectInfo.paper_preferred_date }}</p>
						<p>質問紙提出期限 {{ projectInfo.paper_preferred_date }}</p>
						<p>診断終了日時 {{ projectInfo.end }}</p>
						<p>質問紙BCon到着日（任意） {{ projectInfo.paper_bcon_receipt_date }}</p>
						<p>報告書納品希望日 {{ projectInfo.report_preferred_date }}</p>
					</div>

					<div class="form_box_2_4 fx_ce2">
							<div>
									<div class="margin_b_2_4 fx_ce">
											<p>Web診断開始日時</p>
											<p class="margin_l_2_4 stick_2_4">2020年04月15日 13:00</p>
									</div>
									<div class="margin_b_2_4 fx_ce">
											<p>Web診断終了日時</p>
											<p class="margin_l_2_4">2020年x月x日 13:00</p>
									</div>
							</div>
							<p class="form_box_2_4_p form_box_2_4_2_p">
									<span>診断用書類(必須)締切日　2020年x月x日</span>
							</p>
					</div>
				</dd>
			</dl>
			<dl>
				<dt>該当する業界・業種・規模</dt>
				<dd>
					<p>業界・業種: {{ this.industry_types[projectInfo.industry_type] ? this.industry_types[projectInfo.industry_type] :
						'' }}</p>
					<p>規模: {{ this.scale_types[projectInfo.scale_type] ? this.scale_types[projectInfo.scale_type] : '' }} </p>
				</dd>
			</dl>
			<dl>
				<dt>個人情報保管期間</dt>
				<dd></dd>
			</dl>
		</div>

		<div class="form_box form_box_1_3">
			<div class="form_box_title">
				<p>申込内容</p>
			</div>
			<dl>
				<dt>顧客名</dt>
				<dd>
						{{ projectInfo.org_name }}
				</dd>
			</dl>
			<dl>
				<dt>診断名</dt>
				<dd>
						{{ projectInfo.diagnosis_name }}
				</dd>
			</dl>
			<dl>
				<dt>区分コードの選択者</dt>
				<dd>
						{{ select_kubun_types[projectInfo.select_kubun_type] ? select_kubun_types[projectInfo.select_kubun_type] : ''  }}
				</dd>
			</dl>
			<dl>
				<dt>区分コードファイル(Excel)</dt>
				<dd><a class="btn btn-primary" :href="projectInfo.exportLink" target="_blank">Excelダウンロード</a></dd>
			</dl>
			<dl>
				<dt>ID・パスワード設定</dt>
				<dd>
						<p><span>ID: </span> {{ setting_types[projectInfo.id_setting_type] ? setting_types[projectInfo.id_setting_type] : '' }}</p>
						<p><span>パスワード:</span> {{ setting_types[projectInfo.pass_setting_type] ? setting_types[projectInfo.pass_setting_type] : '' }}</p>
				</dd>
			</dl>
			<dl>
				<dt>貴社お問い合わせ先</dt>
				<dd>
						<p><span>部署・役職: </span> {{ projectInfo.inquiry_division }}</p>
						<p><span>氏名:</span> {{ projectInfo.inquiry_name }}</p>
						<p><span>電話:</span> {{ projectInfo.inquiry_tel }}</p>
						<p><span>メール:</span>{{ projectInfo.inquiry_email }}</p>
				</dd>
			</dl>
			<dl>
				<dt>対象者リスト(Excel)</dt>
				<dd></dd>
			</dl>
		</div>
		-->
		
			<b-modal name="deletePopup" id="deletePopup" ref="deletePopup" :modal-class="['modal-action']" :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
				<h5>クライアント名を削除しますか？</h5>
				<div class="message"><div></div></div>
				<div class="modal-footer top-30">
					<b-button class="btn-submit" @click="$bvModal.hide('deletePopup')">キャンセル</b-button>
					<a class="btn btn-yellow" @click="deleteProject()">削除</a>
				</div>
			</b-modal>

			<b-modal name="sendPasswordPopup" id="sendPasswordPopup" ref="sendPasswordPopup" :modal-class="['modal-action']" :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
				<h5>Do you reset and send password to user？</h5>
				<div class="message"><div></div></div>
				<div class="modal-footer top-30">
					<b-button class="btn-submit" @click="$bvModal.hide('sendPasswordPopup')">キャンセル</b-button>
					<a class="btn btn-yellow" @click="sendPassword()">削除</a>
				</div>
			</b-modal>
	</div>
	


</template>
<script>
	import {mapActions, mapMutations, mapState} from 'vuex'
	import {
		INDUSTRY_TYPE,
		MEDIUM_TYPE,
		PROJECT_STATUS,
		SCALE_TYPE,
		SELECT_KUBUN_TYPE,
		SETTING_TYPE,
		GUIDENCE_EMAIL_TYPE
	} from './../../../contstant'

	export default {
		data() {
			return {
				select_kubun_types: SELECT_KUBUN_TYPE,
				scale_types: SCALE_TYPE,
				industry_types: INDUSTRY_TYPE,
				medium_types: MEDIUM_TYPE,
				setting_types: SETTING_TYPE,
				guidance_email_types: GUIDENCE_EMAIL_TYPE
			}
		},
		computed: {
			...mapState('project', ['projectInfo']),
			...mapState('consultantUser', ['user', 'project'])
		},
		methods: {
			...mapMutations('project', ['PROJECT']),
			...mapActions('project', ['GET_PROJECT_DETAIL']),
			progressStatus() {
				return PROJECT_STATUS[this.projectInfo.progress_status] ? PROJECT_STATUS[this.projectInfo.progress_status] : ''
			},
			
			deleteProject(){
				this.$store.dispatch('project/DELETE_PROJECT', this.projectInfo.id).then(() => {
					this.$refs.deletePopup.hide();
					this.$router.push({ name: 'ProjectList' });
				});
			},
			updateComplete(event){
				this.projectInfo.progress_status = event.target.checked ? 5 : 0;
				this.$store.dispatch('project/UPDATE_PROGRESS_STATUS', this.projectInfo);
			},
			sendPassword(){
				this.$refs.sendPasswordPopup.hide();
				this.$store.dispatch('project/SEND_PASSWORD', this.projectInfo);
			}
		},
		created() {
			let id = this.$route.params.id ? this.$route.params.id : 1
			this.GET_PROJECT_DETAIL(id);
		}
	}
</script>
<style scoped lang="scss">
	.container {
		padding-top: 20px;
	}

	.status {
		background-color: rgb(218, 89, 38);
		color: #fff;
		border-radius: 50px;
		margin-left: 30px;
		height: 30px;
		line-height: 1;
	}

	.float-right {
		input {
			margin-right: 10px;
		}

		label {
			margin-right: 20px;
		}
	}

	.p-status {
		margin-top: 20px;
		border: 2px solid red;
		padding: 10px;
	}

	.bg-1 {
		background-color: rgb(231, 184, 218);
	}

	.bg-3 {
		background-color: rgb(193, 218, 174);
	}

	.bg-4 {
		background-color: rgb(177, 181, 215);
	}

	.bg-5 {
		background-color: rgb(219, 218, 175);
	}

	.main-contain {
		margin-top: 30px;

		table {
			border-radius: 10px;

			th {
				background-color: rgb(43, 54, 174);
				color: #fff;
			}

			td {
				&:first-child {
					background-color: rgb(245, 245, 245);
				}
			}

			margin-bottom: 30px;
		}

		.btn-primary {
			color: #fff;
		}
	}

	input[type=checkbox]{
		display: unset;
	}
</style>
