import {configure, extend} from "vee-validate";
import {email, min, required, regex, digits, numeric } from "vee-validate/dist/rules";
import {i18n} from "./lang/i18n";

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);

    return i18n.t(`validation.${values._rule_}`, values);
  }
});

// Install required rule and message.
extend("required", required);

// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);

// Install regex rule and message.
extend("regex", regex);

// Install min digits and message.
extend("digits", digits);

// Install min digits and message.
extend("numeric", numeric);

extend('same', {
  validate(value, args) {
    return value !== args[0];
  },
  message: 'メールは担当者のメールと重複しています。もう一度ご入力してください。'
});
