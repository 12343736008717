<template>
  <div class="body_1_2">
    <ValidationObserver ref="observer" v-show="!is_preview && project.progress_status == 0" v-slot="{ invalid }"
                        tag="form" @submit.prevent="agree && agree_store ? saveProject() : ''">
      <div class="form_box form_box_2_3">
        <div class="form_box_title">
          <p>申込内容入力</p>
        </div>
        <dl class="">
          <dt class="required required10 height_20">組織名（会社名）</dt>
          <dd class="margin_top_10">
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="client_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <input :class="{'form_text': true, 'error': errors[0] }" name="client_name" placeholder="" type="text"
                     v-model="project.client_name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl>
          <dt class="required required4 height_21">所属・役職</dt>
          <dd>
            <div class="fx_ce">
              <ValidationProvider name="division" rules="required" v-slot="{ errors }" class="validate">
                <p class="position">所属
                  <input :class="{'form_text': true, 'error': errors[0] }" type="text" name="division" value="○○○部"
                         v-model="user.division">
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
              <ValidationProvider name="position" rules="required" v-slot="{ errors }" class="validate">
                <p class="position">役職
                  <input :class="{'form_text': true, 'error': errors[0] }" type="text" name="position" value="部長"
                         v-model="user.position">
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </p>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl>
          <dt class="required required8 height_22 top">氏名</dt>
          <dd class="margin_top_10">
            <ValidationProvider name="username" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{'form_text': true, 'error': errors[0] }" type="text" name="name" value="田中一郎"
                     v-model="user.name">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl class="">
          <dt class="required required7 top">電話番号</dt>
          <dd class="">
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="client_tel" rules="required|numeric"
                                v-slot="{ errors }" class="validate">
              <input :class="{'form_text tel_number': true, 'error': errors[0] }" name="client_tel"
                     placeholder="例：0332873421" type="text"
                     v-model="project.client_tel">
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>
        <dl class="">
          <dt class="required required8 form_box_2_3_dt top">住所<br>
            <span class="f12">レポート・報告書の納品先になります。</span>
          </dt>
          <dd class="margin_dd">
            <p>
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="zip_code" rules="required"
                                  v-slot="{ errors }" class="validate label_p">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="zip_code"
                        placeholder="例：1000005" type="text"
                        v-model="project.zip_code">
                <input type="button" class="input_button" @click="searchAddress()" value="住所検索"><span
                class=""></span><br>
                <span>※ハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }" v-model="project.address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }" v-model="project.address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
                <span class="validate">
                  ビル名
                  <input class="form_text form_address_3" v-model="project.address3" placeholder="例：サピアタワー　18F"
                         type="text">
                </span>
            </p>
          </dd>
        </dl>
        <dl class="radio_2">
          <dt class="required required9 form_box_2_3_dt_1 top">実施方法</dt>
          <dd>
            <p class="margin_dd">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="medium_type" rules="required"
                                  v-slot="{ errors }" class="validate">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type1" name="medium_type"
                       type="radio" value="1" @change="changeMedium()"
                       v-model="project.medium_type">
                <label class="form-check-label" for="medium_type1">Web</label>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type2" name="medium_type"
                       type="radio" value="2" @change="changeMedium()"
                       v-model="project.medium_type">
                <label class="form-check-label" for="medium_type2">質問紙</label>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" id="medium_type3" name="medium_type"
                       type="radio" value="3" @change="changeMedium()"
                       v-model="project.medium_type">
                <label class="form-check-label" for="medium_type3">Web + 質問紙</label>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>
            <div class="charge_box">
              <ValidationProvider v-if="project.medium_type == 1" :mode="agree ? 'eager' : 'passive'" name="web_people" rules="required" v-slot="{ errors }"
                                  class="validate no-padding">
                <p id="web_box">
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="web_people" type="number"
                         v-model="project.web_people"> 名<br><span>※想定人数で構いません。</span>
                </p>
              </ValidationProvider>
              <ValidationProvider v-if="project.medium_type == 2" :mode="agree ? 'eager' : 'passive'" name="paper_people" rules="required" v-slot="{errors }"
                                  class="validate no-padding">
                <p id="web_box1">
                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="paper_people" type="number"
                         v-model="project.paper_people"> 名<br><span>※想定人数で構いません。</span>
                </p>
              </ValidationProvider>
              <ValidationProvider v-if="project.medium_type == 3" :mode="agree ? 'eager' : 'passive'" name="web_people" rules="required" v-slot="{ errors }"
                                  class="validate no-padding">
                <p id="web_box2">　　Web

                  <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="web_people" type="number"
                         v-model="project.web_people"> 名
                  <!--<br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>-->
                  <br><span>※想定人数で構いません。</span>
                </p>
              </ValidationProvider>
              <ValidationProvider v-if="project.medium_type == 3" :mode="agree ? 'eager' : 'passive'" name="paper_people" rules="required" v-slot="{errors }"
                                  class="validate no-padding">
              <p id="web_box3">　　質問紙
                <input :class="{ 'form_text form_radio_text web_box_text': true, 'error': errors[0] }" name="paper_people" type="number"
                       v-model="project.paper_people"> 名
                <!--<br v-show="errors[0]"><span v-show="errors[0]" class="error">{{ errors[0] }}</span>-->
              </p>
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl class="radio_2" v-if="project.medium_type == 2 || project.medium_type == 3">
          <dt class="required required3">質問紙の送付先拠点数</dt>
          <dd>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_send_type" rules="required"
                                v-slot="{ errors }">
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type1" name="paper_send_type"
                     type="radio" value="1"
                     v-model="project.paper_send_type">
              <label class="form-check-label" for="paper_send_type1">1拠点</label>
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type2" name="paper_send_type"
                     type="radio" value="2"
                     v-model="project.paper_send_type">
              <label class="form-check-label" for="paper_send_type2">2拠点</label>
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="paper_send_type3" name="paper_send_type"
                     type="radio" value="3"
                     v-model="project.paper_send_type">
              <label class="form-check-label" for="paper_send_type3">3拠点以上(日程は目安となります。)</label>
              <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl class="" v-if="project.paper_send_type == 1 || project.paper_send_type == 2">
          <dt class="required required81 form_box_2_3_dt2 top">質問紙の宛先&#9312;</dt>
          <dd class="margin_dd">
            <span class="margin_l_2_3_3">※納品先と質問紙の納付先が異なる場合は、入力し直してください。</span>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_division" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>部署<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_division" placeholder="○○○部"
                          v-model="project.paper1_division"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>氏名<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_name" placeholder="田中一郎"
                          v-model="project.paper1_name"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_position" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>役職<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper1_position" placeholder="部長"
                          v-model="project.paper1_position"></p>
            </ValidationProvider>
            <p>
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_zip_code" rules="required"
                                  v-slot="{ errors }" class="validate">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="paper1_zip_code"
                        placeholder="例：1000005" type="text"
                        v-model="project.paper1_zip_code">
                <input type="button" class="input_button" @click="searchAddressPaper()" value="住所検索"><span
                class=""></span><br>
                <span>※ハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }"
                       v-model="project.paper1_address1"
                       name="paper1_address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }"
                       v-model="project.paper1_address2"
                       name="paper1_address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <span class="validate">
                ビル名
                <input class="form_text form_address_3" v-model="project.paper1_address3" placeholder="例：サピアタワー　18F"
                       type="text">
              </span>
            </p>
            <p>部数
              <strong>
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_copies" rules="required|numeric"
                                    v-slot="{ errors }" class="validate">
                  <input :class="{ 'form_text form_text_2_3_2': true, 'error': errors[0] }" name="paper1_copies"
                         placeholder=""
                         type="number"
                         v-model="project.paper1_copies"> 部
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </strong>
            </p>
          </dd>
        </dl>

        <dl class="" v-if="project.paper_send_type == 2">
          <dt class="required required81 form_box_2_3_dt2 top">質問紙の宛先&#9313;</dt>
          <dd class="margin_dd">
            <span class="margin_l_2_3_3">※納品先と質問紙の納付先が異なる場合は、入力し直してください。</span>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_division" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>部署<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_division" placeholder="○○○部"
                          v-model="project.paper2_division"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_name" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>氏名<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_name" placeholder="田中一郎"
                          v-model="project.paper2_name"></p>
            </ValidationProvider>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_position" rules="required"
                                v-slot="{ errors }" class="validate">
              <p>役職<input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="paper2_position" placeholder="部長"
                          v-model="project.paper2_position"></p>
            </ValidationProvider>
            <p>
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_zip_code" rules="required"
                                  v-slot="{ errors }" class="validate">
                〒<input :class="{ 'form_text form_address': true, 'error': errors[0] }" name="paper2_zip_code"
                        placeholder="例：1000005" type="text"
                        v-model="project.paper2_zip_code">
                <input type="button" class="input_button" @click="searchAddressPaper()" value="住所検索"><span
                class=""></span><br>
                <span>※ハイフンを入れずに入力してください。</span>
              </ValidationProvider>
            </p>
            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_address1" rules="required"
                                  v-slot="{ errors }" class="validate">
                都道府県
                <input :class="{ 'form_text form_address_1': true, 'error': errors[0] }"
                       v-model="project.paper2_address1"
                       placeholder="例：東京都" type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper2_address2" rules="required"
                                  v-slot="{ errors }" class="validate">
                市区町村番地
                <input :class="{ 'form_text form_address_2': true, 'error': errors[0] }"
                       v-model="project.paper2_address2"
                       placeholder="例：千代田区丸の内　1-7-12"
                       type="text">
                <br v-show="errors[0]"><span class="error left-150" v-show="errors[0]">{{ errors[0] }}</span>
              </ValidationProvider>
            </p>

            <p class="">
              <span class="validate">
                ビル名
                <input class="form_text form_address_3" v-model="project.paper2_address3" placeholder="例：サピアタワー　18F"
                       type="text">
              </span>
            </p>
            <p>部数
              <strong>
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper1_copies" rules="required|numeric"
                                    v-slot="{ errors }" class="validate">
                  <input :class="{ 'form_text form_text_2_3_2': true, 'error': errors[0] }" name="paper2_copies"
                         placeholder=""
                         type="number"
                         v-model="project.paper2_copies"> 部
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </strong>
            </p>
          </dd>
        </dl>
        <dl class="fx">
          <dt class="required required10 height_up_2_3_4 top">回答スケジュール
          </dt>
          <dd class="margin_dd position_r_2_3 no-padding">
            <div class="form_box_2_4">
              <p class="form_box_2_4_p form_box_2_3_p margin_t5">
                <span>対象者リスト提出締切日　2020年x月x日</span></p>
              <div class="margin_top_2_3">
                <div v-if="project.medium_type == 1">
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_start1" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>開始日時</p>
                    <div class="input-group stick_2_3 form_sch2_3_4 width-datepicker">
                      <date-picker v-model="project.web_start" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_start1"
                                   :wrap="true" :config="configs.web_start">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 1">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_end1" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>終了日時</p>
                    <div class="input-group form_sch2_3_4 width-datepicker">
                      <date-picker v-model="project.web_end" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_end1"
                                   :wrap="true" :config="configs.web_start">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                </div>

                <div v-if="project.medium_type == 2">
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_preferred_date2" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙到着
                      <br>希望日</p>

                    <div class="input-group margin_l_2_5 stick_2_3_4 width-datepicker">
                      <date-picker v-model="project.paper_preferred_date" :class="{'form_text form_sch 2': true, 'error': errors[0] }" name="project.paper_preferred_date2"
                                   :wrap="true" :config="configs.date">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 2">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_limit_date2" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙提出期限
                      <br>(任意)</p>
                    <div class="input-group margin_l_2_7 stick_2_3_4 width-datepicker">
                      <date-picker v-model="project.paper_limit_date" :class="{'form_text form_sch 2': true, 'error': errors[0] }" name="project.paper_limit_date2"
                                   :wrap="true" :config="configs.date">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <p class="no-margin"><img src="images/info.png" srcset="images/info.png 1x, images/info@2x.png 2x"
                                              alt="インフォメーション"
                                              class="info_2" @mouseover="mouseOver" @mouseleave="mouseLeave">
                    <p v-show="fukidashi2" class="info_fukidashi_2">質問紙に職場での回収期限を<br>記載したい場合に入力してください。
                    </p>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 2">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_bcon_receipt_date2" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙BCon
                      <br>到着日</p>
                    <div class="input-group margin_l_2_6 width-datepicker">
                      <date-picker v-model="project.paper_bcon_receipt_date" :class="{'form_text form_sch 2': true, 'error': errors[0] }" name="project.paper_bcon_receipt_date2"
                                   :wrap="true" :config="configs.date">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                </div>

                <div v-if="project.medium_type == 3">
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_start3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>開始日時</p>

                    <div class="input-group form_sch2_3_4 stick_2_3_4 width-datepicker">
                      <date-picker v-model="project.web_start" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_start3"
                                   :wrap="true" :config="configs.web_start">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="web_end3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>Web回答
                      <br>終了日時</p>
                    <div class="input-group form_sch2_3_4 width-datepicker">
                      <date-picker v-model="project.web_end" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.web_end3"
                                   :wrap="true" :config="configs.web_start">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!--<div v-if="project.medium_type == 3">-->
                  <hr>
                <!--</div>-->

                <!-- 質問紙到着希望日 paper_preferred_date -->
                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_preferred_date3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙到着
                      <br>希望日</p>
                    <div class="input-group margin_l_2_5 stick_2_3_4 width-datepicker">
                      <date-picker v-model="project.paper_preferred_date" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.paper_preferred_date3"
                                   :wrap="true" :config="configs.date">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!-- 質問紙提出期限 paper_limit_date -->
                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_limit_date3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce">
                    <p>質問紙提出期限
                      <br>(任意)</p>
                    <div class="input-group margin_l_2_7 stick_2_3_4 width-datepicker">
                      <date-picker v-model="project.paper_limit_date" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.paper_limit_date3"
                                   :wrap="true" :config="configs.date">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <p class="no-margin"><img src="images/info.png" srcset="images/info.png 1x, images/info@2x.png 2x"
                                              alt="インフォメーション"
                                              class="info_2" @mouseover="mouseOver" @mouseleave="mouseLeave">
                    <p v-show="fukidashi2" class="info_fukidashi_3">質問紙に職場での回収期限を<br>記載したい場合に入力してください。
                    </p>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                <!--</div>-->

                <!-- 質問紙BCon到着日 paper_bcon_receipt_date -->
                <!--<div v-if="project.medium_type == 3">-->
                  <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="paper_bcon_receipt_date3" rules="required"
                                      v-slot="{ errors }"
                                      class="validate margin_b_2_4 margin_b_2_3 fx_ce position_r_2_3">
                    <p>質問紙BCon
                      <br>到着日</p>
                    <div class="input-group margin_l_2_6 width-datepicker">
                      <date-picker v-model="project.paper_bcon_receipt_date" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.paper_bcon_receipt_date3"
                                   :wrap="true" :config="configs.date">
                      </date-picker>
                      <div class="input-group-append cal_icon">
                        <button class="btn datepickerbutton" type="button"></button>
                      </div>
                    </div>
                    <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                  </ValidationProvider>
                </div>

                <p v-if="project.medium_type == 3" class="position_ant_2_3"><span>※到着が遅れた場合、報告書納品が遅れる場合がございます。</span></p>

              </div>
            </div>
          </dd>
        </dl>
        <!--報告書納品希望日 report_preferred_date -->
        <dl class="fx">
          <dt class="required required32 top">報告書納品希望日</dt>
          <dd class="margin_dd">
            <span class="f12">Web回答終了日時を起点に報告書納品日が算出されます。</span>
            <div class="fx_ce2">
              <div>
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="report_preferred_date" rules="required"
                                    v-slot="{ errors }"
                                    class="validate fx_ce">
                  <div class="input-group margin_b_2_4_2 form_text_2_3 width-datepicker">
                    <date-picker v-model="project.report_preferred_date" :class="{'form_text form_sch': true, 'error': errors[0] }" name="project.report_preferred_date"
                                 :wrap="true" :config="configs.date">
                    </date-picker>
                    <div class="input-group-append cal_icon">
                      <button class="btn datepickerbutton" type="button"></button>
                    </div>
                  </div>
                  <!--<br v-show="errors[0]"><span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
                </ValidationProvider>
              </div>

            </div>
          </dd>
        </dl>
        <!--industry type-->
        <dl class="fx">
          <dt class="required required11 top">該当する業界・規模</dt>
          <dd class="margin_dd">
            <p>業種</p>
            <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="industry_type" rules="required"
                                v-slot="{ errors }"
                                class="validate fx_between">
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(0, 12, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="project.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(12, 24, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="project.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <ul class="industry">
                <li v-for="(industry_type, index) in sliced(24, industry_types.length, industry_types)">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'industry_type' + index"
                         name="industry_type"
                         type="radio"
                         :value="index" v-model="project.industry_type">
                  <label :for="'industry_type' + index">{{ industry_type }}</label>
                </li>
              </ul>
              <!--<span class="error" v-show="errors[0]">{{errors[0]}}</span>-->
              </ValidationProvider>
              <!-- scale type -->
              <div class="scale">
                <ValidationProvider :mode="agree ? 'eager' : 'passive'" name="scale_type" rules="required"
                                    v-slot="{ errors }" class="validate">
                  <p class="margin_dd">規模</p>
                  <div class="scale_1" v-for="i in Math.ceil(Object.keys(scale_types).length / 4)">
                    <p v-for="(scale_type, index) in sliced((i - 1) * 4, i * 4 < Object.keys(scale_types).length ? i * 4 : Object.keys(scale_types).length, scale_types)">
                      <input :class="{ 'form_radio': true, 'error': errors[0] }" :id="'scale_type' + index"
                             name="scale_type" type="radio"
                             :value="index" v-model="project.scale_type">
                      <label :for="'scale_type' + index">{{ scale_type }}</label>
                    </p>
                  </div>
                </ValidationProvider>
              </div>
          </dd>
        </dl>

        <!--memo-->
        <dl class="fx_normal">
          <dt class="top">備考欄</dt>
          <dd><textarea name="remarks" rows="7" cols="40" v-model="project.memo"></textarea></dd>
        </dl>
      </div>

      <!--consent-->
      <div class="consent">
        <p>【本書に記載される情報と本診断で取得する情報の取扱いについて】</p>
        <div class="consent_waku">
          <p>1.　利用目的及び提供：株式会社ビジネスコンサルタント（以下「BCon」といいます。）は、本書に記載される情報及び本診断で取得及び発生する情報（組織及び個人に関する情報を含み、以下総称して「対象情報」といいます。）を以下の目的で利用致します｡<br>
            <br>
            ①お申込みご担当者の確認<br>
            ②本診断に係るお申込み手続き<br>
            ③ご回答者の確認<br>
            ④本診断の実施<br>
            ⑤本診断報告書の作成<br>
            ⑥本診断結果の統計データ(a)<br>
            ⑦本診断結果に基づき、有益と判断するプログラム及びサービスに係る情報の提供(b) <br>
            (a)情報の主体（組織名又は個人）が特定されることのない形式に加工し、統計データの一部として利用致します。<br>
            (b)希望されない旨をお伝えいただければご意向に従います。<br>
            <br>
            2.　利用者の範囲：対象情報につきましては、以下に定める関係者が取扱います。<br>
            　　①本診断の運営担当者　②本診断の分析担当者　③担当営業　④担当コンサルタント<br>
            <br>
            3.　第三者開示・提供及び再委託の有無：対象情報を以下に定める以外の理由で、事前の承諾を得ることなく第三者に開示・提供することはございません。また、以下③以外で本診断に係る業務の全部又は一部を、事前の承諾を得ることなく第三者に再委託することもございません。なお、③に係る再委託先について、BConは一切の責任を負うものとします。<br>
            　　①法令又は命令等により、裁判所、警察、検察その他国家機関若しくはこれらに類する機関等に当該情報の開示について義務的に求められたとき<br>
            　　②生命・身体及び財産等に対する差し迫った危険があり、緊急の必要性があるとき<br>
            　　③本診断に係る印刷及び入力業務<br>
            <br>
            4.　情報提供の任意性：対象情報を含む情報の提供につきましては、任意となりますので、十分な情報をご提供頂けない場合は、お申込み手続き及び本診断の実施に支障をきたす場合がございます。<br>
            <br>
            5.　セキュリティ：BConは、対象情報について、紛失又は不当なアクセス・破壊・使用・修正・開示等が行われないよう合理的な安全保護措置を講じます。詳細につきましては、以下のWebに掲載しておりますプライバシーポリシー（http://www.bcon.jp/privacyp/）をご参照ください。<br>
            <br>
            6.　確認・開示・訂正：対象情報に関する確認・開示・訂正については、お申し出により、合理的な期間及び範囲で対応致します。<br>
            <br>
            7.　個人情報の削除：対象情報のうち、個人情報（特定の個人を識別できる情報）は原則、報告会・研修会等、報告書を使用した翌月末日を保管期限とし、翌々月には削除致します。この保管期限に関しては、診断申込書の該当欄の記載内容に準じます。<br>
            <br>
            8.　情報管理責任部門（問い合わせ窓口）：対象情報の取扱いに関するご質問・ご要望は下記にて承ります｡<br>
            <br>
            情報管理責任部門：株式会社ビジネスコンサルタント ESB本部 診断サービスグループ<br>
            〒100-0005　東京都千代田区丸の内1-7-12 サピアタワー18F<br>
            TEL:03-3287-3416  E-mail: quest@info.bcon.jp</p>

        </div>
      </div>
      <p class="consent_box_p">以上の内容で同意いただける方は「同意する」にチェックを入れてください。</p>
      <p class="consent_box_p_p">
        <input type="checkbox" v-model="agree" name="agree" value="true" id="consent_check"><label for="consent_check" class="check_2_3">同意する</label>
      </p>

      <div id="check_box" style="display: block;">
        <div class="form_box form_box_2_3_2"  v-show="agree">
          <dl>
            <dt>個人情報保管期間</dt>
            <dd>
              <p class="margin_b2">同意される場合は「同意する」にチェックを、期間延長を希望される場合は担当営業もしくは、<br>
                お問い合わせ窓口までお問い合わせください。</p>
              <p>
                <input class="form-check-input" id="consent_check_2" type="checkbox" value="true" v-model="agree_store">
                <label for="consent_check_2" class="check_2_3">個人情報保管期間について同意する</label>
              </p>
            </dd>
          </dl>
        </div>
        <div class="footer_box footer_2_3">
          <p class="btn_1"><input :class="{ 'form_box_sub': true, 'form_box_sub_o': !agree }" type="submit" value="入力内容を確認する"></p>
          <p class="save_btn"><a href="javascript:;" @click="saveWithoutValidate">一時保存</a></p>
        </div>
      </div>
    </ValidationObserver>
    <div v-if="is_preview && project.progress_status == 0">
      <div class="attention">
        <p>入力内容に誤りがないかご確認ください。</p>
      </div>
      <form @submit.prevent="updateProgress()">
        <div class="form_box form_box_1_3">
          <div class="form_box_title">
            <p class="">お客様情報入力</p>
          </div>
          <dl>
            <dt width="25%">組織名(会社名)</dt>
            <dd width="75%">{{ project.client_name }}</dd>
          </dl>
          <dl>
            <dt>所属・役職</dt>
            <dd>{{ user.division }}　{{ user.position }}</dd>
          </dl>
          <dl>
            <dt>氏名</dt>
            <dd>{{ user.name }}</dd>
          </dl>
          <dl>
            <dt>電話番号</dt>
            <dd>
              {{ project.client_tel }}
            </dd>
          </dl>
          <dl>
            <dt>住所</dt>
            <dd>
              <p>〒{{ project.zip_code }}</p>
              <p>{{ project.address1 }}{{ project.address2 }}</p>
            </dd>
          </dl>
          <dl>
            <dt width="25%">実施方法</dt>
            <dd width="75%">
              <label>{{ this.medium_types[project.medium_type] ? this.medium_types[project.medium_type] : '' }}</label>
              <label v-show="(parseInt(project.web_people) || 0) + (parseInt(project.web_people) || 0) > 0">{{
                (parseInt(project.web_people) || 0) + (parseInt(project.web_people) || 0) }}名</label>
            </dd>
          </dl>
          <dl v-if="project.medium_type == 2 || project.medium_type == 3">
            <dt>質問紙の送付先点数</dt>
            <dd>
              {{ paper_send_types[project.paper_send_type] ?  paper_send_types[project.paper_send_type] : ''}}
            </dd>
          </dl>
          <dl v-if="project.paper_send_type == 1 || project.paper_send_type == 2">
            <dt>質問紙の送付先住所①</dt>
            <dd>
              <p>〒{{ project.paper1_zip_code }}</p>
              <p>{{ project.paper1_address1 }}{{ project.paper1_address2 }}</p>
            </dd>
          </dl>
          <dl v-if="project.paper_send_type == 1 || project.paper_send_type == 2">
            <dt>質問紙の送付部数①</dt>
            <dd>{{ project.paper1_copies }}</dd>
          </dl>
          <dl v-if="project.paper_send_type == 2">
            <dt>質問紙の送付先住所②</dt>
            <dd>
              <p>〒{{ project.paper2_zip_code }}</p>
              <p>{{ project.paper2_address1 }}{{ project.paper2_address2 }}</p>
            </dd>
          </dl>
          <dl v-if="project.paper_send_type == 2">
            <dt>質問紙の送付部数②</dt>
            <dd>{{ project.paper2_copies }}</dd>
          </dl>
          <dl>
            <dt class="top">回答スケジュール</dt>
            <dd class="fx">
              <div class="form_box_2_4">
                <p id="margin_left_20" class="form_box_2_4_p form_box_2_4_2_p">
                  <span>診断用書類(必須)締切日　2020年x月x日</span>
                </p>
                <div v-if="project.medium_type == 1" class="form_box_360">
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">Web診断開始日時</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | datetimeFormat }}</p>
                  </div>
                  <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                    <p class="col-140">Web診断終了日時</p>
                    <p class="col margin_l_2_4">{{ project.web_end | datetimeFormat }}</p>
                  </div>
                </div>
                <div v-if="project.medium_type == 2" class="form_box_360">
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙到着希望日</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce">
                    <p>質問紙提出期限(任意)</p>
                    <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙BCon到着日</p>
                    <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                  </div>
                </div>
                <div v-if="project.medium_type == 3" class="form_box_360">
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">Web診断開始日時</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.web_start | datetimeFormat }}</p>
                  </div>
                  <div class="row margin_b_2_4 margin_b_2_3 fx_ce">
                    <p class="col-140">Web診断終了日時</p>
                    <p class="col margin_l_2_4">{{ project.web_end | datetimeFormat }}</p>
                  </div>
                  <div>
                    <hr>
                  </div>
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙到着希望日</p>
                    <p class="col margin_l_2_4 stick_2_4">{{ project.paper_preferred_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce">
                    <p>質問紙提出期限(任意)</p>
                    <p class="margin_l_2_4 stick_2_4">{{ project.paper_limit_date | dateFormat }}</p>
                  </div>
                  <div class="margin_b_2_4 fx_ce row">
                    <p class="col col-140">質問紙BCon到着日</p>
                    <p class="col margin_l_2_4">{{ project.paper_bcon_receipt_date | dateFormat }}</p>
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl>
            <dt>報告書納品希望日</dt>
            <dd class="margin_l_2_4_2">{{ project.report_preferred_date | dateFormat }}</dd>

          </dl>
          <dl class="form_box_1_3_dl">
            <dt>該当する業界・業種・規模</dt>
            <dd>業界・業種：　{{ this.industry_types[project.industry_type] ? this.industry_types[project.industry_type] : '' }}<br>
              規模　　　：　{{ this.scale_types[project.scale_type] ? this.scale_types[project.scale_type] : '' }}
            </dd>
          </dl>

          <dl>
            <dt>備考欄</dt>
            <dd>
              <div v-if="empty(project.memo) && project.memo.length > 0">
                <div v-for="(line, i) in project.memo.split('\n')">
                    {{ line }} <br v-if="i < project.memo.split('\n').length - 1">
                </div>
              </div>
              <div v-else>なし</div>
            </dd>
          </dl>

          <dl>
            <dt>個人情報保管期間</dt>
            <dd>
              {{ agree_store ? '個人情報保管期間について同意する' : ''}}
            </dd>
          </dl>
        </div>
        <div class="footer_box footer_box_1_3">
          <p class="btn2"><input @click="backInput()" class="form_box_sub_blue form_box_sub_2" type="button" value="前の画面に戻る"></p>
          <p class="btn3"><input class="form_box_sub form_box_sub_2" type="submit" value="上記の内容で送信する"></p>
        </div>
      </form>
    </div>

    <div class="top_box_2_5" v-if="project.progress_status == 1">
      <p>ご登録ありがとうございました。<br>
        診断申込みを受け付けました。<br>
        申込受付完了メールを送信しますので内容をご確認ください。</p>
    </div>

    <div class="content_box_2_5 fx_se" v-if="project.progress_status == 1">
      <div class="content_box_child_2_5"  @click="newStep()">
        <img src="images/jizen_img1.png" alt="「事前情報」を入力する" srcset="images/jizen_img1.png 1x, images/jizen_img1@2x.png 2x">
        <p>引き続き<br>「事前情報」を入力する</p>
        <p class="btn-s btn_2_5"><a href="javascript:;" class="form_box_sub form_box_sub_2">事前情報 登録へ進む</a></p>
      </div>
      <div class="content_box_child_2_5"  @click="logout()">
        <img src="images/jizen_img2.png" alt="「事前情報」を入力する" srcset="images/jizen_img2.png 1x, images/jizen_img2@2x.png 2x">
        <p>後で<br>「事前情報」を入力する</p>
        <p class="content_box_child_2_5_p">この画面を終了した後で事前情報を入力する場合<br>は、再度ログインしていただき、「事前情報
          登録」<br>へお進みください。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {
    INDUSTRY_TYPE,
    MEDIUM_TYPE,
    PAPER_SEND_TYPE,
    SCALE_TYPE,
    USER_CONSULTANT,
    USER_SALES
  } from './../../../contstant'
  import {ja} from 'vuejs-datepicker/dist/locale';

  export default {
    data() {
      return {
        industry_types: INDUSTRY_TYPE,
        scale_types: SCALE_TYPE,
        medium_types: MEDIUM_TYPE,
        paper_send_types: PAPER_SEND_TYPE,
        agree: false,
        agree_store: false,
        extend: false,
        value: '',
        formatted: '',
        selected: '',
        logoutTo: 'Login',
        fukidashi2: false,
        is_preview: false,
        configs: {
          web_start: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD HH:mm',
            locale: 'ja',
            useCurrent: false,
            inline: false
          },
          date: {
            allowInputToggle: true,
            format: 'YYYY-MM-DD',
            locale: 'ja',
            useCurrent: false,
            inline: false
          }
        }
      }
    },
    props: {
      preview: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      preview() {
        this.is_preview = this.preview
      }
    },
    computed: {
      ...mapState('consultantUser', ['user', 'project']),
    },
    methods: {
      empty(value) {
        return !_.isEmpty(value)
      },
      async saveProject() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({key, value}))
              .filter(error => error["value"].length);

            console.log(errors[0]["key"])
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return
          // ABORT!!
        }

        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project, 'user': this.user }).then(() => {
          this.is_preview = true
          this.$emit('hidden',  true)
          this.$wait.end('loading')
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      saveWithoutValidate() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project, 'user': this.user }).then(() => {
          // this.is_preview = true
          this.$emit('hidden',  true)
          this.$wait.end('loading')
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      updateProgress() {
        // this.$store.dispatch('project/NEW_STEP', false)
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': {progress_status: 1}}).then(() => {
          console.log(this.project)
          this.$wait.end('loading')
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      searchAddress() {
        this.$store.dispatch('project/SEARCH_ADDRESS', this.project.zip_code)
      },
      searchAddressPaper() {
        this.$store.dispatch('project/SEARCH_ADDRESS_PAPER', this.project.paper1_zip_code)
      },
      searchAddressPaper2() {
        this.$store.dispatch('project/SEARCH_ADDRESS_PAPER2', this.project.paper2_zip_code)
      },
      checkExtend() {
        if (!this.extend) {
          this.project.strage_expiration = ''
        }
      },
      onContext(ctx) {
        // The date formatted in the locale, or the `label-no-date-selected` string
        this.formatted = ctx.selectedFormatted
        // The following will be an empty string until a valid date is entered
        this.selected = ctx.selectedYMD
      },
      changeMedium() {
        if (this.project.medium_type == 1) {
          this.project.paper_send_type = null
        }
        this.$forceUpdate()
      },
      logout() {
        if (this.user.type === USER_CONSULTANT) {
          this.logoutTo = 'LoginConsultant'
        } else if (this.user.type === USER_SALES) {
          this.logoutTo = 'LoginSales'
        }
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      newStep() {
        this.$store.dispatch('project/NEW_STEP', 1)
        this.$emit('hidden',  false)
      },
      mouseOver() {
        this.fukidashi2 = true
      },
      mouseLeave() {
        this.fukidashi2 = false
      },
      sliced(from, to, data) {
        const sliced = Object.keys(data).slice(from, to).reduce((result, key) => {
          result[key] = data[key];

          return result;
        }, {});
        return sliced
      },
      backInput() {
        this.is_preview = false
        this.$emit('hidden',  false)
      }
    },
    created() {
    }
  }
</script>

<style lang="scss" scoped>
  .error {
    &.left-150 {
      margin-left: 150px;
    }
  }
  .width-datepicker{
    width: 300px;
  }
</style>