import Cookies from 'js-cookie'
import {USER_PROJECT} from '../../contstant';
import axios from "axios";

export default {
  namespaced: true,
  state: {
    token: Cookies.get('user-token') || '',
    status: '',
    user: {},
    remember: Cookies.get('rememberMe'),
    project: {}
  },
  getters: {
    isAuthenticated: state => !!state.token,
    user: state => state.user,
    remember: state => state.remember,
  },
  mutations: {
    AUTH_REQUEST(state) {
      state.status = 'loading'
    },
    AUTH_SUCCESS(state, token) {
      state.status = 'success'
      state.token = token
    },
    AUTH_ERROR(state) {
      state.status = 'error'
    },
    AUTH_USER(state, user) {
      state.user = user
      state.project = user.project ? user.project : {}
    },
    SET_PROJECT(state, project) {
      state.project = project
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_PROJECT_PROGRESS_STATUS(state, progress_status) {
      state.project.progress_status = progress_status
    }
  },
  actions: {
    AUTH_REQUEST({commit, dispatch}, user) {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        axios({url: '/api/admin/login', data: user, method: 'POST'})
          .then(resp => {
            let token = resp.data.token
            const user = resp.data.user
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            Cookies.set('user-token', token)
            commit('AUTH_SUCCESS', token)
            commit('AUTH_USER', user)
            resolve(resp)
          })
          .catch(err => {
            commit('AUTH_ERROR', err)
            Cookies.remove('user-token') // if the request fails, remove any possible user token if possible
            reject(err)
          })
      })
    },
    AUTH_LOGOUT({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        Cookies.remove('user-token')
        Cookies.remove('rememberMe')
        commit('AUTH_USER', {})
        commit('AUTH_SUCCESS', '')
        // remove the axios default header
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    async AUTH_TOKEN({commit, dispatch, getters}) {
      try {
        let {data} = await axios.get('/api/users/info')
        let user = data
        if (user.type === USER_PROJECT && getters.remember !== 'true') {
          Cookies.remove('user-token')
          Cookies.remove('rememberMe')
          commit('AUTH_USER', {})
        } else {
          commit('AUTH_USER', user)
          if (user.project && user.project.progress_status) {
            commit('project/NEW_STEP', user.project.progress_status, {root: true})
          }
        }
        return user
      } catch (e) {
        dispatch('AUTH_LOGOUT')
        commit('AUTH_SUCCESS', '')
        return e
      }
    }
  }
}
