export const USER_CONSULTANT = 1;
export const USER_SALES = 2;
export const USER_PROJECT = 3;
export const PROJECT_TYPE = {
  0: {
    1: "セールスパーソン(本人のみ)",
    21: "セールスパーソン(多面)",
    2: "リーダーシップにおける7つの役割診断(本人のみ)",
    22: "リーダーシップにおける7つの役割診断(多面)",
    3: "キャリア・ポテンシャル診断",
    4: "パーソナルサーベイ(本人のみ)",
    24: "パーソナルサーベイ(多面)",
    5: "QI",
    6: "360度診断"
  },
  1: {
    11: "組織効果性サーベイ",
    12: "組織健康度診断",
    13: "組織活性度調査",
    14: "コンプライアンス診断",
    15: "営業診断",
    16: "労働組合診断"
  }
}
export const PROJECT_STATUS = {
  1: "案件登録済み",
  2: "申込済",
  3: "リスト登録済",
  4: "診断中",
  5: "完了"
}
export const  INDUSTRY_TYPE = {
  "A": "A　_建設不動産業界",
  "B": "B　_製造業界",
  "B01": "食品製造業",
  "B02": "印刷業",
  "B03": "化学工業",
  "B04": "窯業・土石製品製造",
  "B05": "鉄鋼・非鉄金属製造",
  "B06": "金属製品製造業",
  "B07": "一般機器製造業",
  "B08": "電気機器製造業",
  "B09": "輸送機器製造業",
  "B10": "精密・医療機器製造業",
  "B11": "その他機器製造業",
  "C": "卸売・小売業界",
  "C01": "医薬品・医療用品卸売業",
  "C02": "卸売業、商社",
  "C03": "各種商品小売業",
  "C04": "自動車小売業",
  "D": "運輸業界",
  "E": "サービス業界",
  "E01": "専門・技術サービス業",
  "E02": "宿泊業、飲食サービス業",
  "E03": "生活関連サービス業、娯楽業",
  "F": "IT業界",
  "F01": "通信業、放送業",
  "F02": "システム開発、情報処理業",
  "G": "JA",
  "H": "社会福祉法人",
  "I": "労働組合",
  "J": "医療法人",
  "K": "自治体・官公庁",
  "L": "金融業界",
  "Z": "その他"
}
export const SCALE_TYPE = {
  1: "1～50名",
  2: "51～100名",
  3: "101～300名",
  4: "301~500名",
  5: "501~1,000名",
  6: "1,001～3,000名",
  7: "3,001～5,000名",
  8: "5,000名以上"
}

export const MEDIUM_TYPE = {
  1: 'Web',
  2: '質問紙',
  3: 'Web + 質問紙'
}

export const SELECT_KUBUN_TYPE = {
  1: 'BConがメール送信',
  2: '担当者が選択'
}

export const SETTING_TYPE = {
  1: '事務局(貴社)が設定',
  2: 'お客様が実施'
}

export const COMPARE_TYPE = {
  1: '有り',
  2: '無し'
}

export const PAPER_SEND_TYPE = {
  1: '1ヶ箇所',
  2: '2ヶ箇所',
  3: '3ヶ箇所'
}

export const ATTRIBUTE_SETTING_TYPE = {
  1: '回答時に選択（推奨）',
  2: 'リスト提出時に設定'
}

export const GUIDENCE_EMAIL_TYPE = {
  1: '回答時',
  2: 'リスト提出時'
}

export const QUESTION_FORMAT_TYPE = {
  1: '尺度(7点法)'
}