<template>
  <div class="body_1_2">
    <header-component :title="'[営業部門用 案件 登録フォーム]'"></header-component>
    <!--<ValidationObserver v-slot="{ handleSubmit }">-->
    <ValidationObserver ref="observer" v-show="!is_preview && !is_preview_email" v-slot="{ invalid }" tag="form" @submit.prevent="previewRegister()">
      <!--<form @submit.prevent="handleSubmit(previewRegister)" v-show="!is_preview && !is_preview_email">-->
        <div class="form_box">
          <div class="form_box_title">
            <p>お客様情報</p>
          </div>
          <dl class="">
            <dt class="required required1">組織名</dt>
            <dd class="">
              <ValidationProvider name="client_name" rules="required" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" v-model="user.client_name"
                       placeholder="例：株式会社○○○"
                       name="client_name">
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl>
            <dt>担当者所属・役職</dt>
            <dd class="fx_ce">
              <p class="position">所属<input class="form_text" type="text" name="division" v-model="user.division"
                                           placeholder="例：○○○部"></p>
              <p class="position">役職<input class="form_text" type="text" name="position" v-model="user.position"
                                           placeholder="例：部長">
              </p>
            </dd>
          </dl>
          <dl>
            <dt class="required required2">担当者　氏名</dt>
            <dd>
              <ValidationProvider name="name1" rules="required" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" name="name1" placeholder="例：田中一郎"
                       v-model="user.name1">
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl>
            <dt class="required required3 height_up1">担当者　メールアドレス</dt>
            <dd>
              <ValidationProvider name="email1" rules="required|email" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text form_text_2': true, 'error': errors[0] }" type="text" name="email1"
                       v-model="user.email1"
                       placeholder="例：Ichiro.t@cliant.co.jp">
                <br><span>※英数字半角入力</span>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl>
            <dt class="height_up1">担当者2人目　氏名</dt>
            <dd><span>案内先を2名設定する場合は設定してください。</span><br>
              <ValidationProvider :rules="user.email2 !== '' ? 'required' : ''" name="name2" class="validate"
                                  v-slot="{ errors }">
                <input :class="{ 'form_text': true, 'error': errors[0] }" type="text" name="name2" placeholder="例：佐藤花子"
                       v-model="user.name2">
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl>
            <dt class="height_up1">担当者2人目　メールアドレス</dt>
            <dd>
              <ValidationProvider
                :rules="('same:'+ user.email1) + '|' + (user.name2 !== '' ? 'required' : '') + '|email'"
                name="email2"
                v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text form_text_2': true, 'error': errors[0] }" type="text" name="email2" title="半角英数字で入力して下さい。"
                       placeholder="例：Hanako.S@cliant.co.jp" v-model="user.email2">
                <br><span>※英数字半角入力</span>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
        </div>

        <div class="form_box">
          <div class="form_box_title">
            <p>社内連絡情報</p>
          </div>
          <dl>
            <dt class="required required4">担当営業名</dt>
            <dd>
              <ValidationProvider name="staff_name" rules="required" v-slot="{ errors }" class="validate">
                <input type="text" :class="{ 'form_text': true, 'error': errors[0] }" v-model="user.staff_name"
                       name="staff_name" placeholder="例：伊藤孝史">
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl>
            <dt class="height_up1">共有先メールアドレス&#9312;</dt>
            <dd>
              <ValidationProvider :rules="{ regex: /^[0-9A-Za-z]+$/ }" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text mail_form form_text_2': true, 'error': errors[0] }" type="text"
                       v-model="user.staff_email1" name="staff_email1"
                       title="半角英数字で入力して下さい。" placeholder="例：Atsushi.i"><span
                class="middle">@bcon.co.jp</span>
                <br><span>※英数字半角入力</span>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl>
            <dt class="height_up1">共有先メールアドレス&#9313;</dt>
            <dd>
              <ValidationProvider :rules="{ regex: /^[0-9A-Za-z]+$/ }" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text mail_form form_text_2': true, 'error': errors[0] }" type="text"
                       v-model="user.staff_email2" name="staff_email2"
                       title="半角英数字で入力して下さい。" placeholder="例：Yumiko.H"><span
                class="middle">@bcon.co.jp</span><br><span>※英数字半角入力</span>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
          </dl>
          <dl class="radio">
            <dt class="required required5 top">診断種類の選択</dt>
            <dd>
              <ValidationProvider name="is_multisite_diagnosis" rules="required" v-slot="{ errors }" class="validate">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="is_multisite_diagnosis"
                       v-model="user.is_multisite_diagnosis" id="is_multisite_diagnosis0" value="0">
                <label for="is_multisite_diagnosis0">個人診断</label>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="is_multisite_diagnosis"
                       v-model="user.is_multisite_diagnosis" id="is_multisite_diagnosis1" value="1">
                <label for="is_multisite_diagnosis1">組織診断</label>
                <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
              <br>
              <p class="radio_p">以下より選択してください。</p>
              <div v-if="project_types" id="person">
                <ValidationProvider name="project_type" rules="required" v-slot="{ errors }" class="validate">
                  <div v-for="(type, index) in project_types" :key="index">
                    <p class="label_p">
                      <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="project_type"
                             v-model="user.project_type" :id="user.project_type + index" :value="index">
                      <label :for="user.project_type + index">{{ type
                        }}</label>
                    </p>
                  </div>
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </dd>
          </dl>
          <dl class="fx_normal">
            <dt class="top">備考欄（診断SGへの連絡用）</dt>
            <dd><textarea name="memo" v-model="user.memo" rows="7" cols="40"
                          placeholder="診断チームに申し伝えることがあれば入力してください。"></textarea></dd>
          </dl>
          <div v-show="user.is_multisite_diagnosis == 1">
            <dl class="radio_2">
              <dt class="required required6">経年比較の有無</dt>
              <dd>
                <ValidationProvider name="is_comparison_age" rules="required" v-slot="{ errors }">
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="is_comparison_age"
                         v-model="user.is_comparison_age" value="true" id="is_comparison_age1">
                  <label for="is_comparison_age1">有り</label>
                  <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="is_comparison_age"
                         v-model="user.is_comparison_age" value="false" id="is_comparison_age2">
                  <label for="is_comparison_age2">無し</label>
                  <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
            </dl>
          </div>
        </div>

        <div class="footer_box">
          <p class="btn_1"><input class="form_box_sub" type="submit" value="入力内容を確認する"></p>
        </div>
      <!--</form>-->
    </ValidationObserver>
    <div v-show="is_preview && !is_preview_email">
      <div class="attention">
        <p>入力内容に誤りがないかご確認ください。</p>
      </div>
      <form @submit.prevent="comfirmRegister()">
        <div class="form_box form_box_1_3">
          <div class="form_box_title">
            <p>お客様情報</p>
          </div>
          <dl>
            <dt>組織名</dt>
            <dd>{{ user.client_name }}</dd>
          </dl>
          <dl>
            <dt>担当者所属・役職</dt>
            <dd>{{ user.division }}　{{ user.position }}</dd>
          </dl>
          <dl>
            <dt>担当者　氏名</dt>
            <dd>{{ user.name1 }}</dd>
          </dl>
          <dl>
            <dt>担当者　メールアドレス</dt>
            <dd>{{ user.email1 }}</dd>
          </dl>
          <dl>
            <dt>担当者2人目　氏名</dt>
            <dd>{{ user.name2 }}</dd>
          </dl>
          <dl>
            <dt>担当者2人目　メールアドレス</dt>
            <dd>{{ user.email2 }}</dd>
          </dl>
        </div>

        <div class="form_box form_box_1_3">
          <div class="form_box_title">
            <p>社内連絡情報</p>
          </div>
          <dl>
            <dt>担当営業名</dt>
            <dd>{{ user.staff_name }}</dd>
          </dl>
          <dl>
            <dt>共有先メールアドレス&#9312;</dt>
            <dd>{{ user.staff_email1 ? user.staff_email1 + '@bcon.co.jp' : '' }}</dd>
          </dl>
          <dl>
            <dt>共有先メールアドレス&#9313;</dt>
            <dd>{{ user.staff_email2 ? user.staff_email2 + '@bcon.co.jp' : '' }}</dd>
          </dl>
          <dl class="form_box_1_3_dl">
            <dt>診断種別選択</dt>
            <dd>＜{{ user.is_multisite_diagnosis === 0 ? '個人診断' : '組織診断' }}＞<br>
              {{ projectType() }}
            </dd>
          </dl>
          <dl>
            <dt>備考欄（社内連絡用）</dt>
            <dd>
              <div v-for="line in user.memo.split('\n')" :key="line">{{ line }}</div>
            </dd>
          </dl>
          <dl>
            <dt>経年比較</dt>
            <dd>{{ user.is_comparison_age ? '有り' : '無し' }}</dd>
          </dl>
        </div>
        <div class="footer_box footer_box_1_3">
          <p class="back_btn"><a href="javascript:;" @click="backRegister">前の画面へ戻る</a></p>
          <p class="btn"><input class="form_box_sub form_box_sub_2" type="submit" value="メールをプレビュー"></p>
        </div>
      </form>
    </div>

    <div v-show="is_preview_email && !is_success">
      <form @submit.prevent="saveClient()">
      <div class="attention">
        <p>お客様へ以下のメールが送信されます。内容をご確認ください。</p>
      </div>
      <div class="form_box form_box_1_4">
        <p>{{ user.client_name }}<br>{{ user.division }}　{{ user.position }}<br>{{ user.name1 }}</p>
        <p>平素より大変お世話になっております。株式会社ビジネスコンサルタント □□でございます。</p>
        <p>この度、ご依頼いただきました「組織効果性サーべイ」のお申込みフォームのご案内です。<br>
          以下のURLよりログイン画面にアクセスしていただき、以下のID、パスワードでログインしてお申込みフォームへお進みください。
        </p>
        <p>ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー<br>
          お申込みフォーム ログイン画面<br><span>{{ base_url() }}</span><br>【ログインID】{{ user.id_login }}<br>【パスワード】{{
          user.password }}<br>
          ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー</p>
        <p>メールの内容についてのお問い合わせは、下記の連絡先までメール、または電話でお願いします。</p>
        <p>ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー<br>
          BConアンケート事務局<br>電話　03-3287-3416(月～金 10:00～17:00)<br>メールアドレス <span>quest@info.bcon.jp</span><br>
          ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー</p>
      </div>
      <div class="footer_box footer_box_1_3">
        <p class="btn2"><input class="form_box_sub_blue form_box_sub_2" @click="backPreview()" value="前の画面に戻る" type="button"></p>
        <p class="btn3"><input class="form_box_sub form_box_sub_2" type="submit" value="この内容で送信する"></p>
      </div>
      </form>
    </div>
    <div class="login_form_box form_box_1_5" v-show="is_success">
      <p>登録を受け付けました。</p>
      <p>お客様にメールでクライアント用ログイン情報を送信しましたので</p>
      <p>アフターフォローをお願いします。</p>
    </div>
  </div>
</template>
<script>
  import {PROJECT_TYPE} from '../../../contstant'

  export default {
    data() {
      return {
        is_preview: false,
        is_preview_email: false,
        is_success: false,
        user: {
          client_name: '',
          name1: '',
          email1: '',
          email2: '',
          division: '',
          position: '',
          name2: '',
          staff_name: '',
          staff_email1: '',
          staff_email2: '',
          project_type: '',
          is_comparison_age: true,
          is_multisite_diagnosis: 0,
          memo: '',
          id_login: '',
          password: ''
        },
        project_types: [],
      }
    },
    watch: {
      "user.is_multisite_diagnosis": function (v) {
        this.project_types = PROJECT_TYPE[this.user.is_multisite_diagnosis]
        this.project_type = null
      }
    },
    methods: {
      base_url() {
        return location.origin
      },
      async previewRegister() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return
          // ABORT!!
        }
        this.$store.dispatch('TEMP_CLIENT', this.user)
        this.is_preview = true
      },
      backRegister() {
        this.is_preview = false
      },
      comfirmRegister() {
        this.is_preview_email = true
        this.user.id_login = this.loginID()
        this.user.password = this.rPass()
      },
      backPreview() {
        this.is_preview_email = false
      },
      loginID() {
        return Math.random().toString(36).substr(2, 9);
      },
      rPass() {
        return Array(6).fill("0123456789").map(function (x) {
          return x[Math.floor(Math.random() * x.length)]
        }).join('');
      },
      saveClient() {
        if (this.user.staff_email1) {
          this.user.staff_email1 = this.user.staff_email1 + '@bcon.co.jp'
        }

        if (this.user.staff_email2) {
          this.user.staff_email2 = this.user.staff_email2 + '@bcon.co.jp'
        }

        this.$store.dispatch('TEMP_CLIENT', {})
        this.$wait.start('loading')
        this.$store.dispatch('CREATE_PROJECT_USER', this.user).then(() => {
          this.$wait.end('loading')
          this.is_success = true
        }).catch(err => {
          // this.$wait.end('loading')
        })
      },
      projectType() {
        return PROJECT_TYPE[this.user.is_multisite_diagnosis][this.user.project_type]
      }
    },
    created() {
      const temp = this.$store.getters.temp_user
      Object.assign(this.user, temp);
    },
    mounted() {
      // this.$wait.start('loading')
      this.project_types = PROJECT_TYPE[this.user.is_multisite_diagnosis]
    }
  }
</script>
<style scoped lang="scss">
</style>

