<template>
  <div id="login">
    <div class="login_box login_box_2_1">
		<img src="images/2_1_logo.png" srcset="images/2_1_logo.png 1x, images/2_1_logo@2x.png 2x" alt="BCon">
		<p>メールに記載されているログインIDとパスワードを入力してログインしてください。</p>
	</div>
	<div class="fx_bw">
		<div class="login_form_box login_form_box_2_1">
			<div class="attention" v-show="loginMessage">
				<p>{{ loginMessage }}</p>
			</div>
			<ValidationObserver v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(login)">
					<ValidationProvider name="id_login" rules="required" v-slot="{ errors }">
						<p>ログインID<input :class="{ 'login_form_id': true, 'login_form_id_2_1': true, 'error': errors[0] }" type="text" name="id_login" v-model="id_login"></p>
						<span class="error" v-show="errors[0]">{{ errors[0] }}</span>
					</ValidationProvider>
					
					<ValidationProvider name="password" rules="required" v-slot="{ errors }">
						<p>パスワード<input :class="{ 'login_form_pass': true, 'login_form_id_2_1': true, 'error': errors[0] }" type="password" name="password" v-model="password"></p>
						<span class="error" v-show="errors[0]">{{ errors[0] }}</span>
					</ValidationProvider>
					
					<p class="checkbox_p">
						<label class="checkbox_text"><input type="checkbox" v-model="rememberMe">ログイン情報を保存する</label>
					</p>
					<p class="submit_p">
						<input class="login_form_sub" type="submit" value="ログイン">
					</p>
				</form>
			</ValidationObserver>
		</div>
		<div class="1mg_box_2_1">
			<img src="images/2_1_back.png" srcset="images/2_1_back.png 1x, images/2_1_back@2x.png 2x" alt="BCon">
		</div>
	</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        id_login: null,
        password: null,
        loginMessage: null,
        rememberMe: false
      };
    },
    created() {

    },
    watch: {
			id_login() {
				this.loginMessage = null
			},
			password() {
				this.loginMessage = null
			}
		},
    methods: {
      login() {
        const {id_login, password, rememberMe} = this
        this.$store.dispatch('AUTH_REQUEST_PROJECT', {id_login, password, rememberMe}).then((res) => {
					let user = res.data.user
					if (user.project && user.project.progress_status == 3) {
						this.$router.push({
							name: 'apply-completed'
						})
					} else {
						this.$router.push({
							name: 'apply'
						})
					}

        }).catch((e) => {
          this.loginMessage = e.response.data.message
        })
      }
    }
  };
</script>
<style scoped lang="scss">
  input[type=checkbox] {
    display: inline-block;
  }
  .login-form {
    width: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0 auto;

    > span {
      width: 100%;
    }
  }

  .login-form form {
    width: 100%;
    margin-bottom: 15px;
  }

  .login-form h2 {
    margin: 0 0 15px;
  }

  .form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
  }

  .btn {
    font-size: 15px;
    font-weight: bold;
  }

	.attention {
		width: 350px;
	}
</style>


