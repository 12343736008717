<template>
  <div class="body">
    <v-wait for="loading">
      <template slot="waiting">
        <div>
          <div class="wrap-loading"></div>
          <div class="v-spinner">
            <div class="loadingio-spinner-rolling-2n8ectvu1hk"><div class="ldio-gf8wf4cp9ou">
              <div></div>
            </div></div>
          </div>
        </div>
      </template>
    </v-wait>
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    data: function () {
      return {}
    },
    created() {

    },
    mounted() {
      // this.$wait.start('loading')
    },
    methods: {},
    beforeCreate() {
      // this.$store.dispatch('AUTH_TOKEN')
    },
    beforeRouteEnter(to, from, next) {
      // console.log('****')
    },
    // when route changes and this component is already rendered,
    // the logic will be slightly different.
    beforeRouteUpdate(to, from, next) {
      // console.log('&&&&')
    },
  }
</script>
