var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body_1_2" },
    [
      _c("ValidationObserver", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              !_vm.is_preview &&
              _vm.project &&
              _vm.project.progress_status == 1,
            expression: "!is_preview && project && project.progress_status == 1"
          }
        ],
        ref: "observer",
        attrs: { tag: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.second()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c(
                  "div",
                  { staticClass: "form_box form_box_2_3 form_box_3_1" },
                  [
                    _c("div", { staticClass: "form_box_title" }, [
                      _c("p", [_vm._v("事前情報")])
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c("dt", { staticClass: "form_dt_3_1_1 height_23" }, [
                        _vm._v("組織名")
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("span", [
                          _vm._v(
                            "ご指定がない場合は、「株式会社○○○○」となります。"
                          )
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.org_name,
                              expression: "project.org_name"
                            }
                          ],
                          staticClass: "form_text tel_number",
                          attrs: {
                            name: "org_name",
                            type: "text",
                            placeholder: "例：○○グループ"
                          },
                          domProps: { value: _vm.project.org_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.project,
                                "org_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c("dt", { staticClass: "form_dt_3_1_2 height_24" }, [
                        _vm._v("診断名")
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("span", [
                          _vm._v(
                            "ご指定がない場合は、「組織診断サーベイ」となります。"
                          )
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.diagnosis_name,
                              expression: "project.diagnosis_name"
                            }
                          ],
                          staticClass: "form_text tel_number",
                          attrs: {
                            type: "text",
                            name: "diagnosis_name",
                            placeholder: "例：○○○研修　事前WEB診断"
                          },
                          domProps: { value: _vm.project.diagnosis_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.project,
                                "diagnosis_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c(
                        "dt",
                        { staticClass: "required required12 form_dt_3_1_3" },
                        [
                          _vm._v("属性情報の設定"),
                          _c("img", {
                            staticClass: "info",
                            attrs: {
                              src: "images/info.png",
                              srcset:
                                "images/info.png" +
                                " 1x, " +
                                "images/info@2x.png" +
                                " 2x",
                              alt: "インフォメーション"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "info_fukidashi_4" }, [
                            _vm._v(
                              "属性情報とは：回答者の役職・所属などのことです。"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("dd", [
                        _c(
                          "p",
                          { staticClass: "margin_dd" },
                          [
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                name: "attribute_setting_type",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project
                                                  .attribute_setting_type,
                                              expression:
                                                "project.attribute_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            id: "attribute_setting_type1",
                                            type: "radio",
                                            value: "1",
                                            name: "attribute_setting_type"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project
                                                .attribute_setting_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "attribute_setting_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "attribute_setting_type1"
                                            }
                                          },
                                          [_vm._v("回答時に選択（推奨）")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project
                                                  .attribute_setting_type,
                                              expression:
                                                "project.attribute_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            id: "attribute_setting_type2",
                                            type: "radio",
                                            value: "2",
                                            name: "attribute_setting_type"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project
                                                .attribute_setting_type,
                                              "2"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "attribute_setting_type",
                                                "2"
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "attribute_setting_type2"
                                            }
                                          },
                                          [_vm._v("リスト提出時に設定")]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c(
                        "dt",
                        {
                          staticClass:
                            "required required13 height_up_2_3 height_up_3_1_2"
                        },
                        [_vm._v("ID・パスワード設定")]
                      ),
                      _vm._v(" "),
                      _c(
                        "dd",
                        [
                          _c("ValidationProvider", {
                            staticClass:
                              "validate margin_dd fx_st btn_form_3_1",
                            attrs: {
                              name: "id_setting_type",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("p", [_vm._v("ID")]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "id_btn1" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project.id_setting_type,
                                              expression:
                                                "project.id_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "id_setting_type",
                                            value: "1",
                                            id: "id_setting_type1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project.id_setting_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "id_setting_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "id_setting_type1" }
                                          },
                                          [_vm._v("事務局(貴社)が設定")]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project.id_setting_type,
                                              expression:
                                                "project.id_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "id_setting_type",
                                            value: "2",
                                            id: "id_setting_type2"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project.id_setting_type,
                                              "2"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "id_setting_type",
                                                "2"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "id_setting_type2" }
                                          },
                                          [
                                            _vm._v(
                                              "ビジネスコンサルタントが設定"
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            staticClass:
                              "validate margin_dd fx_st btn_form_3_1 btn_form_3_1_2",
                            attrs: {
                              name: "pass_setting_type",
                              rules: "required"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("p", [_vm._v("パスワード")]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "id_btn2" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project.pass_setting_type,
                                              expression:
                                                "project.pass_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "pass_setting_type",
                                            value: "1",
                                            id: "pass_setting_type1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project.pass_setting_type,
                                              "1"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "pass_setting_type",
                                                "1"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "pass_setting_type1" }
                                          },
                                          [_vm._v("事務局(貴社)が設定")]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.project.pass_setting_type,
                                              expression:
                                                "project.pass_setting_type"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "pass_setting_type",
                                            value: "2",
                                            id: "pass_setting_type2"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.project.pass_setting_type,
                                              "2"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.project,
                                                "pass_setting_type",
                                                "2"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "pass_setting_type2" }
                                          },
                                          [
                                            _vm._v(
                                              "ビジネスコンサルタントが設定"
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c(
                        "dt",
                        {
                          staticClass:
                            "required required40 form_box_2_3_dt height_up_3_1"
                        },
                        [_vm._v("貴社お問合せ先")]
                      ),
                      _vm._v(" "),
                      _c(
                        "dd",
                        { staticClass: "margin_dd" },
                        [
                          _c("p", { staticClass: "form_contact_p" }, [
                            _vm._v(
                              "Web画面・質問紙に記載される貴社お問い合わせ先です。"
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fx_st" }, [
                            _c("p", [_vm._v("部署・役職")]),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.project.inquiry_division,
                                    expression: "project.inquiry_division"
                                  }
                                ],
                                staticClass: "form_text form_sch",
                                attrs: {
                                  type: "text",
                                  name: "inquiry_division",
                                  placeholder: "◯◯部　部長"
                                },
                                domProps: {
                                  value: _vm.project.inquiry_division
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.project,
                                      "inquiry_division",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fx_st" }, [
                            _c("p", [_vm._v("氏名")]),
                            _vm._v(" "),
                            _c("p", { staticClass: "margin_dd2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.project.inquiry_name,
                                    expression: "project.inquiry_name"
                                  }
                                ],
                                staticClass: "form_text form_sch",
                                attrs: {
                                  type: "text",
                                  name: "inquiry_name",
                                  placeholder: "田中　一郎"
                                },
                                domProps: { value: _vm.project.inquiry_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.project,
                                      "inquiry_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fx_st" }, [
                            _c("p", { staticClass: "sch_1" }, [
                              _vm._v("電話番号")
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "margin_dd3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.project.inquiry_tel,
                                    expression: "project.inquiry_tel"
                                  }
                                ],
                                staticClass: "form_text form_sch",
                                attrs: {
                                  type: "text",
                                  name: "inquiry_tel",
                                  placeholder: "03xxxxxxxx"
                                },
                                domProps: { value: _vm.project.inquiry_tel },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.project,
                                      "inquiry_tel",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            staticClass: "validate",
                            attrs: {
                              name: "comparison_industry_type",
                              rules: "email"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c("div", { staticClass: "fx_st" }, [
                                        _c("p", { staticClass: "sch_1" }, [
                                          _vm._v("メール")
                                        ]),
                                        _vm._v(" "),
                                        _c("p", { staticClass: "margin_dd4" }, [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.project.inquiry_email,
                                                expression:
                                                  "project.inquiry_email"
                                              }
                                            ],
                                            class: {
                                              "form_text form_sch": true,
                                              error: errors[0]
                                            },
                                            attrs: {
                                              type: "text",
                                              name: "inquiry_email",
                                              placeholder:
                                                "Ichiro.t@cliant.co.jp"
                                            },
                                            domProps: {
                                              value: _vm.project.inquiry_email
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.project,
                                                  "inquiry_email",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ],
                                          staticClass: "error left-95"
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("dl", [
                      _c("dt", { staticClass: "form_dt_3_1_5" }, [
                        _vm._v("質問紙提出先"),
                        _c("img", {
                          staticClass: "info",
                          attrs: {
                            src: "images/info.png",
                            srcset:
                              "images/info.png" +
                              " 1x, " +
                              "images/info@2x.png" +
                              " 2x",
                            alt: "インフォメーション"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "info_fukidashi_10" }, [
                          _vm._v("質問紙に記載されます。（下記例）"),
                          _c("br"),
                          _vm._v(" "),
                          _c("img", {
                            attrs: {
                              src: "images/sample02.png",
                              alt: "サンプル"
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.paper_recipient,
                              expression: "project.paper_recipient"
                            }
                          ],
                          staticClass: "form_text tel",
                          attrs: {
                            type: "text",
                            name: "paper_recipient",
                            placeholder: "例：●●部署 ▲▲さん"
                          },
                          domProps: { value: _vm.project.paper_recipient },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.project,
                                "paper_recipient",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", { staticClass: "radio_2" }, [
                      _c("dt", { staticClass: "form_dt_3_1_4" }, [
                        _vm._v("業種別比較")
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.comparison_industry_type,
                              expression: "project.comparison_industry_type"
                            }
                          ],
                          class: { form_radio: true },
                          attrs: {
                            type: "radio",
                            name: "comparison_industry_type",
                            value: "1",
                            id: "comparison_industry_type1"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.project.comparison_industry_type,
                              "1"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.project,
                                "comparison_industry_type",
                                "1"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "comparison_industry_type1" } },
                          [_vm._v("有り")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.comparison_industry_type,
                              expression: "project.comparison_industry_type"
                            }
                          ],
                          class: { form_radio: true },
                          attrs: {
                            type: "radio",
                            name: "comparison_industry_type",
                            value: "0",
                            id: "comparison_industry_type2"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.project.comparison_industry_type,
                              "0"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.project,
                                "comparison_industry_type",
                                "0"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "comparison_industry_type2" } },
                          [_vm._v("無し")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", { staticClass: "radio_2" }, [
                      _c("dt", { staticClass: "form_dt_3_1_4" }, [
                        _vm._v("回答者への案内メール")
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.guidance_email_type,
                              expression: "project.guidance_email_type"
                            }
                          ],
                          staticClass: "form_radio",
                          attrs: {
                            id: "guidance_email_type_1",
                            type: "radio",
                            value: "1",
                            name: "guidance_email_type"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.project.guidance_email_type,
                              "1"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.project,
                                "guidance_email_type",
                                "1"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "guidance_email_type_1" } },
                          [_vm._v("BConがメール送信")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.project.guidance_email_type,
                              expression: "project.guidance_email_type"
                            }
                          ],
                          staticClass: "form_radio",
                          attrs: {
                            id: "guidance_email_type_2",
                            type: "radio",
                            value: "2",
                            name: "guidance_email_type"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.project.guidance_email_type,
                              "2"
                            )
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(
                                _vm.project,
                                "guidance_email_type",
                                "2"
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "guidance_email_type_2" } },
                          [_vm._v("お客様が実施")]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("dl", { staticClass: "radio_2" }, [
                      _c("dt", { staticClass: "form_dt_3_1_4" }, [
                        _vm._v("質問文の主語変更")
                      ]),
                      _vm._v(" "),
                      _c("dd", [
                        _c(
                          "div",
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.question_subject_type_other,
                                  expression: "question_subject_type_other"
                                }
                              ],
                              staticClass: "form_radio",
                              attrs: {
                                id: "radio_3_1_3",
                                type: "radio",
                                value: "当社",
                                name: "question_subject_type"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.question_subject_type_other,
                                  "当社"
                                )
                              },
                              on: {
                                change: [
                                  function($event) {
                                    _vm.question_subject_type_other = "当社"
                                  },
                                  function($event) {
                                    return _vm.subjectType()
                                  }
                                ]
                              }
                            }),
                            _c("label", { attrs: { for: "radio_3_1_3" } }, [
                              _vm._v("当社")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.question_subject_type_other,
                                  expression: "question_subject_type_other"
                                }
                              ],
                              staticClass: "form_radio",
                              attrs: {
                                id: "radio_3_1_4",
                                type: "radio",
                                value: "私の職場",
                                name: "question_subject_type"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.question_subject_type_other,
                                  "私の職場"
                                )
                              },
                              on: {
                                change: [
                                  function($event) {
                                    _vm.question_subject_type_other = "私の職場"
                                  },
                                  function($event) {
                                    return _vm.subjectType()
                                  }
                                ]
                              }
                            }),
                            _c("label", { attrs: { for: "radio_3_1_4" } }, [
                              _vm._v("私の職場")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.question_subject_type_other,
                                  expression: "question_subject_type_other"
                                }
                              ],
                              staticClass: "form_radio",
                              attrs: {
                                id: "radio_3_1_5",
                                type: "radio",
                                name: "question_subject_type"
                              },
                              domProps: {
                                value: _vm.empty(
                                  _vm.question_subject_type_other
                                )
                                  ? _vm.question_subject_type_other
                                  : " ",
                                checked: _vm._q(
                                  _vm.question_subject_type_other,
                                  _vm.empty(_vm.question_subject_type_other)
                                    ? _vm.question_subject_type_other
                                    : " "
                                )
                              },
                              on: {
                                change: [
                                  function($event) {
                                    _vm.question_subject_type_other = _vm.empty(
                                      _vm.question_subject_type_other
                                    )
                                      ? _vm.question_subject_type_other
                                      : " "
                                  },
                                  function($event) {
                                    return _vm.subjectType()
                                  }
                                ]
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "no-margin",
                                attrs: { for: "radio_3_1_5" }
                              },
                              [_vm._v("その他")]
                            ),
                            _vm._v(" "),
                            _c("ValidationProvider", {
                              staticClass: "validate",
                              attrs: {
                                name: "question_subject_type_other_text",
                                rules:
                                  _vm.question_subject_type_other !== "当社" &&
                                  _vm.question_subject_type_other !==
                                    "私の職場" &&
                                  _vm.empty(_vm.question_subject_type_other)
                                    ? "required"
                                    : ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.question_subject_type_other_text,
                                              expression:
                                                "question_subject_type_other_text"
                                            }
                                          ],
                                          class: {
                                            "form_text tel_number": true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "text",
                                            name: "other",
                                            placeholder:
                                              "例：部門、本部、事業部"
                                          },
                                          domProps: {
                                            value:
                                              _vm.question_subject_type_other_text
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.question_subject_type_other_text =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.project.project_type !== 11
                      ? _c("dl", { staticClass: "radio_2 fx" }, [
                          _c("dt", [_vm._v("追加質問文")]),
                          _vm._v(" "),
                          _c("dd", { staticClass: "padding_t" }, [
                            _c("span", { staticClass: "margin_no" }, [
                              _vm._v(
                                "※見積・報告書形式については担当営業にお問い合わせください。"
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.project.qestion_type,
                                    expression: "project.qestion_type"
                                  }
                                ],
                                staticClass: "form_radio",
                                attrs: {
                                  id: "qestion_type1",
                                  type: "radio",
                                  value: "1",
                                  name: "qestion_type"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.project.qestion_type, "1")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.project,
                                      "qestion_type",
                                      "1"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "qestion_type1" } }, [
                                _vm._v(
                                  "追加質問が5問以内/追加質問の尺度が左記の7点法を利用"
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "padding_b" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.project.qestion_type,
                                    expression: "project.qestion_type"
                                  }
                                ],
                                staticClass: "form_radio",
                                attrs: {
                                  id: "qestion_type2",
                                  type: "radio",
                                  value: "2",
                                  name: "qestion_type"
                                },
                                domProps: {
                                  checked: _vm._q(_vm.project.qestion_type, "2")
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(
                                      _vm.project,
                                      "qestion_type",
                                      "2"
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "qestion_type2" } }, [
                                _vm._v(
                                  "追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)"
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.project.qestion_type == 1
                              ? _c("div", { attrs: { id: "seven" } }, [
                                  _c("div", { staticClass: "b_bottom" }, [
                                    _c("p", [
                                      _c("span", { staticClass: "margin_no" }, [
                                        _vm._v("【7点法尺度】")
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("img", {
                                        attrs: {
                                          src: "images/img3-1-2.png",
                                          width: "266",
                                          height: "137"
                                        }
                                      })
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "b_bottom2" },
                                    [
                                      _vm._l(_vm.project.questions, function(
                                        question,
                                        i
                                      ) {
                                        return _c(
                                          "div",
                                          { staticClass: "fx_st2" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: question.id,
                                                  expression: "question.id"
                                                }
                                              ],
                                              attrs: { type: "hidden" },
                                              domProps: { value: question.id },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    question,
                                                    "id",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("p", [
                                                _c(
                                                  "span",
                                                  { staticClass: "margin_no" },
                                                  [_vm._v("質問文")]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: question.body,
                                                      expression:
                                                        "question.body"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: "body" + i,
                                                    rows: "3",
                                                    cols: "40",
                                                    id: "textarea_3_1",
                                                    placeholder:
                                                      "質問文を入れてください。"
                                                  },
                                                  domProps: {
                                                    value: question.body
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        question,
                                                        "body",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c("p", [
                                                _c("span", [_vm._v("回答方式")])
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        question.format_type,
                                                      expression:
                                                        "question.format_type"
                                                    }
                                                  ],
                                                  staticClass: "form_radio",
                                                  attrs: {
                                                    id: "answer1" + i,
                                                    type: "radio",
                                                    value: "1",
                                                    name: "format_type" + i
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      question.format_type,
                                                      "1"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        question,
                                                        "format_type",
                                                        "1"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "answer1" + i
                                                    }
                                                  },
                                                  [_vm._v("尺度(7点法)")]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        question.format_type,
                                                      expression:
                                                        "question.format_type"
                                                    }
                                                  ],
                                                  staticClass: "form_radio",
                                                  attrs: {
                                                    id: "answer2" + i,
                                                    type: "radio",
                                                    value: "2",
                                                    name: "'format_type' + i"
                                                  },
                                                  domProps: {
                                                    checked: _vm._q(
                                                      question.format_type,
                                                      "2"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      return _vm.$set(
                                                        question,
                                                        "format_type",
                                                        "2"
                                                      )
                                                    }
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "answer2" + i
                                                    }
                                                  },
                                                  [_vm._v("自由記入")]
                                                )
                                              ])
                                            ])
                                          ]
                                        )
                                      }),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "action" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "add",
                                            attrs: { type: "button" },
                                            on: { click: _vm.addQuestion }
                                          },
                                          [_vm._v("＋")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "del",
                                            attrs: { type: "button" },
                                            on: { click: _vm.removeQuestion }
                                          },
                                          [_vm._v("－")]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "footer_box footer_2_3" }, [
                  _c("p", { staticClass: "btn_1" }, [
                    _c("input", {
                      staticClass: "form_box_sub",
                      attrs: { type: "submit", value: "入力内容を確認する" }
                    })
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_preview && _vm.project.progress_status == 1,
              expression: "is_preview && project.progress_status == 1"
            }
          ]
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.updateProgress()
                }
              }
            },
            [
              _c("div", { staticClass: "form_box form_box_1_3" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("dl", {}, [
                  _c("dt", {}, [_vm._v("組織名")]),
                  _vm._v(" "),
                  _c("dd", {}, [_vm._v(_vm._s(_vm.project.org_name))])
                ]),
                _vm._v(" "),
                _c("dl", {}, [
                  _c("dt", {}, [_vm._v("診断名")]),
                  _vm._v(" "),
                  _c("dd", {}, [_vm._v(_vm._s(_vm.project.diagnosis_name))])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("属性情報の設定")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm.attribute_setting_types[
                          _vm.project.attribute_setting_type
                        ]
                          ? _vm.attribute_setting_types[
                              _vm.project.attribute_setting_type
                            ]
                          : ""
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("dl", { staticClass: "form_box_1_3_dl" }, [
                  _c("dt", [_vm._v("ID・パスワード設定")]),
                  _vm._v(" "),
                  _c("dd", { staticClass: "l_height" }, [
                    _vm._v(
                      "ID：　　　　" +
                        _vm._s(
                          _vm.setting_types[_vm.project.id_setting_type]
                            ? _vm.setting_types[_vm.project.id_setting_type]
                            : ""
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            パスワード：" +
                        _vm._s(
                          _vm.setting_types[_vm.project.pass_setting_type]
                            ? _vm.setting_types[_vm.project.pass_setting_type]
                            : ""
                        )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("dl", { staticClass: "form_box_1_3_dl" }, [
                  _c("dt", { staticClass: "height_up_3_2" }, [
                    _vm._v("貴社お問合せ先")
                  ]),
                  _vm._v(" "),
                  _c("dd", { staticClass: "l_height" }, [
                    _vm._v(
                      "部署・役職　　" + _vm._s(_vm.project.inquiry_division)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            氏名　　　　　" +
                        _vm._s(_vm.project.inquiry_name)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            電話番号　　　" +
                        _vm._s(_vm.project.inquiry_tel)
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            メール　　　　" +
                        _vm._s(_vm.project.inquiry_email)
                    ),
                    _c("br")
                  ])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("質問紙提出先")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.project.paper_recipient))])
                ]),
                _vm._v(" "),
                _c("dl", {}, [
                  _c("dt", {}, [_vm._v("業種別比較")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm.project.comparison_industry_type ? "有り" : "無し"
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._m(2)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                _c("p", { staticClass: "btn2" }, [
                  _c("input", {
                    staticClass: "form_box_sub_blue form_box_sub_2",
                    attrs: { type: "button", value: "前の画面に戻る" },
                    on: {
                      click: function($event) {
                        return _vm.backInput()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm._m(3)
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.project.progress_status == 2
        ? _c("div", { staticClass: "next-step" }, [
            _vm._m(4),
            _vm._v(" "),
            _c("div", { staticClass: "content_box_2_5 fx_se" }, [
              _c(
                "div",
                {
                  staticClass: "content_box_child_2_5 content_box_child_3_3",
                  on: {
                    click: function($event) {
                      return _vm.newStep()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "images/jizen_img1.png",
                      alt: "「事前情報」を入力する",
                      srcset:
                        "images/jizen_img1.png" +
                        " 1x, " +
                        "images/jizen_img1@2x.png" +
                        " 2x"
                    }
                  }),
                  _vm._v(" "),
                  _vm._m(5),
                  _vm._v(" "),
                  _vm._m(6)
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "content_box_child_2_5 content_box_child_3_3",
                  on: {
                    click: function($event) {
                      return _vm.logout()
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "images/jizen_img2.png",
                      alt: "「事前情報」を入力する",
                      srcset:
                        "images/jizen_img2.png" +
                        " 1x, " +
                        "images/jizen_img2@2x.png" +
                        " 2x"
                    }
                  }),
                  _vm._v(" "),
                  _vm._m(7),
                  _vm._v(" "),
                  _vm._m(8)
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c("p", [_vm._v("入力内容に誤りがないかご確認ください。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("事前情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("dl", [
      _c("dt", [_vm._v("質問文の主語変更")]),
      _vm._v(" "),
      _c("dd", [_vm._v("当社")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn3 btn_3_2" }, [
      _c("input", {
        staticClass: "form_box_sub form_box_sub_2",
        attrs: { type: "submit", value: "上記の内容で登録する" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_box_2_5" }, [
      _c("p", [
        _vm._v("「事前情報」の登録を受け付けました。"),
        _c("br"),
        _vm._v(
          "\n        受付完了メールを送信しますので内容をご確認ください。"
        ),
        _c("br")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("引き続き"),
      _c("br"),
      _vm._v("「対象者リスト」をアップロー"),
      _c("br"),
      _vm._v("ドする")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn-s btn_2_5 btn_3_3" }, [
      _c("a", { attrs: { href: "javascript:;" } }, [
        _vm._v("対象者リストアップロード"),
        _c("br"),
        _vm._v("へ進む")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("後で"),
      _c("br"),
      _vm._v("「対象者リスト」をアップロー"),
      _c("br"),
      _vm._v("ドする")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "content_box_child_2_5_p" }, [
      _vm._v("この画面を終了した後で対象者リストを入力する"),
      _c("br"),
      _vm._v("場合は、再度ログインしていただき、「対象者リ"),
      _c("br"),
      _vm._v("スト →アップロード」へお進みください。\n        ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }