var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "top_box_4_1" }, [
      _c("div", { staticClass: "top_box_child_4_1" }, [
        _c("p", [_vm._v("アップロードが必要な資料は以下になります。")]),
        _vm._v(" "),
        _vm.project.project_type >= 1 && _vm.project.project_type <= 6
          ? _c("div", [_vm._m(0)])
          : _vm._e(),
        _vm._v(" "),
        _vm.project.project_type >= 11 && _vm.project.project_type <= 16
          ? _c("div", [
              _vm.project.progress_status == "2.1"
                ? _c("div", { staticClass: "fx_se" }, [
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._m(2)
                  ])
                : _c("div", { staticClass: "fx_se" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm._m(4)
                  ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.project.project_type >= 21 && _vm.project.project_type <= 24
          ? _c("div", [_vm._m(5)])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_4_1_p" }, [
        _vm._v("最初に属性一覧の設定を入力→アップロードしてください")
      ]),
      _vm._v(" "),
      _vm._m(6)
    ]),
    _vm._v(" "),
    _vm.project.project_type >= 1 && _vm.project.project_type <= 6
      ? _c("div", { staticClass: "footer_box_4_1" }, [
          _c("div", { staticClass: "fx_se" }, [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.download()
                        }
                      }
                    },
                    [
                      _vm._v("「属性一覧の設定」フォーマットを"),
                      _c("br"),
                      _vm._v("ダウンロード")
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._m(7)
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                { staticClass: "btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer" },
                [
                  _c("input", {
                    ref: "file",
                    attrs: { type: "file", id: "02", name: "02" },
                    on: {
                      change: function($event) {
                        return _vm.upload()
                      }
                    }
                  }),
                  _vm._m(8)
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "footer_box_4_1_u" }, [
                _vm._v("アップロード期日 2020年○月○日 00:00分")
              ]),
              _vm._v(" "),
              _vm._m(9)
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.project.project_type >= 21 && _vm.project.project_type <= 24
      ? _c("div", { staticClass: "footer_box_4_1" }, [
          _c("div", { staticClass: "fx_se" }, [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.downloadPTP()
                        }
                      }
                    },
                    [
                      _vm._v("「属性一覧の設定」フォーマットを"),
                      _c("br"),
                      _vm._v("ダウンロード")
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _vm._m(10)
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                { staticClass: "btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer" },
                [
                  _c("input", {
                    ref: "file",
                    attrs: { type: "file", id: "02", name: "02" },
                    on: {
                      change: function($event) {
                        return _vm.uploadPTP()
                      }
                    }
                  }),
                  _vm._m(11)
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "footer_box_4_1_u" }, [
                _vm._v("アップロード期日 2020年○月○日 00:00分")
              ]),
              _vm._v(" "),
              _vm._m(12)
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.project.project_type >= 11 && _vm.project.project_type <= 16
      ? _c("div", { staticClass: "footer_box_4_1" }, [
          _c("div", { staticClass: "fx_se" }, [
            _vm._m(13),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                { staticClass: "btn-s btn_2_5 btn_3_3 btn_4_1 cur_pointer" },
                [
                  _c("input", {
                    ref: "file",
                    attrs: { type: "file", id: "02", name: "02" },
                    on: {
                      change: function($event) {
                        _vm.project.progress_status == 2.1
                          ? _vm.uploadPTP()
                          : _vm.upload()
                      }
                    }
                  }),
                  _vm._m(14)
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "footer_box_4_1_u" }, [
                _vm._v("アップロード期日 2020年○月○日 00:00分")
              ]),
              _vm._v(" "),
              _vm._m(15)
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fx_se" }, [
      _c("div", [
        _c("div", { staticClass: "top_img_box_child_4_1" }, [
          _c("img", {
            attrs: {
              src: "images/atari1.jpg",
              alt: "対象者リスト画像",
              width: "358",
              height: "199"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "top_box_child_4_1_p" }, [
          _vm._v("対象者リスト")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_4 fin_up" }, [
        _c("img", {
          staticClass: "top_4_4_o",
          attrs: {
            src: "images/atari1.jpg",
            alt: "区分コード画像",
            width: "352",
            height: "155"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p top_4_4_o" }, [
        _vm._v("①「区分コード」設定")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_1" }, [
        _c("img", {
          attrs: {
            src: "images/atari2.jpg",
            alt: "対象者リスト画像",
            width: "358",
            height: "199"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p" }, [_vm._v("②対象者リスト")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_1" }, [
        _c("img", {
          attrs: {
            src: "images/atari1.jpg",
            alt: "区分コード画像",
            width: "352",
            height: "155"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p" }, [
        _vm._v("①属性一覧の設定")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "top_img_box_child_4_1" }, [
        _c("img", {
          attrs: {
            src: "images/atari2.jpg",
            alt: "対象者リスト画像",
            width: "358",
            height: "199"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "top_box_child_4_1_p" }, [_vm._v("②対象者リスト")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fx_se" }, [
      _c("div", [
        _c("div", { staticClass: "top_img_box_child_4_1" }, [
          _c("img", {
            attrs: {
              src: "images/atari2.jpg",
              alt: "対象者リスト画像",
              width: "358",
              height: "199"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "top_box_child_4_1_p" }, [
          _vm._v("対象者リスト")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top_img_box_4_1 fx_se" }, [
      _c("div", [
        _c("p", [
          _c("img", {
            attrs: {
              src: "images/up_1.png",
              alt: "フォーマットをダウンロード",
              srcset: "images/up_1.png" + " 1x, " + "images/up_1@2x.png" + " 2x"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("フォーマットをダウンロード")])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticClass: "arrow" }, [
          _c("img", {
            attrs: {
              src: "images/up_2.png",
              alt: "デスクトップで入力",
              srcset: "images/up_2.png" + " 1x, " + "images/up_2@2x.png" + " 2x"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("デスクトップで入力")])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticClass: "arrow" }, [
          _c("img", {
            attrs: {
              src: "images/up_3.png",
              alt: "ファイルをアップロード",
              srcset: "images/up_3.png" + " 1x, " + "images/up_3@2x.png" + " 2x"
            }
          })
        ]),
        _vm._v(" "),
        _c("p", [_vm._v("ファイルをアップロード")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_d" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("img", {
          attrs: {
            src: "images/file_d_2.png",
            alt: "入力の仕方を見る",
            srcset:
              "images/file_d_2.png" + " 1x, " + "images/file_d_2@2x.png" + " 2x"
          }
        }),
        _vm._v("\n          入力の仕方を見る")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "cur_pointer", attrs: { for: "02", id: "input-label" } },
      [_vm._v("「属性一覧の設定」ファイルを"), _c("br"), _vm._v("アップロード")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_u_2" }, [
      _vm._v("この画面を終了して、後でアップロードする場合"),
      _c("br"),
      _vm._v("は、再度ログインしてください。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_d" }, [
      _c("a", { attrs: { href: "" } }, [
        _c("img", {
          attrs: {
            src: "images/file_d_2.png",
            alt: "入力の仕方を見る",
            srcset:
              "images/file_d_2.png" + " 1x, " + "images/file_d_2@2x.png" + " 2x"
          }
        }),
        _vm._v("\n          入力の仕方を見る")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "cur_pointer", attrs: { for: "02", id: "input-label" } },
      [_vm._v("「属性一覧の設定」ファイルを"), _c("br"), _vm._v("アップロード")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_u_2" }, [
      _vm._v("この画面を終了して、後でアップロードする場合"),
      _c("br"),
      _vm._v("は、再度ログインしてください。")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "p",
        {
          staticClass: "btn-s btn_2_5 btn_3_3 btn_4_1 btn_4_1_blue cur_pointer"
        },
        [
          _c(
            "a",
            { attrs: { download: "", href: "/api/admin/pai-download" } },
            [
              _vm._v("「属性一覧の設定」フォーマットを"),
              _c("br"),
              _vm._v("ダウンロード")
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("p", { staticClass: "footer_box_4_1_d" }, [
        _c("a", { attrs: { href: "" } }, [
          _c("img", {
            attrs: {
              src: "images/file_d_2.png",
              alt: "入力の仕方を見る",
              srcset:
                "images/file_d_2.png" +
                " 1x, " +
                "images/file_d_2@2x.png" +
                " 2x"
            }
          }),
          _vm._v("\n          入力の仕方を見る")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "cur_pointer", attrs: { for: "02", id: "input-label" } },
      [_vm._v("「属性一覧の設定」ファイルを"), _c("br"), _vm._v("アップロード")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "footer_box_4_1_u_2" }, [
      _vm._v("この画面を終了して、後でアップロードする場合"),
      _c("br"),
      _vm._v("は、再度ログインしてください。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }