<template>
  <div class="body_1_2">
    <ValidationObserver ref="observer" v-show="!is_preview && project && project.progress_status == 1" v-slot="{ invalid }" tag="form" @submit.prevent="second()">
      <div class="form_box form_box_2_3 form_box_3_1">
        <div class="form_box_title">
          <p>事前情報</p>
        </div>

        <dl>
          <dt class="form_dt_3_1_1 height_23">組織名</dt>
          <dd><span>ご指定がない場合は、「株式会社○○○○」となります。</span><br>
            <input class="form_text tel_number" name="org_name" type="text" v-model="project.org_name"
                   placeholder="例：○○グループ"></dd>
        </dl>

        <dl>
          <dt class="form_dt_3_1_2 height_24">診断名</dt>
          <dd><span>ご指定がない場合は、「組織診断サーベイ」となります。</span><br>
            <input class="form_text tel_number" type="text" name="diagnosis_name" placeholder="例：○○○研修　事前WEB診断"
                   v-model="project.diagnosis_name"></dd>
        </dl>
        <dl>
          <dt class="required required12 form_dt_3_1_3">属性情報の設定<img src="images/info.png"
                                                                    srcset="images/info.png 1x, images/info@2x.png 2x"
                                                                    alt="インフォメーション" class="info">
            <p class="info_fukidashi_4">属性情報とは：回答者の役職・所属などのことです。</p></dt>
          <dd>
            <p class="margin_dd">
              <ValidationProvider name="attribute_setting_type" rules="required" v-slot="{ errors }" class="validate">
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="attribute_setting_type1" type="radio" value="1"
                     name="attribute_setting_type" v-model="project.attribute_setting_type"><label
              for="attribute_setting_type1">回答時に選択（推奨）</label>
              <input :class="{ 'form_radio': true, 'error': errors[0] }" id="attribute_setting_type2" type="radio" value="2"
                     name="attribute_setting_type" v-model="project.attribute_setting_type"><label
              for="attribute_setting_type2">リスト提出時に設定</label>
              </ValidationProvider>
            </p>
          </dd>
        </dl>
        <dl>
          <dt class="required required13 height_up_2_3 height_up_3_1_2">ID・パスワード設定</dt>
          <dd>
            <ValidationProvider name="id_setting_type" rules="required" v-slot="{ errors }"
                                class="validate margin_dd fx_st btn_form_3_1">
              <p>ID</p>
              <p class="id_btn1">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="id_setting_type"
                       v-model="project.id_setting_type" value="1" id="id_setting_type1">
                <label for="id_setting_type1">事務局(貴社)が設定</label>
              </p>
              <p>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="id_setting_type"
                       v-model="project.id_setting_type" value="2" id="id_setting_type2">
                <label for="id_setting_type2">ビジネスコンサルタントが設定</label>
              </p>
            </ValidationProvider>
            <ValidationProvider name="pass_setting_type" rules="required" v-slot="{ errors }"
                                class="validate margin_dd fx_st btn_form_3_1 btn_form_3_1_2">
              <p>パスワード</p>
              <p class="id_btn2">
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="pass_setting_type"
                       v-model="project.pass_setting_type" value="1" id="pass_setting_type1">
                <label for="pass_setting_type1">事務局(貴社)が設定</label>
              </p>
              <p>
                <input :class="{ 'form_radio': true, 'error': errors[0] }" type="radio" name="pass_setting_type"
                       v-model="project.pass_setting_type" value="2" id="pass_setting_type2">
                <label for="pass_setting_type2">ビジネスコンサルタントが設定</label>
              </p>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="required required40 form_box_2_3_dt height_up_3_1">貴社お問合せ先</dt>
          <dd class="margin_dd">
            <p class="form_contact_p">Web画面・質問紙に記載される貴社お問い合わせ先です。</p>
            <div class="fx_st">
              <p>部署・役職</p>
              <p><input class="form_text form_sch" type="text" name="inquiry_division" placeholder="◯◯部　部長"
                        v-model="project.inquiry_division">
              </p>
            </div>
            <div class="fx_st">
              <p>氏名</p>
              <p class="margin_dd2"><input class="form_text form_sch" type="text" name="inquiry_name" placeholder="田中　一郎"
                                           v-model="project.inquiry_name">
              </p>
            </div>
            <div class="fx_st">
              <p class="sch_1">電話番号</p>
              <p class="margin_dd3"><input class="form_text form_sch" type="text" name="inquiry_tel" placeholder="03xxxxxxxx"
                                           v-model="project.inquiry_tel">
              </p>
            </div>
            <ValidationProvider name="comparison_industry_type" rules="email" v-slot="{ errors }" class="validate">
              <div class="fx_st">
                <p class="sch_1">メール</p>
                <p class="margin_dd4">
                  <input :class="{ 'form_text form_sch': true, 'error': errors[0] }" type="text"
                         name="inquiry_email"
                         placeholder="Ichiro.t@cliant.co.jp" v-model="project.inquiry_email">
                </p>
              </div>
              <span class="error left-95" v-show="errors[0]">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <dl>
          <dt class="form_dt_3_1_5">質問紙提出先<img src="images/info.png" srcset="images/info.png 1x, images/info@2x.png 2x"
                                               alt="インフォメーション" class="info">
            <p class="info_fukidashi_10">質問紙に記載されます。（下記例）<br>
              <img src="images/sample02.png" alt="サンプル"></p></dt>
          <dd>
            <input class="form_text tel" type="text" name="paper_recipient" placeholder="例：●●部署 ▲▲さん" v-model="project.paper_recipient">
          </dd>
        </dl>
        <dl class="radio_2">
          <dt class="form_dt_3_1_4">業種別比較</dt>
          <dd>
            <!--<ValidationProvider name="comparison_industry_type" rules="required" v-slot="{ errors }" class="validate">-->
              <input :class="{ 'form_radio': true }" type="radio" name="comparison_industry_type"
                     v-model="project.comparison_industry_type" value="1" id="comparison_industry_type1">
              <label for="comparison_industry_type1">有り</label>
              <input :class="{ 'form_radio': true }" type="radio" name="comparison_industry_type"
                     v-model="project.comparison_industry_type" value="0" id="comparison_industry_type2">
              <label for="comparison_industry_type2">無し</label>
              <!--<br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>-->
            <!--</ValidationProvider>-->
          </dd>
        </dl>

        <dl class="radio_2">
          <dt class="form_dt_3_1_4">回答者への案内メール</dt>
          <dd>
            <input class="form_radio" id="guidance_email_type_1" type="radio" value="1" name="guidance_email_type"
                   v-model="project.guidance_email_type">
            <label for="guidance_email_type_1">BConがメール送信</label>
            <input class="form_radio" id="guidance_email_type_2" type="radio" value="2" name="guidance_email_type"
                   v-model="project.guidance_email_type">
            <label for="guidance_email_type_2">お客様が実施</label>
          </dd>
        </dl>

        <dl class="radio_2">
          <dt class="form_dt_3_1_4">質問文の主語変更</dt>
          <dd>
            <div>
              <input class="form_radio" id="radio_3_1_3" type="radio" value="当社" name="question_subject_type"
                     @change="subjectType()"
                     v-model="question_subject_type_other"><label
              for="radio_3_1_3">当社</label>
              <input class="form_radio" id="radio_3_1_4" type="radio" value="私の職場" name="question_subject_type"
                     @change="subjectType()"
                     v-model="question_subject_type_other"><label
              for="radio_3_1_4">私の職場</label>
              <input class="form_radio" id="radio_3_1_5" type="radio"
                     name="question_subject_type" @change="subjectType()" :value="empty(question_subject_type_other) ? question_subject_type_other : ' '"
                     v-model="question_subject_type_other">
              <label class="no-margin" for="radio_3_1_5">その他</label>
              <ValidationProvider name="question_subject_type_other_text"
                                  :rules="(question_subject_type_other !== '当社' && question_subject_type_other !== '私の職場' && empty(question_subject_type_other)) ? 'required' : ''"
                                  v-slot="{ errors }" class="validate">
                <input :class="{ 'form_text tel_number': true, 'error': errors[0] }" type="text" name="other"
                       placeholder="例：部門、本部、事業部" v-model="question_subject_type_other_text">
              </ValidationProvider>
            </div>
          </dd>
        </dl>
        <dl class="radio_2 fx" v-if="project.project_type !== 11">
          <dt>追加質問文</dt>
          <dd class="padding_t">
            <span class="margin_no">※見積・報告書形式については担当営業にお問い合わせください。</span>
            <p>
              <input class="form_radio" id="qestion_type1" type="radio" value="1" name="qestion_type"
                     v-model="project.qestion_type">
              <label for="qestion_type1">追加質問が5問以内/追加質問の尺度が左記の7点法を利用</label>
            </p>
            <p class="padding_b">
              <input class="form_radio" id="qestion_type2" type="radio" value="2"
                     name="qestion_type" v-model="project.qestion_type">
              <label for="qestion_type2">追加質問が6問以上/追加質問の尺度が7点法以外(個別対応)</label>
            </p>
            <div id="seven" v-if="project.qestion_type == 1">
              <div class="b_bottom">
                <p><span class="margin_no">【7点法尺度】</span></p>
                <p><img src="images/img3-1-2.png" width="266" height="137"></p>
              </div>
              <div class="b_bottom2">
                <div v-for="(question, i) in project.questions" class="fx_st2">
                  <input type="hidden" v-model="question.id">
                  <div>
                    <p><span class="margin_no">質問文</span></p>
                    <p>
                      <textarea v-model="question.body" :name="'body' + i" rows="3" cols="40" id="textarea_3_1" placeholder="質問文を入れてください。"></textarea>
                    </p>
                  </div>
                  <div>
                    <p><span>回答方式</span></p>
                    <p>
                      <input class="form_radio" :id="'answer1' + i" type="radio" value="1" :name="'format_type' + i" v-model="question.format_type">
                      <label :for="'answer1' + i">尺度(7点法)</label>
                    </p>
                    <p>
                      <input class="form_radio" :id="'answer2' + i" type="radio" value="2" name="'format_type' + i" v-model="question.format_type">
                      <label :for="'answer2' + i">自由記入</label>
                    </p>
                  </div>
                </div>
                <div class="action">
                  <button class="add" type="button" @click="addQuestion">＋</button>
                  <button class="del" type="button" @click="removeQuestion">－</button>
                </div>
              </div>
            </div>
          </dd>
        </dl>
      </div>
      <div class="footer_box footer_2_3">
        <p class="btn_1"><input class="form_box_sub" type="submit" value="入力内容を確認する"></p>
      </div>
    </ValidationObserver>
    <div v-show="is_preview && project.progress_status == 1">
      <div class="attention">
        <p>入力内容に誤りがないかご確認ください。</p>
      </div>
      <form @submit.prevent="updateProgress()">
        <div class="form_box form_box_1_3">
          <div class="form_box_title">
            <p>事前情報</p>
          </div>
          <dl class="">
            <dt class="">組織名</dt>
            <dd class="">{{ project.org_name }}</dd>
          </dl>
          <dl class="">
            <dt class="">診断名</dt>
            <dd class="">{{ project.diagnosis_name }}</dd>
          </dl>
          <dl>
            <dt>属性情報の設定</dt>
            <dd>{{ attribute_setting_types[project.attribute_setting_type] ? attribute_setting_types[project.attribute_setting_type] : '' }}</dd>
          </dl>
          <dl class="form_box_1_3_dl">
            <dt>ID・パスワード設定</dt>
            <dd class="l_height">ID：　　　　{{ setting_types[project.id_setting_type] ? setting_types[project.id_setting_type] : '' }}<br>
              パスワード：{{ setting_types[project.pass_setting_type] ? setting_types[project.pass_setting_type] : '' }}</dd>
          </dl>
          <dl class="form_box_1_3_dl">
            <dt class="height_up_3_2">貴社お問合せ先</dt>
            <dd class="l_height">部署・役職　　{{ project.inquiry_division }}<br>
              氏名　　　　　{{ project.inquiry_name }}<br>
              電話番号　　　{{ project.inquiry_tel }}<br>
              メール　　　　{{ project.inquiry_email }}<br>
            </dd>
          </dl>
          <dl>
            <dt>質問紙提出先</dt>
            <dd>{{ project.paper_recipient }}</dd>
          </dl>
          <dl class="">
            <dt class="">業種別比較</dt>
            <dd>{{ project.comparison_industry_type ? '有り' : '無し' }}</dd>
          </dl>
          <dl>
            <dt>質問文の主語変更</dt>
            <dd>当社</dd>
          </dl>
        </div>
        <div class="footer_box footer_box_1_3">
          <p class="btn2"><input class="form_box_sub_blue form_box_sub_2" type="button" @click="backInput()" value="前の画面に戻る"></p>
          <p class="btn3 btn_3_2"><input class="form_box_sub form_box_sub_2" type="submit" value="上記の内容で登録する"></p>
        </div>
      </form>
    </div>
    <div class="next-step" v-if="project.progress_status == 2">
      <div class="top_box_2_5">
        <p>「事前情報」の登録を受け付けました。<br>
          受付完了メールを送信しますので内容をご確認ください。<br>
        </p>
      </div>

      <div class="content_box_2_5 fx_se">
        <div class="content_box_child_2_5 content_box_child_3_3" @click="newStep()">
          <img src="images/jizen_img1.png" alt="「事前情報」を入力する" srcset="images/jizen_img1.png 1x, images/jizen_img1@2x.png 2x">
          <p>引き続き<br>「対象者リスト」をアップロー<br>ドする</p>
          <p class="btn-s btn_2_5 btn_3_3"><a href="javascript:;">対象者リストアップロード<br>へ進む</a></p>
        </div>
        <div class="content_box_child_2_5 content_box_child_3_3" @click="logout()">
          <img src="images/jizen_img2.png" alt="「事前情報」を入力する" srcset="images/jizen_img2.png 1x, images/jizen_img2@2x.png 2x">
          <p>後で<br>「対象者リスト」をアップロー<br>ドする</p>
          <p class="content_box_child_2_5_p">この画面を終了した後で対象者リストを入力する<br>場合は、再度ログインしていただき、「対象者リ<br>スト →アップロード」へお進みください。
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {COMPARE_TYPE, SELECT_KUBUN_TYPE, SETTING_TYPE, USER_CONSULTANT, USER_SALES, ATTRIBUTE_SETTING_TYPE} from './../../../contstant'

  export default {
    data() {
      return {
        is_preview: false,
        select_kubun_types: SELECT_KUBUN_TYPE,
        setting_types: SETTING_TYPE,
        compare_types: COMPARE_TYPE,
        question_subject_type_other: '',
        question_subject_type_other_text: '',
        attribute_setting_types: ATTRIBUTE_SETTING_TYPE
      }
    },
    props: {
      preview: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      preview() {
        this.is_preview = this.preview
      },
      'project.question_subject_type': function () {
        if (this.project.question_subject_type !== '' && this.project.question_subject_type !== '当社' && this.project.question_subject_type !== '私の職場') {
          this.question_subject_type_other_text = this.project.question_subject_type
        }

        if (this.question_subject_type_other === '') {
          this.question_subject_type_other = this.project.question_subject_type
        }
      },
      question_subject_type_other_text() {
        this.question_subject_type_other = this.question_subject_type_other_text
        this.project.question_subject_type = this.question_subject_type_other_text
      },
      'project.qestion_type': function() {
        if (this.project.qestion_type == 1 && this.project.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.project.questions.push(newQ)
        }
      },
      project() {
        if (this.project.qestion_type == 1 && this.project.questions.length === 0) {
          const newQ = {
            id: '',
            body: '',
            format_type: ''
          }
          this.project.questions.push(newQ)
        }

        if (this.project.question_subject_type !== '' && this.project.question_subject_type !== '当社' && this.project.question_subject_type !== '私の職場') {
          this.question_subject_type_other_text = this.project.question_subject_type
        }
      }
    },
    computed: {
      ...mapState('consultantUser', ['project', 'user'])
    },
    methods: {
      empty(field) {
        return !_.isEmpty(field)
      },
      async second() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.observer.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
          return
          // ABORT!!
        }

        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': this.project}).then(() => {
          this.is_preview = true
          this.$emit('hidden', true)
          this.$wait.end('loading')
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      updateProgress() {
        this.$wait.start('loading')
        this.$store.dispatch('project/UPDATE_PROJECT', {'project': { progress_status: 2 } }).then(() => {
          this.is_preview = true
          this.$wait.end('loading')
        }).catch(e => {
          this.$wait.end('loading')
        })
      },
      newStep() {
        this.$emit('hidden',  false)
        this.$store.dispatch('project/NEW_STEP', 2)
      },
      logout() {
        if (this.user.type === USER_CONSULTANT) {
          this.logoutTo = 'LoginConsultant'
        } else if (this.user.type === USER_SALES) {
          this.logoutTo = 'LoginSales'
        }
        this.$store.dispatch('consultantUser/AUTH_LOGOUT').then(() => {
        }).catch(e => {

        })
      },
      addQuestion() {
        const newQ = {
          id: '',
          body: '',
          format_type: ''
        }
        this.project.questions.push(newQ)
      },
      removeQuestion() {
        if (this.project.questions.length > 1) {
          this.project.questions.pop()
        }
      },
      backInput() {
        this.is_preview = false
        this.$emit('hidden',  false)
      },
      subjectType() {
        if (this.question_subject_type_other === '当社' || this.question_subject_type_other === '私の職場') {
          this.project.question_subject_type = this.question_subject_type_other
        }
      }
    },
    mounted() {
      if (this.project.question_subject_type !== '' && this.project.question_subject_type !== '当社' && this.project.question_subject_type !== '私の職場') {
        this.question_subject_type_other_text = this.project.question_subject_type
      }

      if (this.project.qestion_type == 1 && this.project.questions.length === 0) {
        const newQ = {
          id: '',
          body: '',
          format_type: ''
        }
        this.project.questions.push(newQ)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .error {
    &.left-95 {
      padding-left: 95px;
      margin-left: 0;
    }
  }
</style>
