var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body_1_2" },
    [
      _c("header-component", {
        attrs: { title: "[営業部門用 案件 登録フォーム]" }
      }),
      _vm._v(" "),
      _c("ValidationObserver", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_preview && !_vm.is_preview_email,
            expression: "!is_preview && !is_preview_email"
          }
        ],
        ref: "observer",
        attrs: { tag: "form" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.previewRegister()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              return [
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("お客様情報")])
                  ]),
                  _vm._v(" "),
                  _c("dl", {}, [
                    _c("dt", { staticClass: "required required1" }, [
                      _vm._v("組織名")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      {},
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "client_name", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.client_name,
                                          expression: "user.client_name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        placeholder: "例：株式会社○○○",
                                        name: "client_name"
                                      },
                                      domProps: { value: _vm.user.client_name },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "client_name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", [_vm._v("担当者所属・役職")]),
                    _vm._v(" "),
                    _c("dd", { staticClass: "fx_ce" }, [
                      _c("p", { staticClass: "position" }, [
                        _vm._v("所属"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.division,
                              expression: "user.division"
                            }
                          ],
                          staticClass: "form_text",
                          attrs: {
                            type: "text",
                            name: "division",
                            placeholder: "例：○○○部"
                          },
                          domProps: { value: _vm.user.division },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "division",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "position" }, [
                        _vm._v("役職"),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.position,
                              expression: "user.position"
                            }
                          ],
                          staticClass: "form_text",
                          attrs: {
                            type: "text",
                            name: "position",
                            placeholder: "例：部長"
                          },
                          domProps: { value: _vm.user.position },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "position",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required2" }, [
                      _vm._v("担当者　氏名")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "name1", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.name1,
                                          expression: "user.name1"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "name1",
                                        placeholder: "例：田中一郎"
                                      },
                                      domProps: { value: _vm.user.name1 },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "name1",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required3 height_up1" }, [
                      _vm._v("担当者　メールアドレス")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "email1", rules: "required|email" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.email1,
                                          expression: "user.email1"
                                        }
                                      ],
                                      class: {
                                        "form_text form_text_2": true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "email1",
                                        placeholder: "例：Ichiro.t@cliant.co.jp"
                                      },
                                      domProps: { value: _vm.user.email1 },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "email1",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("span", [_vm._v("※英数字半角入力")]),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "height_up1" }, [
                      _vm._v("担当者2人目　氏名")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("span", [
                          _vm._v("案内先を2名設定する場合は設定してください。")
                        ]),
                        _c("br"),
                        _vm._v(" "),
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            rules: _vm.user.email2 !== "" ? "required" : "",
                            name: "name2"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.name2,
                                          expression: "user.name2"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "name2",
                                        placeholder: "例：佐藤花子"
                                      },
                                      domProps: { value: _vm.user.name2 },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "name2",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "height_up1" }, [
                      _vm._v("担当者2人目　メールアドレス")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            rules:
                              "same:" +
                              _vm.user.email1 +
                              "|" +
                              (_vm.user.name2 !== "" ? "required" : "") +
                              "|email",
                            name: "email2"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.email2,
                                          expression: "user.email2"
                                        }
                                      ],
                                      class: {
                                        "form_text form_text_2": true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "email2",
                                        title: "半角英数字で入力して下さい。",
                                        placeholder: "例：Hanako.S@cliant.co.jp"
                                      },
                                      domProps: { value: _vm.user.email2 },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "email2",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("span", [_vm._v("※英数字半角入力")]),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form_box" }, [
                  _c("div", { staticClass: "form_box_title" }, [
                    _c("p", [_vm._v("社内連絡情報")])
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "required required4" }, [
                      _vm._v("担当営業名")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { name: "staff_name", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.staff_name,
                                          expression: "user.staff_name"
                                        }
                                      ],
                                      class: {
                                        form_text: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "staff_name",
                                        placeholder: "例：伊藤孝史"
                                      },
                                      domProps: { value: _vm.user.staff_name },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "staff_name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "height_up1" }, [
                      _vm._v("共有先メールアドレス①")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { rules: { regex: /^[0-9A-Za-z]+$/ } },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.staff_email1,
                                          expression: "user.staff_email1"
                                        }
                                      ],
                                      class: {
                                        "form_text mail_form form_text_2": true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "staff_email1",
                                        title: "半角英数字で入力して下さい。",
                                        placeholder: "例：Atsushi.i"
                                      },
                                      domProps: {
                                        value: _vm.user.staff_email1
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "staff_email1",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c("span", { staticClass: "middle" }, [
                                      _vm._v("@bcon.co.jp")
                                    ]),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c("span", [_vm._v("※英数字半角入力")]),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", [
                    _c("dt", { staticClass: "height_up1" }, [
                      _vm._v("共有先メールアドレス②")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: { rules: { regex: /^[0-9A-Za-z]+$/ } },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.user.staff_email2,
                                          expression: "user.staff_email2"
                                        }
                                      ],
                                      class: {
                                        "form_text mail_form form_text_2": true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "text",
                                        name: "staff_email2",
                                        title: "半角英数字で入力して下さい。",
                                        placeholder: "例：Yumiko.H"
                                      },
                                      domProps: {
                                        value: _vm.user.staff_email2
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.user,
                                            "staff_email2",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c("span", { staticClass: "middle" }, [
                                      _vm._v("@bcon.co.jp")
                                    ]),
                                    _c("br"),
                                    _c("span", [_vm._v("※英数字半角入力")]),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", { staticClass: "radio" }, [
                    _c("dt", { staticClass: "required required5 top" }, [
                      _vm._v("診断種類の選択")
                    ]),
                    _vm._v(" "),
                    _c(
                      "dd",
                      [
                        _c("ValidationProvider", {
                          staticClass: "validate",
                          attrs: {
                            name: "is_multisite_diagnosis",
                            rules: "required"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.user.is_multisite_diagnosis,
                                          expression:
                                            "user.is_multisite_diagnosis"
                                        }
                                      ],
                                      class: {
                                        form_radio: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "radio",
                                        name: "is_multisite_diagnosis",
                                        id: "is_multisite_diagnosis0",
                                        value: "0"
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.user.is_multisite_diagnosis,
                                          "0"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.user,
                                            "is_multisite_diagnosis",
                                            "0"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "is_multisite_diagnosis0"
                                        }
                                      },
                                      [_vm._v("個人診断")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.user.is_multisite_diagnosis,
                                          expression:
                                            "user.is_multisite_diagnosis"
                                        }
                                      ],
                                      class: {
                                        form_radio: true,
                                        error: errors[0]
                                      },
                                      attrs: {
                                        type: "radio",
                                        name: "is_multisite_diagnosis",
                                        id: "is_multisite_diagnosis1",
                                        value: "1"
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.user.is_multisite_diagnosis,
                                          "1"
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.user,
                                            "is_multisite_diagnosis",
                                            "1"
                                          )
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "is_multisite_diagnosis1"
                                        }
                                      },
                                      [_vm._v("組織診断")]
                                    ),
                                    _vm._v(" "),
                                    _c("br", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: errors[0],
                                          expression: "errors[0]"
                                        }
                                      ]
                                    }),
                                    _c("span", { staticClass: "error" }, [
                                      _vm._v(_vm._s(errors[0]))
                                    ])
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("p", { staticClass: "radio_p" }, [
                          _vm._v("以下より選択してください。")
                        ]),
                        _vm._v(" "),
                        _vm.project_types
                          ? _c(
                              "div",
                              { attrs: { id: "person" } },
                              [
                                _c("ValidationProvider", {
                                  staticClass: "validate",
                                  attrs: {
                                    name: "project_type",
                                    rules: "required"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _vm._l(_vm.project_types, function(
                                              type,
                                              index
                                            ) {
                                              return _c("div", { key: index }, [
                                                _c(
                                                  "p",
                                                  { staticClass: "label_p" },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.user
                                                              .project_type,
                                                          expression:
                                                            "user.project_type"
                                                        }
                                                      ],
                                                      class: {
                                                        form_radio: true,
                                                        error: errors[0]
                                                      },
                                                      attrs: {
                                                        type: "radio",
                                                        name: "project_type",
                                                        id:
                                                          _vm.user
                                                            .project_type +
                                                          index
                                                      },
                                                      domProps: {
                                                        value: index,
                                                        checked: _vm._q(
                                                          _vm.user.project_type,
                                                          index
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.$set(
                                                            _vm.user,
                                                            "project_type",
                                                            index
                                                          )
                                                        }
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for:
                                                            _vm.user
                                                              .project_type +
                                                            index
                                                        }
                                                      },
                                                      [_vm._v(_vm._s(type))]
                                                    )
                                                  ]
                                                )
                                              ])
                                            }),
                                            _vm._v(" "),
                                            _c("br", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors[0],
                                                  expression: "errors[0]"
                                                }
                                              ]
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "error" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("dl", { staticClass: "fx_normal" }, [
                    _c("dt", { staticClass: "top" }, [
                      _vm._v("備考欄（診断SGへの連絡用）")
                    ]),
                    _vm._v(" "),
                    _c("dd", [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.user.memo,
                            expression: "user.memo"
                          }
                        ],
                        attrs: {
                          name: "memo",
                          rows: "7",
                          cols: "40",
                          placeholder:
                            "診断チームに申し伝えることがあれば入力してください。"
                        },
                        domProps: { value: _vm.user.memo },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.user, "memo", $event.target.value)
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.user.is_multisite_diagnosis == 1,
                          expression: "user.is_multisite_diagnosis == 1"
                        }
                      ]
                    },
                    [
                      _c("dl", { staticClass: "radio_2" }, [
                        _c("dt", { staticClass: "required required6" }, [
                          _vm._v("経年比較の有無")
                        ]),
                        _vm._v(" "),
                        _c(
                          "dd",
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                name: "is_comparison_age",
                                rules: "required"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user.is_comparison_age,
                                              expression:
                                                "user.is_comparison_age"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "is_comparison_age",
                                            value: "true",
                                            id: "is_comparison_age1"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.user.is_comparison_age,
                                              "true"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.user,
                                                "is_comparison_age",
                                                "true"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "is_comparison_age1" }
                                          },
                                          [_vm._v("有り")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.user.is_comparison_age,
                                              expression:
                                                "user.is_comparison_age"
                                            }
                                          ],
                                          class: {
                                            form_radio: true,
                                            error: errors[0]
                                          },
                                          attrs: {
                                            type: "radio",
                                            name: "is_comparison_age",
                                            value: "false",
                                            id: "is_comparison_age2"
                                          },
                                          domProps: {
                                            checked: _vm._q(
                                              _vm.user.is_comparison_age,
                                              "false"
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.user,
                                                "is_comparison_age",
                                                "false"
                                              )
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "is_comparison_age2" }
                                          },
                                          [_vm._v("無し")]
                                        ),
                                        _vm._v(" "),
                                        _c("br", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: errors[0],
                                              expression: "errors[0]"
                                            }
                                          ]
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(_vm._s(errors[0]))
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "footer_box" }, [
                  _c("p", { staticClass: "btn_1" }, [
                    _c("input", {
                      staticClass: "form_box_sub",
                      attrs: { type: "submit", value: "入力内容を確認する" }
                    })
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_preview && !_vm.is_preview_email,
              expression: "is_preview && !is_preview_email"
            }
          ]
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.comfirmRegister()
                }
              }
            },
            [
              _c("div", { staticClass: "form_box form_box_1_3" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("組織名")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.user.client_name))])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("担当者所属・役職")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      _vm._s(_vm.user.division) +
                        "　" +
                        _vm._s(_vm.user.position)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("担当者　氏名")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.user.name1))])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("担当者　メールアドレス")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.user.email1))])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("担当者2人目　氏名")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.user.name2))])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("担当者2人目　メールアドレス")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.user.email2))])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form_box form_box_1_3" }, [
                _vm._m(2),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("担当営業名")]),
                  _vm._v(" "),
                  _c("dd", [_vm._v(_vm._s(_vm.user.staff_name))])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("共有先メールアドレス①")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm.user.staff_email1
                          ? _vm.user.staff_email1 + "@bcon.co.jp"
                          : ""
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("共有先メールアドレス②")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      _vm._s(
                        _vm.user.staff_email2
                          ? _vm.user.staff_email2 + "@bcon.co.jp"
                          : ""
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("dl", { staticClass: "form_box_1_3_dl" }, [
                  _c("dt", [_vm._v("診断種別選択")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(
                      "＜" +
                        _vm._s(
                          _vm.user.is_multisite_diagnosis === 0
                            ? "個人診断"
                            : "組織診断"
                        ) +
                        "＞"
                    ),
                    _c("br"),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.projectType()) +
                        "\n          "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("備考欄（社内連絡用）")]),
                  _vm._v(" "),
                  _c(
                    "dd",
                    _vm._l(_vm.user.memo.split("\n"), function(line) {
                      return _c("div", { key: line }, [_vm._v(_vm._s(line))])
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("dl", [
                  _c("dt", [_vm._v("経年比較")]),
                  _vm._v(" "),
                  _c("dd", [
                    _vm._v(_vm._s(_vm.user.is_comparison_age ? "有り" : "無し"))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                _c("p", { staticClass: "back_btn" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.backRegister }
                    },
                    [_vm._v("前の画面へ戻る")]
                  )
                ]),
                _vm._v(" "),
                _vm._m(3)
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_preview_email && !_vm.is_success,
              expression: "is_preview_email && !is_success"
            }
          ]
        },
        [
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.saveClient()
                }
              }
            },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "form_box form_box_1_4" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.user.client_name)),
                  _c("br"),
                  _vm._v(
                    _vm._s(_vm.user.division) + "　" + _vm._s(_vm.user.position)
                  ),
                  _c("br"),
                  _vm._v(_vm._s(_vm.user.name1))
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "平素より大変お世話になっております。株式会社ビジネスコンサルタント □□でございます。"
                  )
                ]),
                _vm._v(" "),
                _vm._m(5),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー"
                  ),
                  _c("br"),
                  _vm._v("\n        お申込みフォーム ログイン画面"),
                  _c("br"),
                  _c("span", [_vm._v(_vm._s(_vm.base_url()))]),
                  _c("br"),
                  _vm._v("【ログインID】" + _vm._s(_vm.user.id_login)),
                  _c("br"),
                  _vm._v("【パスワード】" + _vm._s(_vm.user.password)),
                  _c("br"),
                  _vm._v(
                    "\n        ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー"
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "メールの内容についてのお問い合わせは、下記の連絡先までメール、または電話でお願いします。"
                  )
                ]),
                _vm._v(" "),
                _vm._m(6)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "footer_box footer_box_1_3" }, [
                _c("p", { staticClass: "btn2" }, [
                  _c("input", {
                    staticClass: "form_box_sub_blue form_box_sub_2",
                    attrs: { value: "前の画面に戻る", type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.backPreview()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _vm._m(7)
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_success,
              expression: "is_success"
            }
          ],
          staticClass: "login_form_box form_box_1_5"
        },
        [
          _c("p", [_vm._v("登録を受け付けました。")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "お客様にメールでクライアント用ログイン情報を送信しましたので"
            )
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("アフターフォローをお願いします。")])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c("p", [_vm._v("入力内容に誤りがないかご確認ください。")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("お客様情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_box_title" }, [
      _c("p", [_vm._v("社内連絡情報")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn" }, [
      _c("input", {
        staticClass: "form_box_sub form_box_sub_2",
        attrs: { type: "submit", value: "メールをプレビュー" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "attention" }, [
      _c("p", [
        _vm._v("お客様へ以下のメールが送信されます。内容をご確認ください。")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "この度、ご依頼いただきました「組織効果性サーべイ」のお申込みフォームのご案内です。"
      ),
      _c("br"),
      _vm._v(
        "\n        以下のURLよりログイン画面にアクセスしていただき、以下のID、パスワードでログインしてお申込みフォームへお進みください。\n      "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー"
      ),
      _c("br"),
      _vm._v("\n        BConアンケート事務局"),
      _c("br"),
      _vm._v("電話　03-3287-3416(月～金 10:00～17:00)"),
      _c("br"),
      _vm._v("メールアドレス "),
      _c("span", [_vm._v("quest@info.bcon.jp")]),
      _c("br"),
      _vm._v(
        "\n        ーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーーー"
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "btn3" }, [
      _c("input", {
        staticClass: "form_box_sub form_box_sub_2",
        attrs: { type: "submit", value: "この内容で送信する" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }